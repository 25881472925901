import type JobApplication from 'redesign/types/JobApplication'

export const appStatusOptions = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Hidden',
    value: 'hiddenAt'
  },
  {
    label: 'Closed',
    value: 'closed'
  },
  {
    label: 'Withdrawn',
    value: 'withdrawn'
  },
  {
    label: 'All',
    value: 'all'
  }
]

export const appStatusToFields = {
  pending: {
    active: true,
    applied: true,
    hiddenAt_null: true
  },

  withdrawn: {
    active: true,
    applied: false,
    hiddenAt_null: true
  },

  closed: {
    active: false
  },

  hiddenAt: {
    active: true,
    hiddenAt_null: false
  }
}

export const applicationStatus = (record: JobApplication) => [
  {
    filter: 'pending',
    condition:
      record.active === true &&
      record.applied === true &&
      record.hiddenAt === null,
    text: 'Pending',
    color: 'volcano',
    canClose: true
  },
  {
    filter: 'hiddenAt',
    condition: record.active === true && record.hiddenAt !== null,
    text: 'Hidden',
    color: 'purple'
  },
  {
    filter: 'withdrawn',
    condition: record.active === true && record.applied === false,
    text: 'Candidate Withdrew',
    color: 'blue'
  },
  {
    filter: 'closed',
    condition: record.active === false,
    text: 'Closed',
    color: 'magenta'
  }
]
