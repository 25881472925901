import React from 'react'

import { ProfilePictureComponent } from '../ProfilePictureComponent'
import { XProfilesSection } from '../XProfilesSection'

import { SocialIcon } from 'components/SocialIcon'

import type { BaseDataProps } from './BaseData.types'

import {
  profilePictureStyles,
  profilePictureFxtStyles,
  profilePictureXTeamerStyles,
  responsiveSectionStyles,
  sectionStyles,
  socialIconsStyles
} from './BaseData.module.css'

const BaseData = ({
  handleOpenXProfile,
  handleCreateXProfile,
  handleRequestXProfile,
  parsedResume,
  resumeMetadata,
  xProfiles,
  profileImageUrl,
  socialAccounts,
  updatedAt,
  createdAt,
  featureFlags,
  isFxt,
  xTeamerStatus,
  xProfileRequestedAt,
  xProfileRequestedBy,
}: BaseDataProps) => (
  <div className={sectionStyles}>
    <ProfilePictureComponent
      profileImageUrl={profileImageUrl}
      featureFlags={featureFlags}
      isFxt={isFxt}
      xTeamerStatus={xTeamerStatus}
      profilePictureStyles={profilePictureStyles}
      profilePictureFxtStyles={profilePictureFxtStyles}
      profilePictureXTeamerStyles={profilePictureXTeamerStyles}
    />

    <div className={socialIconsStyles}>
      <SocialIcon value={socialAccounts.linkedin} type="linkedin" />
      <SocialIcon value={socialAccounts.github} type="github" />
      <SocialIcon value={socialAccounts.stackoverflow} type="other" />
    </div>

    <div className={responsiveSectionStyles}>
      <XProfilesSection
        updatedAt={updatedAt}
        createdAt={createdAt}
        xProfiles={xProfiles}
        xProfileRequestedAt={xProfileRequestedAt}
        xProfileRequestedBy={xProfileRequestedBy}
        parsedResume={parsedResume}
        resumeMetadata={resumeMetadata}
        handleOpenXProfile={handleOpenXProfile}
        handleCreateXProfile={handleCreateXProfile}
        handleRequestXProfile={handleRequestXProfile}
      />
    </div>
  </div>
)

export default BaseData
