import React from 'react'

import { Tag } from '../../Tag'

const isFoundIn = (item, position) =>
  item.FoundIn?.map(section => section.Id).includes(position)

const SkillsList = ({ listId, skills, position }) => {
  const renderVariations = (skill, position) =>
    skill.Variations?.reduce((acc, variation) => {
      if (position && !isFoundIn(variation, position)) {
        return acc
      }
      const tag = (
        <Tag key={`${skill.Id}-${variation.Id}`} color="cyan">
          {variation.Name}
        </Tag>
      )
      return [...acc, tag]
    }, [])

  return skills?.reduce((acc, skill) => {
    const variations = renderVariations(skill, position)

    if (position && !variations?.length && !isFoundIn(skill, position)) {
      return acc
    }

    const tag = (
      <span key={`${listId}-${skill.Name}`}>
        <Tag color="blue">{skill.Name}</Tag>
        {variations}
      </span>
    )
    return acc.map(tag => tag.key).includes(tag.key) ? acc : [...acc, tag]
  }, [])
}

export default SkillsList
