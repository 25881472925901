import React, { useState } from 'react'

import { ScreeningsTable } from './components/ScreeningsTable'
import { TechEvaluationsTable } from './components/TechEvaluationsTable'

import { SectionCard } from 'redesign/components/'

import { useProfileScreenings } from '../../hooks/useProfileScreenings'
import { useProfileTechEvaluations } from '../../hooks/useProfileTechEvaluation'

import { useAutoSaveScreening } from 'redesign/hooks/useAutoSaveScreening'
import { useAutoSaveTechEvaluation } from 'redesign/hooks/useAutoSaveTechEvaluation'
import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useFindAndUpdateJobApplication } from 'redesign/hooks/useFindAndUpdateJobApplication'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileExperts } from 'redesign/hooks/useProfileExperts'
import { useProfileFxtStatus } from 'redesign/hooks/useProfileFxtStatus'
import { useProfileId } from 'redesign/hooks/useProfileId'
import { useUser } from 'redesign/hooks/useUser'

import type Screening from 'redesign/types/Screening'
import type TechnicalEvaluation from 'redesign/types/TechnicalEvaluation'

const InterviewsSection: React.FC = () => {
  const { profileId } = useProfileId()
  const { data: user } = useUser()

  const { data: profileExperts } = useProfileExperts()

  const {
    fxtStatus,
    isLoading: isFxtStatusLoading,
    updateProfileFxtStatus
  } = useProfileFxtStatus({ profileId: Number(profileId) })

  // Tech Evaluations logic
  const {
    data: techEvaluations,
    isLoading: techEvaluationsIsLoading,
    isMutationLoading: techEvaluationsIsMutationLoading,
    createTechEvaluation,
    updateTechEvaluation,
    deleteTechEvaluation
  } = useProfileTechEvaluations()

  const { findAndUpdateJobApplication } = useFindAndUpdateJobApplication()
  const {
    autoSaveTechEvaluation,
    isLoading: isAutoSaveTechEvaluationLoading
  } = useAutoSaveTechEvaluation()

  const { data: roleCandidates, isLoading: isLoadingRoleCandidates } =
    usePartnerRoleCandidates({
      params: {
        profile: profileId,
        bookedAt_null: true,
        closedAt_null: true
      }
    })
  const { data: features } = useFeatureFlags()

  const [expandedTechEvaluations, setExpandedTechEvaluations] = useState<
    number[]
  >([])

  const onExpandTechEvaluation = (
    expanded: boolean,
    row: TechnicalEvaluation
  ) => {
    if (expanded) {
      setExpandedTechEvaluations(state => [...state, row.id])
    } else {
      setExpandedTechEvaluations(state => state.filter(id => id !== row.id))
    }
  }

  const onExpandAllTechEvaluations = () => {
    const rowKeys = techEvaluations.map(techEvaluation => techEvaluation.id)
    if (expandedTechEvaluations.length === rowKeys.length) {
      setExpandedTechEvaluations([])
    } else {
      setExpandedTechEvaluations(rowKeys)
    }
  }

  const {
    data: screenings,
    isLoading: screeningsIsLoading,
    isMutationLoading: screeningsIsMutationLoading,
    createScreening,
    updateScreening,
    deleteScreening
  } = useProfileScreenings()

  const {
    autoSaveScreening,
    isLoading: isAutoSaveScreeningLoading
  } = useAutoSaveScreening()

  const [expandedScreenings, setExpandedScreenings] = useState<number[]>([])

  const onExpandScreening = (expanded: boolean, row: Screening) => {
    if (expanded) {
      setExpandedScreenings(state => [...state, row.id])
    } else {
      setExpandedScreenings(state => state.filter(id => id !== row.id))
    }
  }

  const onExpandAllScreenings = () => {
    const rowKeys = screenings.map(screening => screening.id)
    if (expandedScreenings.length === rowKeys.length) {
      setExpandedScreenings([])
    } else {
      setExpandedScreenings(rowKeys)
    }
  }

  const isLoading = Boolean(
    screeningsIsLoading || techEvaluationsIsLoading || isFxtStatusLoading
  )

  return (
    <>
      <SectionCard isLoading={isLoading}>
        <TechEvaluationsTable
          user={user}
          profileId={profileId}
          profileExperts={profileExperts}
          techEvaluations={techEvaluations}
          createTechEvaluation={createTechEvaluation}
          updateTechEvaluation={updateTechEvaluation}
          autoSaveTechEvaluation={autoSaveTechEvaluation}
          deleteTechEvaluation={deleteTechEvaluation}
          expandedRows={expandedTechEvaluations}
          onExpandRow={onExpandTechEvaluation}
          onExpandAllRows={onExpandAllTechEvaluations}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          features={features}
          updateFxtStatus={updateProfileFxtStatus}
          isFxt={fxtStatus}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={techEvaluationsIsMutationLoading || isAutoSaveTechEvaluationLoading}
        />
      </SectionCard>
      <SectionCard isLoading={isLoading}>
        <ScreeningsTable
          user={user}
          profileId={profileId}
          screenings={screenings}
          createScreening={createScreening}
          updateScreening={updateScreening}
          autoSaveScreening={autoSaveScreening}
          deleteScreening={deleteScreening}
          expandedRows={expandedScreenings}
          onExpandRow={onExpandScreening}
          onExpandAllRows={onExpandAllScreenings}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          features={features}
          updateFxtStatus={updateProfileFxtStatus}
          isFxt={fxtStatus}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={screeningsIsMutationLoading || isAutoSaveScreeningLoading}
        />
      </SectionCard>
    </>
  )
}

export default InterviewsSection
