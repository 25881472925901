import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getActivityLogs } from 'services/activityLogs'

import type { ActivityLog } from 'redesign/types/ActivityLog'
import type Profile from 'redesign/types/Profile'

type useActivityLogsParams = {
  profileId: Profile['id']
  page: number
  pageSize: number
}

type useActivityLogsReturn = {
  data: ActivityLog[]
  isLoading: boolean
}

const useActivityLogs = ({
  profileId,
  page,
  pageSize
}: useActivityLogsParams): useActivityLogsReturn => {
  const { data: activityLogsData, isLoading, isRefetching } = useQuery({
    queryKey: ['activityLog', profileId, page, pageSize],
    queryFn: () =>
      getActivityLogs({
        params: {
          profile: profileId,
          populate: [
            'user',
            'user.role',
            'roleCandidate',
            'roleCandidate.partnerRole',
            'roleCandidate.roleCandidateCloseReason',
            'roleCandidate.transferredTo',
            'roleCandidate.transferredTo.partnerRole',
            'jobApplication',
            'jobApplication.job'
          ],
          _sort: 'created_at:desc',
          _start: (page - 1) * pageSize,
          _limit: pageSize
        }
      }),
    enabled: Boolean(profileId),
  })
  const activityLogs = useMemo(
    () => activityLogsData?.data || [],
    [activityLogsData]
  )

  return {
    data: activityLogs,
    isLoading: isLoading || isRefetching
  }
}
export default useActivityLogs
