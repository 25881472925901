import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const readResume = async resumeId => {
  try {
    const response = await api.get(`/upload/files/${resumeId}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const uploadResume = async (resume, params) => {
  try {
    const response = await api.post('/upload', resume, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
