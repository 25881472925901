import { handleThrowError } from 'utils/services'

import { request as api } from './strapi/api'

export const listParsedResumeExtractedData = async params => {
  try {
    const response = await api.get(`/parsed-resume-extracted-data/`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
