import React from 'react'

import ACTIVITY_LOGS_MESSAGES from '../messages'
import { Timeline } from 'redesign/components'

import { renderDate } from 'utils/helpers'

import type { ActivityLog } from 'redesign/types/ActivityLog'

type ActivityLogsTimelineProps = {
  activityLogs: ActivityLog[]
}

const LogMessage = ({ log }: { log: ActivityLog }) => {
  const MessageComponent = ACTIVITY_LOGS_MESSAGES[log.type]

  if (MessageComponent) {
    return <MessageComponent log={log} />
  }

  return (
    <span>
      {log.type} by {log.user.username}.
    </span>
  )
}

const ActivityLogsTimeline = ({ activityLogs }: ActivityLogsTimelineProps) => (
  <Timeline>
    {activityLogs.map((log: ActivityLog) => (
      <Timeline.Item key={`${log.id}${log.type}`}>
        <span
          style={{ marginTop: '-5px', marginRight: '8px' }}
        >
          {renderDate(log.created_at)}:
        </span>
        <LogMessage log={log} />
      </Timeline.Item>
    ))}
  </Timeline>
)

export default ActivityLogsTimeline
