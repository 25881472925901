import React, { useState } from 'react'

import { SnoozeModal } from '../SnoozeModal'
import { ProfileSnoozeButton } from 'redesign/components'

import { EditButton } from 'components/generic/EditButton'
import { profileStatuses } from 'components/ProfileStatusTag/constants'
import { Row } from 'components/Row'

import type { ActionButtonsProps } from './ActionButtons.types'

import { editButtonStyles } from './ActionButtons.module.css'

const ActionButtons = ({
  profileSnooze,
  profileStatus,
  setIsEditMode,
  createProfileSnooze,
  deleteProfileSnooze,
  createSnoozeIsLoading,
  deleteSnoozeIsLoading,
  createSnoozeError,
  roleCandidates,
  roleCandidatesIsLoading
}: ActionButtonsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const hasOpenRole =
    roleCandidates?.filter(candidate => !candidate.closedAt).length > 0

  const hasActiveRunway =
    profileStatus?.activeRunway &&
    profileStatus?.status?.toString() !== profileStatuses.ready.value

  const hasBookedStatus =
    profileStatus?.status?.toString() === profileStatuses.booked.value

  const cannotSnooze = hasOpenRole || hasBookedStatus || hasActiveRunway
  const snoozeButtonTooltip = cannotSnooze
    ? 'You cannot snooze a candidate that has the current status.'
    : undefined

  return (
    <>
      <Row justify="end">
        <div className={editButtonStyles}>
          <EditButton onClick={() => setIsEditMode(true)} />
        </div>

        <ProfileSnoozeButton
          deleteSnoozeIsLoading={deleteSnoozeIsLoading}
          isSnoozed={Boolean(profileSnooze?.isSnoozed)}
          onSnoozeClick={() => setIsModalOpen(true)}
          onEndSnoozeClick={() =>
            deleteProfileSnooze(profileSnooze?.id as number)
          }
          isDisabled={Boolean(cannotSnooze)}
          isLoading={
            roleCandidatesIsLoading ||
            createSnoozeIsLoading ||
            deleteSnoozeIsLoading
          }
          tooltip={snoozeButtonTooltip}
        />
      </Row>
      {isModalOpen && (
        <SnoozeModal
          visible={isModalOpen}
          createProfileSnooze={createProfileSnooze}
          closeModal={() => setIsModalOpen(false)}
          isLoading={roleCandidatesIsLoading || createSnoozeIsLoading}
          error={createSnoozeError}
        />
      )}
    </>
  )
}

export default ActionButtons
