import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const getProfileVotes = async params => {
  try {
    const response = await api.get('/profile-votes', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createProfileVote = async data => {
  try {
    const response = await api.post(`/profile-votes`, data)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateProfileVote = async (id, values) => {
  try {
    const response = await api.put(`/profile-votes/${id}`, values)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteProfileVote = async id => {
  try {
    const response = await api.delete(`/profile-votes/${id}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
