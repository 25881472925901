import React from 'react'

import { renderDate } from 'utils/helpers'

import type { BookedStatusDatesProps } from './BookedStatusDates.types'

import { bookedStatusDatesStyles, bookedStatusDatesSeparatorStyles } from './BookedStatusDates.module.css'

const BookedStatusDates = ({ startDate, endDate }: BookedStatusDatesProps) => {
  // It's unlikely there will be no start date as it's mandatory
  // but we're handling this case consistently to end date absence.
  const startDateValue = startDate ? `Start Date: ${renderDate(startDate)}` : 'No Start Date'
  const endDateValue = endDate ? `End Date: ${renderDate(endDate)}` : 'No End Date'

  return (
    <div className={bookedStatusDatesStyles}>
      {startDateValue} <span className={bookedStatusDatesSeparatorStyles}>|</span> {endDateValue}
    </div>
  )
}

export default BookedStatusDates
