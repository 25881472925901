import React from 'react'

import { useProfile } from '../../hooks'
import { SectionCard } from 'redesign/components'

import { Marketing } from './components'

const MarketingSection = () => {
  const { data: profile, isLoading, updateProfile } = useProfile()

  return (
    <SectionCard isLoading={isLoading}>
      <Marketing profile={profile} onSave={updateProfile} />
    </SectionCard>
  )
}

export default MarketingSection
