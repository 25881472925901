import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listJobApplications } from 'services/jobApplications'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type JobApplication from 'redesign/types/JobApplication'

import type { IUseProfileJobApplicationsReturn } from './useProfileJobApplications.types'

const useProfileJobApplications = (): IUseProfileJobApplicationsReturn => {
  const { profileId } = useProfileId()

  const { data: jobApplicationsData, isLoading } = useQuery({
    queryKey: ['jobApplications', profileId],
    queryFn: () =>
      listJobApplications({
        params: { profile: profileId, populate: ['job'] }
      })
  })

  const data = useMemo(
    () => jobApplicationsData?.data || [],
    [jobApplicationsData]
  )
  const totalDataCount = useMemo(
    () => jobApplicationsData?.data.length || 0,
    [jobApplicationsData]
  )
  const pendingDataCount = useMemo(
    () =>
      (jobApplicationsData?.data || []).filter(
        (item: JobApplication) =>
          item.active === true &&
          item.applied === true &&
          item.hiddenAt === null
      ).length || 0,
    [jobApplicationsData]
  )

  return {
    data,
    dataCount: totalDataCount,
    pendingDataCount,
    isLoading
  }
}
export default useProfileJobApplications
