import { useMemo } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  create as createTechEvaluationService,
  deleteTechEval as deleteTechEvaluationService,
  listDraftTechEval,
  listTechEvals,
  update as updateTechEvaluationService
} from 'services/techEvals'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { MutationOptions } from 'redesign/types/Mutation'
import type { TechnicalEvaluationFormValues } from 'redesign/types/TechnicalEvaluation'

import type {
  IUseProfileTechEvaluationsReturn,
  UpdateTechEvaluationParams
} from './useProfileTechEvaluations.types'

const useProfileTechEvaluations = (): IUseProfileTechEvaluationsReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  const invalidateRelatedQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['techEvaluations'] })
    queryClient.invalidateQueries({ queryKey: ['draftTechEvaluations'] })
    queryClient.invalidateQueries({ queryKey: ['candidateSkills'] })
    queryClient.invalidateQueries({
      queryKey: ['role-candidates', { profile: profileId }]
    })
    queryClient.invalidateQueries({ queryKey: ['activityLog'] })
    queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
  }

  // Get
  const { data: techEvaluationsData, isLoading: techEvaluationsIsLoading } =
    useQuery({
      queryKey: ['techEvaluations', profileId],
      queryFn: () =>
        listTechEvals({
          params: {
            profile: profileId,
            populate: [
              'skills',
              'author',
              'expert',
              'role_candidate',
              'role_candidate.assignee',
              'role_candidate.partnerRole',
              'role_candidate.partnerRole.partner',
              'role_candidate.partnerRole.roleSkills',
              'role_candidate.partnerRole.roleSkills.skill'
            ]
          }
        })
    })
  const techEvaluations = useMemo(
    () => techEvaluationsData?.data || [],
    [techEvaluationsData]
  )
  const techEvaluationsCount = useMemo(
    () => techEvaluationsData?.data.length || 0,
    [techEvaluationsData]
  )

  const {
    data: draftTechEvaluationData,
    isLoading: draftTechEvaluationsIsLoading
  } = useQuery({
    queryKey: ['draftTechEvaluations', profileId],
    queryFn: () =>
      listDraftTechEval({
        params: { profile: profileId, populate: ['skills', 'author'] }
      })
  })
  const draftTechEvaluation = useMemo(
    () => draftTechEvaluationData?.data || [],
    [draftTechEvaluationData]
  )
  const draftTechEvaluationCount = useMemo(
    () => draftTechEvaluationData?.data.length || 0,
    [draftTechEvaluationData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createTechEvaluationService,
    onSuccess: () => {
      message.success('Technical Evaluation created')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Create Technical Evaluation failed')
    }
  })

  const createTechEvaluation = (
    values: TechnicalEvaluationFormValues,
    callbacks?: MutationOptions
  ) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateTechEvaluationParams
  >({
    mutationFn: ({ techEvaluationId, values }) =>
      updateTechEvaluationService(techEvaluationId, values),
    onSuccess: () => {
      message.success('Technical Evaluation updated')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Update Technical Evaluation failed')
    }
  })

  const updateTechEvaluation = (
    values: UpdateTechEvaluationParams,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteTechEvaluationService,
    onSuccess: () => {
      message.success('Technical Evaluation deleted')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Delete Technical Evaluation failed')
    }
  })

  const deleteTechEvaluation = (techEvaluationId?: number) => {
    deleteMutation.mutate(techEvaluationId)
  }

  return {
    data: [...techEvaluations, ...draftTechEvaluation],
    dataCount: techEvaluationsCount + draftTechEvaluationCount,
    isLoading: Boolean(
      techEvaluationsIsLoading || draftTechEvaluationsIsLoading
    ),
    isMutationLoading: Boolean(
      createMutation.isLoading ||
        updateMutation.isLoading ||
        deleteMutation.isLoading
    ),
    createTechEvaluation,
    updateTechEvaluation,
    deleteTechEvaluation
  }
}

export default useProfileTechEvaluations
