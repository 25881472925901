import React from 'react'

import { useProfile } from '../../hooks'

import { LegacyPanel, SnoozePanel } from './components'

const NotificationPanels = () => {
  const { data: profile } = useProfile()

  return (
    <>
      <LegacyPanel profile={profile} />
      <SnoozePanel profileSnooze={profile.profileSnooze} />
    </>
  )
}

export default NotificationPanels
