import React, { useState } from 'react'

import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import type {
  UploadRequestOption,
  UploadRequestError
} from 'rc-upload/lib/interface'

import { Button } from 'components/Button'
import { FormItem } from 'components/Form'
import { message } from 'components/Message'
import { Modal } from 'components/Modal'
import { Progress } from 'components/Progress'
import type { RcFile, UploadChangeParam } from 'components/Upload'
import { Upload } from 'components/Upload'

import { LockedFormItemModal } from 'redesign/components/LockedFormItemModal'

import type { IResumeProps } from './Resume.types'

import {
  modalContentStyles,
  modalContentTextStyles,
  iconStyles
} from './Resume.module.css'

const allowedFormats = ['.pdf', '.doc', '.docx', '.odt', '.rdf', '.txt']
const recommendedFormats = ['.pdf', '.doc', '.docx', '.odt']

const Resume = ({
  resume,
  setErrorMessage,
  parseProfileResume,
  deleteProfileResume,
  fileList,
  clearFileList,
  uploadResume,
  resumeData,
  uploadProgress
}: IResumeProps) => {
  const [actionType, setActionType] = useState('')
  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [file, setFile] = useState<RcFile | undefined>()

  const upload = async (resumeParams: UploadRequestOption) => {
    if (fileList.length > 0) {
      remove()
    }

    await uploadResume(resumeParams)

    parseProfileResume({
      onSuccess: () => resumeParams.onSuccess?.(resumeData),
      onError: (err: UploadRequestError | ProgressEvent) => {
        setErrorMessage(
          'Oops We couldn’t scan the information in your file. Make sure to upload a file with the following format .pdf, doc, .docx, .odt.'
        )
        resumeParams.onError?.(err)
      }
    })
  }

  const beforeUpload = () => {
    setActionType('upload')
    setIsLockedModalOpen(true)
    return false
  }

  const beforeRemove = () => {
    setActionType('remove')
    setIsLockedModalOpen(true)
  }

  const confirmEdit = () => {
    setIsLockedModalOpen(false)
    if (actionType === 'remove') {
      setIsRemoveModalOpen(true)
    } else {
      const resumeParams: UploadRequestOption = {
        file: file ?? '',
        action: '',
        method: 'POST'
      }
      upload(resumeParams)
    }
  }

  const remove = async () => {
    setIsRemoveModalOpen(false)
    await deleteProfileResume(resume?.id)
    clearFileList()
  }

  const onChange = (info: UploadChangeParam) => {
    setFile(info.file as RcFile)
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  return (
    <>
      <FormItem>
        <Upload
          accept={allowedFormats.join(',')}
          name="files"
          onChange={onChange}
          beforeUpload={beforeUpload}
          multiple={false}
          onRemove={beforeRemove}
          fileList={fileList}
          showUploadList={{ showDownloadIcon: true, showRemoveIcon: true }}
        >
          <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>
            Click to Upload
          </Button>
          {uploadProgress > 0 ? <Progress percent={uploadProgress} /> : null}
        </Upload>
        <span hidden={fileList.length > 0}>
          Recommended formats: {recommendedFormats.join(', ')}
        </span>
      </FormItem>
      <Modal
        open={isRemoveModalOpen}
        okText="REMOVE"
        onOk={remove}
        cancelText="CANCEL"
        onCancel={() => setIsRemoveModalOpen(false)}
      >
        <div className={modalContentStyles}>
          <ExclamationCircleOutlined className={iconStyles} />
          <span className={modalContentTextStyles}>Are you sure you want to remove this file?</span>
        </div>
      </Modal>
      <LockedFormItemModal
        visible={isLockedModalOpen}
        onOk={confirmEdit}
        onCancel={() => setIsLockedModalOpen(false)}
      />
    </>
  )
}

export default Resume
