import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  listNotes,
  deleteNote as deleteNoteService,
  createNote as createNoteService,
  updateNote as updateNoteService
} from 'services/notes'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Note from 'redesign/types/Note'

import type {
  IUseProfileNotesReturn,
  UpdateNoteValueParams
} from './useProfileNotes.types'

const useProfileNotes = (): IUseProfileNotesReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  // Get
  const { data: notesData, isLoading } = useQuery({
    queryKey: ['notes', profileId],
    queryFn: () =>
      listNotes({ params: { profile: profileId, populate: ['tags'] } })
  })
  const data: Note[] = useMemo(() => notesData?.data || [], [notesData])
  const dataCount: number = useMemo(
    () => notesData?.data.length || 0,
    [notesData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createNoteService,
    onSuccess: () => {
      message.success('Note created')
      queryClient.invalidateQueries({ queryKey: ['notes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Create Note failed')
    }
  })

  const createNote = (values: Note, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateNoteValueParams
  >({
    mutationFn: ({ noteId, values }) => updateNoteService(noteId, values),
    onSuccess: () => {
      message.success('Note updated')
      queryClient.invalidateQueries({ queryKey: ['notes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Update Note failed')
    }
  })

  const updateNote = (
    values: UpdateNoteValueParams,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteNoteService,
    onSuccess: () => {
      message.success('Note deleted')
      queryClient.invalidateQueries({ queryKey: ['notes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Delete Note failed')
    }
  })

  const deleteNote = (noteId: number) => {
    deleteMutation.mutate(noteId)
  }

  return {
    data,
    dataCount,
    isLoading,
    createNote,
    updateNote,
    deleteNote
  }
}
export default useProfileNotes
