// extracted by mini-css-extract-plugin
export var borderWrapperStyles = "SummaryData-module--borderWrapperStyles--05480";
export var breakpointMD = "1099px";
export var breakpoints = "'theme/breakpoints.module.css'";
export var colors = "'theme/colors.module.css'";
export var gainsBoro = "#DBDBDB";
export var leftBorderStyles = "SummaryData-module--leftBorderStyles--71170";
export var lunarRock = "#C5C5C5";
export var phillipineSilver = "#B6B6B6";
export var raisinBlack = "#262626";
export var summaryBlockStyles = "SummaryData-module--summaryBlockStyles--0192a";
export var summaryByCandidateContentStyles = "SummaryData-module--summaryByCandidateContentStyles--023cb";
export var summaryByCandidateTitleStyles = "SummaryData-module--summaryByCandidateTitleStyles--a9d2f";
export var summaryByCavalryContentStyles = "SummaryData-module--summaryByCavalryContentStyles--5a6a2";
export var summaryByCavalryTitleStyles = "SummaryData-module--summaryByCavalryTitleStyles--2754c";
export var summaryStyles = "SummaryData-module--summaryStyles--a65d0";