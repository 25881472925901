import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  createProfileSnooze as createProfileSnoozeService,
  deleteProfileSnooze as deleteProfileSnoozeService
} from 'services/profileSnoozes'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { MutationOptions } from 'redesign/types/Mutation'

import type {
  IUseProfileSnoozeReturn,
  CreateSnoozeParams
} from './useProfileSnooze.types'

const useProfileSnooze = (): IUseProfileSnoozeReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  // Post
  const createMutation = useMutation<
    AxiosResponse,
    AxiosError,
    CreateSnoozeParams
  >({
    mutationFn: values =>
      createProfileSnoozeService({ profile: profileId, ...values }),
    onSuccess: () => {
      message.success('Profile snoozed')
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Snooze profile failed')
    }
  })

  const createProfileSnooze = (values: CreateSnoozeParams, callbacks: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  const createSnoozeError: string =
    createMutation.error?.response?.data.message

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteProfileSnoozeService,
    onSuccess: () => {
      message.success('Profile unsnoozed')
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Unsnooze profile failed')
    }
  })

  const deleteProfileSnooze = (snoozeId: number) => {
    deleteMutation.mutate(snoozeId)
  }

  return {
    createProfileSnooze,
    createSnoozeIsLoading: createMutation.isLoading,
    createSnoozeError,
    deleteProfileSnooze,
    deleteSnoozeIsLoading: deleteMutation.isLoading
  }
}
export default useProfileSnooze
