import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import { requestXProfileService } from 'services/profile'

import type { MutationOptions } from 'redesign/types/Mutation'

import type {
  UseRequestXProfilesParams,
  UseRequestXProfilesReturn
} from './useRequestXProfile.types'

const useRequestXProfile =
  (): UseRequestXProfilesReturn => {
    const queryClient = useQueryClient()

    const requestXProfileMutation = useMutation<
      AxiosResponse,
      AxiosError,
      UseRequestXProfilesParams
    >({
      mutationFn: params => requestXProfileService(params),
      onSuccess: () => {
        message.success('X-Profile requested')
        queryClient.invalidateQueries({ queryKey: ['profile'] })
        queryClient.invalidateQueries({ queryKey: ['activityLog'] })
        queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
      },
      onError: () => {
        message.error('X-Profile requested failed')
      }
    })

    const requestXProfile = (
      params: UseRequestXProfilesParams,
      options?: MutationOptions
    ) => requestXProfileMutation.mutate(params, options)

    return {
      requestXProfile,
      isLoading: requestXProfileMutation.isLoading
    }
  }

export default useRequestXProfile
