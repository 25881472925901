import React from 'react'

import {
  ProfilePicture,
  ProfilePictureFxt,
  ProfilePictureXTeamer
} from 'redesign/components'

import type { ProfilePictureComponentProps } from './ProfilePictureComponent.types'

const ProfilePictureComponent = ({
  profileImageUrl,
  featureFlags,
  isFxt,
  xTeamerStatus,
  profilePictureStyles,
  profilePictureFxtStyles,
  profilePictureXTeamerStyles
}: ProfilePictureComponentProps) => {
  if (featureFlags?.RELEASE_XTEAMER_STATUS) {
    const status = xTeamerStatus?.status

    if (status) {
      return (
        <ProfilePictureXTeamer
          xTeamerStatus={status}
          src={profileImageUrl}
          className={profilePictureXTeamerStyles}
        />
      )
    }

    return (
      <ProfilePicture src={profileImageUrl} className={profilePictureStyles} />
    )
  }

  if (featureFlags?.RELEASE_FXT_IMPROVEMENTS && isFxt) {
    return (
      <ProfilePictureFxt
        src={profileImageUrl}
        className={profilePictureFxtStyles}
      />
    )
  }

  return (
    <ProfilePicture src={profileImageUrl} className={profilePictureStyles} />
  )
}

export default ProfilePictureComponent
