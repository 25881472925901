import { useEffect, useState } from 'react'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { UploadRequestOption, UploadProgressEvent } from 'rc-upload/lib/interface'

import {
  readResume as readResumeService,
  uploadResume as uploadResumeService
} from 'services/resume'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { File } from 'redesign/types/File'
import type { UploadedFile } from 'redesign/types/UploadedFile'

import type { FormDataObj, IUseResumeReturn } from './useResume.types'

const useResume = (profileResume?: UploadedFile): IUseResumeReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  const [resumeData, setResumeData] = useState(null)
  const [fileList, setFileList] = useState<File[]>([])

  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const resumeExists = Boolean(
    profileResume && Object.keys(profileResume).length > 0
  )

  // Get
  const { data: readResumeData, isLoading: isLoadingResume } = useQuery({
    queryKey: ['resume', profileResume?.id],
    enabled: resumeExists,
    queryFn: () => readResumeService(profileResume?.id)
  })

  useEffect(() => {
    if (readResumeData?.data) {
      setFileList([
        {
          status: 'done',
          uid: readResumeData.data.id,
          url: readResumeData.data.url,
          name: readResumeData.data.name
        }
      ])
    } else {
      clearFileList()
    }
  }, [readResumeData, profileId])

  // Post
  // This request is not in react-query because of the file config params
  const uploadResume = async (resumeParams: UploadRequestOption) => {
    const formData = new FormData()

    const dataObj: FormDataObj = {
      ref: 'profile',
      path: 'resume',
      field: 'resume',
      refId: profileId,
      files: resumeParams.file
    }

    Object.keys(dataObj).forEach(key => {
      const value = dataObj[key as keyof FormDataObj]
      formData.append(key, value as string | Blob)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event: Required<UploadProgressEvent>) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        setUploadProgress(percent)
        resumeParams.onProgress?.({ percent: (event.loaded / event.total) * 100 })
      }
    }

    setUploadLoading(true)

    const {
      data: [resumeData]
    } = await uploadResumeService(formData, config)
    queryClient.invalidateQueries({ queryKey: ['profile'] })
    queryClient.invalidateQueries({ queryKey: ['activityLog'] })
    queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })

    setResumeData(resumeData)
    setFileList([
      {
        status: 'done',
        uid: resumeData.id,
        url: resumeData.url,
        name: resumeData.name
      }
    ])

    setUploadLoading(false)
    setUploadProgress(0)
  }

  const clearFileList = () => {
    setFileList([])
  }

  const isLoading = (resumeExists && isLoadingResume) || uploadLoading

  return {
    uploadResume,
    fileList,
    clearFileList,
    resumeData,
    uploadProgress,
    isLoading
  }
}
export default useResume
