import React, { useState } from 'react'

import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { NotesForm } from '../NotesForm'

import { Button } from 'components/Button'
import { Row } from 'components/Row'
import { Tag } from 'components/Tag'

import { App } from 'redesign/components/App'

import { sortByField } from 'utils/helpers'

import { replaceLineBreakWithBr } from 'redesign/utils/stringUtils/stringUtils'

import type NoteTag from 'redesign/types/NoteTag'

import type { IExpandedRowProps } from './ExpandedRow.types'

import { deleteButtonStyles } from './ExpandedRow.module.css'

// @TODO(TS migration): extract to a separate component.
// The fragment around is important since it's expected it return
// a single element to be used inside the 'render' method.
const NoteTags = ({ tags }: { tags: NoteTag[] }) => (
  <>
    {(tags || []).sort(sortByField('slug')).map(({ id, slug }) => (
      <Tag key={id} color="blue">
        {slug}
      </Tag>
    ))}
  </>
)

const ExpandedRow = ({
  user,
  profileId,
  note,
  updateNote,
  deleteNote
}: IExpandedRowProps) => {
  const { modal: { confirm } } = App.useApp()

  const [isEdit, setIsEdit] = useState(false)

  const isAuthor = note.author === user.id
  const { canUpdateAnyNote } = user?.role?.capabilities || {}
  const showActionButtons = isAuthor || canUpdateAnyNote

  const onClickDelete = () => {
    confirm({
      title: 'Permanently delete item? You can\'t undo this.',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        deleteNote(note.id)
      }
    })
  }

  const onClickEdit = () => {
    setIsEdit(true)
  }

  const closeForm = () => {
    setIsEdit(false)
  }

  if (isEdit) {
    return (
      <NotesForm
        initialValues={note}
        profileId={profileId}
        updateNote={updateNote}
        closeForm={closeForm}
      />
    )
  }

  return (
    <>
      <Row justify="space-between">
        <p
          dangerouslySetInnerHTML={{
            __html: replaceLineBreakWithBr(note.body)
          }}
        />

        {showActionButtons && (
          <Row>
            <Button
              data-testid="profileNotesTable-deleteButton"
              danger
              type="primary"
              icon={<DeleteOutlined />}
              className={deleteButtonStyles}
              onClick={onClickDelete}
            />
            <Button
              data-testid="profileNotesTable-editButton"
              icon={<EditOutlined />}
              onClick={onClickEdit}
            />
          </Row>
        )}
      </Row>

      <NoteTags tags={note.tags} />
    </>
  )
}

export default ExpandedRow
