import React from 'react'

import moment from 'moment'

import { XProfiles } from 'redesign/components'

import { Tooltip } from 'components/Tooltip'

import type { XProfilesSectionProps } from './XProfilesSection.types'

import {
  accountHistoryStyles,
  responsiveRowStyles
} from './XProfilesSection.module.css'

const historyDateFormat = 'D MMM YYYY, HH:mm'

const XProfilesSection = ({
  parsedResume,
  resumeMetadata,
  createdAt,
  updatedAt,
  xProfiles,
  xProfileRequestedAt,
  xProfileRequestedBy,
  handleOpenXProfile,
  handleCreateXProfile,
  handleRequestXProfile,
}: XProfilesSectionProps) => (
  <div className={responsiveRowStyles}>
    <div className={accountHistoryStyles}>
      <div data-testid="profile-updated-at">
        Updated{' '}
        <Tooltip
          title={moment(updatedAt).format(historyDateFormat)}
          style={{ whiteSpace: 'nowrap' }}
        >
          {moment(updatedAt).fromNow()}
        </Tooltip>
      </div>
      <div data-testid="profile-created-at">
        Created{' '}
        <Tooltip
          title={moment(createdAt).format(historyDateFormat)}
          style={{ whiteSpace: 'nowrap' }}
        >
          {moment(createdAt).fromNow()}
        </Tooltip>
      </div>
    </div>

    <XProfiles
      xProfiles={xProfiles}
      onProfileClick={(id: number) => handleOpenXProfile(id, 'id')}
      onManageProfileClick={handleOpenXProfile}
      parsedResume={parsedResume}
      resumeMetadata={resumeMetadata}
      xProfileRequestedAt={xProfileRequestedAt}
      xProfileRequestedBy={xProfileRequestedBy}
      onCreateProfileClick={handleCreateXProfile}
      onRequestProfileClick={handleRequestXProfile}
    />
  </div>
)

export default XProfilesSection
