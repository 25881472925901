import type { ReactNode } from 'react'
import React from 'react'

import { useLocation } from '@reach/router'

import { getSelectedId } from 'redesign/utils/queryStringUtils'

import ProfileIdContext from './ProfileIdContext'

const ProfileIdProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const profileId = getSelectedId(location.search)

  return (
    <ProfileIdContext.Provider value={{ profileId }}>
      {children}
    </ProfileIdContext.Provider>
  )
}

export default ProfileIdProvider
