import React, { useState } from 'react'

import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import { RunwayModal } from '../RunwayModal'

import { Button } from 'components/Button'

import { App } from 'redesign/components/App'

import {
  getInUSDPerHourFormat,
  getDateInReadableFormat,
  getElapsedDaysOutOfDurationFormat
} from 'utils/runway-helpers'

import type { IExpandedRowProps } from './ExpandedRow.types'

import { deleteButtonStyles, dataSectionStyles } from './ExpandedRow.module.css'

const ExpandedRow = ({
  profileId,
  runway,
  onEdit,
  onDelete
}: IExpandedRowProps) => {
  const { modal: { confirm } } = App.useApp()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClickDelete = () => {
    confirm({
      title: 'Permanently delete item? You can\'t undo this.',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        onDelete(runway.id)
      }
    })
  }

  const onClickEdit = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={dataSectionStyles}>
        <span>
          <strong>Status:</strong> {runway.active ? 'Active' : 'Inactive'}
        </span>

        {runway.rate && (
          <span>
            <strong>Rate:</strong> {getInUSDPerHourFormat(runway.rate)}
          </span>
        )}

        <span>
          <strong>Start:</strong>{' '}
          {getDateInReadableFormat(runway.startAt as string)}
        </span>

        <span>
          <strong>End:</strong>{' '}
          {runway.endingDate && getDateInReadableFormat(runway.endingDate)}
          {!runway.endingDate && `Not defined yet`}
        </span>

        <span>
          {getElapsedDaysOutOfDurationFormat(
            runway.elapsed,
            runway.endingDuration
          )}
        </span>

        {runway.description && <span>({runway.description})</span>}
      </div>

      <Button
        data-testid="profileRunwaysTable-deleteButton"
        danger
        type="primary"
        className={deleteButtonStyles}
        icon={<DeleteOutlined />}
        onClick={onClickDelete}
      />
      <Button
        data-testid="profileRunwaysTable-editButton"
        icon={<EditOutlined />}
        onClick={onClickEdit}
      />

      <RunwayModal
        visible={isModalOpen}
        onEdit={onEdit}
        profileId={profileId}
        closeForm={closeModal}
        initialValues={runway}
      />
    </>
  )
}

export default ExpandedRow
