import { useContext } from 'react'

// Context
import { ProfileIdContext } from 'redesign/providers/ProfileIdProvider'

const useProfileId = () => {
  const profileIdState = useContext(ProfileIdContext)

  if (!profileIdState) {
    throw new Error('useProfileId Error: ProfileIdProvider not found')
  }

  return profileIdState
}

export default useProfileId
