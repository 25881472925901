import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { useProfileStatus } from '../useProfileStatus'

import type { IRunwaysNormalizedValues } from '../../containers/InternalSection/components/RunwaysTable/RunwaysTable.types'

import { message } from 'components/Message'

import {
  listRunways,
  createRunway as createRunwayService,
  updateRunway as updateRunwayService,
  deleteRunway as deleteRunwayService
} from 'services/runways'

import { useProfileId } from 'redesign/hooks/useProfileId'

import { getRunwayWithExtraInfo } from 'utils/runway-helpers'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Runway from 'redesign/types/Runway'

import type {
  IUseProfileRunwaysReturn,
  UpdateRunwayValueParams
} from './useProfileRunways.types'

const useProfileRunways = (): IUseProfileRunwaysReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()
  const { refetch: refetchStatus } = useProfileStatus()

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['runways', profileId] })
    queryClient.invalidateQueries({
      queryKey: ['profileXTeamerStatus', profileId]
    })
    queryClient.invalidateQueries({ queryKey: ['activityLog'] })
    queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
  }

  // Get
  const { data: runwaysData, isLoading } = useQuery<
    AxiosResponse<Array<Runway & Partial<IRunwaysNormalizedValues>>>
  >({
    queryKey: ['runways', profileId],
    queryFn: () =>
      listRunways({ params: { profile: profileId, populate: ['runways'] } })
  })

  const data = useMemo(
    () =>
      runwaysData?.data.map(
        runway =>
          getRunwayWithExtraInfo(runway) as Runway &
            Partial<IRunwaysNormalizedValues>
      ) || [],
    [runwaysData?.data]
  )
  const dataCount = useMemo(() => runwaysData?.data.length || 0, [runwaysData])
  const activeRunways = useMemo(
    () => runwaysData?.data.filter(runway => runway.active),
    [runwaysData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createRunwayService,
    onSuccess: async () => {
      message.success('Runway created')
      await refetchStatus()
      invalidateQueries()
    },
    onError: () => {
      message.error('Create Runway failed')
    }
  })

  const createRunway = (values: Runway, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateRunwayValueParams
  >({
    mutationFn: ({ runwayId, values }) => updateRunwayService(runwayId, values),
    onSuccess: async () => {
      message.success('Runway updated')
      await refetchStatus()
      invalidateQueries()
    },
    onError: () => {
      message.error('Update Runway failed')
    }
  })

  const updateRunway = (
    values: UpdateRunwayValueParams,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteRunwayService,
    onSuccess: async () => {
      message.success('Runway deleted')
      await refetchStatus()
      invalidateQueries()
    },
    onError: () => {
      message.error('Runway Experience failed')
    }
  })

  const deleteRunway = (values: number, callbacks?: MutationOptions) => {
    deleteMutation.mutate(values, callbacks)
  }

  return {
    data,
    dataCount,
    activeRunways,
    isLoading,
    createRunway,
    updateRunway,
    deleteRunway
  }
}
export default useProfileRunways
