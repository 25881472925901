import { handleThrowError, normalizeConfig } from '../utils/services'

import { request as api, getCacheBuster } from './strapi/api'

export const listTestAssessments = async (config = {}) => {
  try {
    normalizeConfig(config)
    config.params._cb = getCacheBuster('testAssessments')

    const response = await api.get(`/test-assessments`, config)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
