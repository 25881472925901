import { useMutation, useQueryClient } from '@tanstack/react-query'

import { message } from 'components/Message'

import {
  parseProfileResume as parseProfileResumeService,
  deleteProfileResume as deleteProfileResumeService
} from 'services/profile'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { IUseProfileResumeReturn } from './useProfileResume.types'

const useProfileResume = (): IUseProfileResumeReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  // Post
  const parseProfileResumeMutation = useMutation({
    mutationFn: parseProfileResumeService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
    },
    onError: () => {
      message.error('Resume not parsable')
    },
    retry: false
  })

  const parseProfileResume = (callbacks: any) => {
    parseProfileResumeMutation.mutate(profileId, callbacks)
  }

  // Delete
  const deleteProfileResumeMutation = useMutation({
    mutationFn: () => deleteProfileResumeService(profileId),
    onSuccess: (_, resumeDataId) => {
      message.success('Resume deleted')
      queryClient.invalidateQueries({ queryKey: ['profile'] })

      // Reset and delete stale cache key we no longer need
      const resumeQueryKey = ['resume', resumeDataId]
      queryClient.setQueryData(resumeQueryKey, null)
      queryClient.removeQueries(resumeQueryKey)
    },
    onError: () => {
      message.error('Delete resume failed')
    }
  })

  const deleteProfileResume = (resumeDataId?: number) => {
    // @ts-expect-error this is require to pass the cache key to invalidate properly
    // inside mutation's onSuccess callback.
    deleteProfileResumeMutation.mutateAsync(resumeDataId)
  }

  return {
    parseProfileResume,
    deleteProfileResume
  }
}
export default useProfileResume
