// extracted by mini-css-extract-plugin
export var colors = "'theme/colors.module.css'";
export var darkCharcoal = "#303030";
export var eerieBlack = "#1F1F1F";
export var emailStyles = "RelatedProfilesModal-module--emailStyles--4891e";
export var fullNameStyles = "RelatedProfilesModal-module--fullNameStyles--c7dee";
export var gainsBoro = "#DBDBDB";
export var idStyles = "RelatedProfilesModal-module--idStyles--cf76a";
export var indicatorStyles = "RelatedProfilesModal-module--indicatorStyles--d0df2";
export var profileBlockStyles = "RelatedProfilesModal-module--profileBlockStyles--3ff89";
export var relatedProfilesModalStyles = "RelatedProfilesModal-module--relatedProfilesModalStyles--f3f02";
export var sootyBlack = "#141414";