import React, { useCallback } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'

import { CandidateSkills } from '../CandidateSkills'
import { Resume } from '../Resume'
import { SectionCardContent } from 'redesign/components'

import { Alert } from 'components/Alert'
import ResumeDetails from 'components/Profile/Resume/ResumeDetails'
import type { RadioChangeEvent } from 'components/RadioGroup'
import { RadioGroup } from 'components/RadioGroup'
import { RelativeDate } from 'components/RelativeDate'
import { Spin } from 'components/Spin'
import { ErrorIcon } from 'components/Svg'

import type { ResumeSectionProps } from './ResumeSection.types'

import {
  cvError,
  cvTable,
  cvWarning,
  cvWarningLink,
  tableHead,
  tableRow
} from './ResumeSection.module.css'

const ResumeSection = ({
  resumeFileMeta,
  parseProfileResume,
  deleteProfileResume,
  fileList,
  clearFileList,
  uploadResume,
  resumeData,
  candidateSkills,
  uploadProgress,
  isLoading,
  selectedTab,
  setSelectedTab,
  errorMessage,
  setErrorMessage,
  parsedResumeWarnings,
  parsedResume,
  features
}: ResumeSectionProps) => {
  const resumeUpdatedAt = resumeFileMeta?.updated_at || new Date()

  const isCandidateSkillsEnabled = Boolean(features?.RELEASE_CANDIDATE_SKILLS)

  const onChangeCvTab = useCallback((e: RadioChangeEvent) => {
    const { value } = e.target
    setSelectedTab(value)
  }, [setSelectedTab])

  const cvTabs = [
    {
      label: 'File',
      value: 'file'
    },
    {
      label: (
        <>
          Structured
          <Spin
            hidden={!isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </>
      ),
      value: 'structured',
      disabled: isEmpty(resumeFileMeta)
    }
  ]

  const CandidateSkillsRenderer = () => (
    <CandidateSkills candidateSkills={candidateSkills} />
  )

  const resumeNotParsable = !isEmpty(resumeFileMeta) && !isLoading && errorMessage
  const showSkillsWithoutCV =
    isCandidateSkillsEnabled &&
    (isEmpty(resumeFileMeta) || resumeNotParsable) &&
    candidateSkills.length > 0

  return (
    <SectionCardContent
      title="CV"
      actions={
        <RadioGroup
          options={cvTabs}
          optionType="button"
          buttonStyle="solid"
          value={selectedTab}
          onChange={onChangeCvTab}
        />
      }
    >
      {resumeNotParsable && selectedTab !== 'structured' && (
        <Alert
          className={cvWarning}
          message={
            <span>
              Warning. The CV of this applicant is not parsable. You can{' '}
              <a
                target="_blank"
                rel="noreferrer noopener"
                className={cvWarningLink}
                href={resumeFileMeta?.url}
              >
                Download the CV here
              </a>
            </span>
          }
          type="warning"
          showIcon
        />
      )}
      <table className={cvTable} hidden={selectedTab !== 'file'}>
        <tbody>
          <tr className={tableRow}>
            <th className={tableHead}>File</th>
            <td>
              <Resume
                resume={resumeFileMeta}
                setErrorMessage={setErrorMessage}
                parseProfileResume={parseProfileResume}
                deleteProfileResume={deleteProfileResume}
                fileList={fileList}
                clearFileList={clearFileList}
                uploadResume={uploadResume}
                resumeData={resumeData}
                uploadProgress={uploadProgress}
              />
            </td>
          </tr>
          {showSkillsWithoutCV && (
            <tr className={tableRow}>
              <th className={tableHead}>Skills</th>
              <td>
                <CandidateSkills candidateSkills={candidateSkills} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div hidden={selectedTab !== 'structured'}>
        <>
          {parsedResumeWarnings.map((msg, idx) => (
            <Alert key={`alert-${idx}`} message={msg} type="error" />
          ))}
          {errorMessage
            ? (
              <>
              Last Updated: <RelativeDate value={resumeUpdatedAt} />
                <div className={cvError}>
                  <span>
                    <ErrorIcon />
                  </span>
                  <span>{errorMessage}</span>
                </div>
              </>
            )
            : (
              <ResumeDetails
                parsedResume={parsedResume}
                updatedAt={resumeUpdatedAt}
                CandidateSkillsRenderer={
                  candidateSkills?.length ? CandidateSkillsRenderer : null
                }
              />
            )}
        </>
      </div>
    </SectionCardContent>
  )
}

export default ResumeSection
