import React from 'react'

import { Tooltip } from '../../Tooltip'

import * as styles from './ProbableText.module.css'

const ProbableText = ({ content }) => (
  <Tooltip title={content && `Probability: ${content.Probability}`}>
    <span className={styles[content?.Probability]}>{content?.Raw}</span>
  </Tooltip>
)

export default ProbableText
