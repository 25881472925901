import React, { useMemo } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'

import { Descriptions, DescriptionsItem } from '../../Descriptions'
import { RelativeDate } from '../../RelativeDate'
import { Tag } from '../../Tag'
import { Tooltip } from '../../Tooltip'

import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

import DetailsItem from './DetailsItem'
import DetailsSubItem from './DetailsSubItem'
import ProbableText from './ProbableText'
import SkillsList from './SkillsList'
import TopSkills from './TopSkills'

const ResumeDetails = ({ parsedResume, updatedAt, CandidateSkillsRenderer }) => {
  const { data } = parsedResume || {}
  const location = data?.ContactInformation?.Location
  const positions = data?.EmploymentHistory?.Positions

  const { features } = useFeatureFlags()
  const isCandidateSkillsEnabled = features?.RELEASE_CANDIDATE_SKILLS

  const taxonomies = useMemo(
    () =>
      data?.SkillsData.reduce((prev, taxonomy) => {
        const subTaxonomies = taxonomy.SubTaxonomies.map(subTaxonomy => ({
          id: subTaxonomy.SubTaxonomyId,
          name: `${taxonomy.Name} > ${subTaxonomy.SubTaxonomyName}`,
          percentOfOverall: subTaxonomy.PercentOfOverall,
          skills: subTaxonomy.Skills
        }))
        return [...prev, ...subTaxonomies]
      }, []).sort((t1, t2) => t2.percentOfOverall - t1.percentOfOverall),
    [data]
  )

  const allSkills = useMemo(
    () =>
      taxonomies?.reduce(
        (prev, taxonomy) => [...prev, ...(taxonomy.skills || [])],
        []
      ),
    [taxonomies]
  )

  const summaryProps = useMemo(() => {
    const professionalSummary = data?.ProfessionalSummary
    const experienceSummary =
      data?.EmploymentHistory?.ExperienceSummary?.Description
    const managementStory =
      data?.EmploymentHistory?.ExperienceSummary?.ManagementStory

    const props = []
    if (professionalSummary) {
      props.push({
        title: 'Professional Summary',
        text: professionalSummary
      })
    }
    if (experienceSummary) {
      props.push({
        title: 'Experience Summary',
        text: experienceSummary
      })
    }
    if (managementStory) {
      props.push({
        title: 'Management Story',
        text: managementStory
      })
    }
    return props
  }, [data])

  const shouldDisplaySkills = !isCandidateSkillsEnabled || CandidateSkillsRenderer !== null

  return (
    <Descriptions
      bordered
      column={1}
      labelStyle={{ backgroundColor: '#1D1D1D', width: '8%' }}
      contentStyle={{ backgroundColor: '#141414' }}
    >
      <DescriptionsItem label="Last Updated">
        <RelativeDate value={updatedAt} />
      </DescriptionsItem>
      <DescriptionsItem label="Summary">
        <DetailsItem>
          {summaryProps?.map(props => (
            <DetailsSubItem key={props.title} {...props} />
          ))}
        </DetailsItem>
      </DescriptionsItem>

      <DescriptionsItem label="Location">
        {location && Object.values(location).join(' - ')}
      </DescriptionsItem>

      {shouldDisplaySkills &&
        <DescriptionsItem
          label={isCandidateSkillsEnabled
            ? <>Skills</>
            : (<>
              Top Skills
              <Tooltip
                title={
                  <>
                    <h3>CV Parser Skill Types</h3>
                    <Tag color="blue">Skill</Tag>a general skill category that the
                    candidate may have based on their CV.
                    <br />
                    <br />
                    <Tag color="cyan">Variation</Tag>a more specific skill that is
                    part of the previous Skill category and is present on the CV.
                  </>
                }
                placement="rightBottom"
              >
                &nbsp;
                <InfoCircleOutlined />
              </Tooltip>
            </>)
          }
        >
          {isCandidateSkillsEnabled
            ? CandidateSkillsRenderer && <CandidateSkillsRenderer />
            : <TopSkills taxonomies={taxonomies} />
          }
        </DescriptionsItem>
      }

      <DescriptionsItem label="Experiences">
        <DetailsItem>
          {positions?.map(position => (
            <DetailsSubItem
              key={position.Id}
              title={
                <>
                  <ProbableText content={position.Employer?.Name} /> -&nbsp;
                  <ProbableText content={position.JobTitle} />
                  <br />({position.StartDate?.Date} -{' '}
                  {position.IsCurrent ? 'current' : position.EndDate?.Date})
                </>
              }
              text={position.Description}
            >
              <SkillsList
                skills={allSkills}
                listId={position.Id}
                position={position.Id}
              />
            </DetailsSubItem>
          ))}
        </DetailsItem>
      </DescriptionsItem>
    </Descriptions>
  )
}

export default ResumeDetails
