import React, { useState } from 'react'

import { useProfile } from '../../hooks'
import { SectionCard, Pagination } from 'redesign/components'

import { ActivityLogsTimeline } from './components/ActivityLogsTimeline'

import type { PaginationProps } from 'redesign/components/Pagination'

import { useActivityLogs } from './hooks/useActivityLogs'
import { useActivityLogsCount } from './hooks/useActivityLogsCount'

const PAGE_SIZE = 13

const ActivityLogSection = () => {
  const [page, setPage] = useState(1)

  const { data: profile, isLoading: isProfileLoading } = useProfile()

  const { data: activityLogsCount, isLoading: isActivityLogsCountLoading } =
    useActivityLogsCount({ profileId: profile.id })

  const { data: activityLogs, isLoading: isActivityLogsLoading } =
    useActivityLogs({ profileId: profile.id, page, pageSize: PAGE_SIZE })

  const onChangePagination: PaginationProps['onChange'] = page => {
    setPage(page)
  }

  const isLoading =
    isProfileLoading || isActivityLogsCountLoading || isActivityLogsLoading

  return (
    <SectionCard isLoading={isLoading}>
      <ActivityLogsTimeline activityLogs={activityLogs} />

      <Pagination
        current={page}
        pageSize={PAGE_SIZE}
        total={activityLogsCount}
        onChange={onChangePagination}
      />
    </SectionCard>
  )
}

export default ActivityLogSection
