import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { fetchSuggestedProfiles } from 'services/profile'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useProfileId } from 'redesign/hooks/useProfileId'

import type { IUseSuggestedProfilesReturn } from './useSuggestedProfiles.types'

const useSuggestedProfiles = (
  // @TODO(TS migration)
  // @ts-expect-error I ddo not understand what selectedModels is since it's missing on the type used here 🤔
  selectedModels
): IUseSuggestedProfilesReturn => {
  const { profileId } = useProfileId()
  const { data: featureFlags } = useFeatureFlags()
  const isSuggestedProfilesEnabled = Boolean(featureFlags?.OPS_SUGGESTED_PROFILES)

  const {
    error,
    data: suggestedProfiles,
    isLoading,
    refetch
  } = useQuery<AxiosResponse, AxiosError>({
    queryKey: ['suggestedProfiles', profileId, selectedModels],
    retry: false,
    enabled: isSuggestedProfilesEnabled,
    queryFn: () =>
      fetchSuggestedProfiles(profileId, {
        params: {
          models: selectedModels
        }
      })
  })
  const data = useMemo(
    () => suggestedProfiles?.data || [],
    [suggestedProfiles]
  )

  if (error?.response?.status === 425) {
    setTimeout(refetch, 60000)
  }

  return {
    data,
    isLoading,
    error
  }
}
export default useSuggestedProfiles
