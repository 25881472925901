import React, { useState, useMemo } from 'react'

import { CloseOutlined } from '@ant-design/icons'

import { MasterDuplicateIndicatorFull } from '../../../../components/MainData/components/MasterDuplicateIndicator'

import { Button } from 'components/Button'
import { message } from 'components/Message'
import { Modal } from 'components/Modal'
import type { RadioChangeEvent } from 'components/RadioGroup'
import { Radio, RadioGroup } from 'components/RadioGroup'
import { Select, SelectOption } from 'components/Select'
import { Spin } from 'components/Spin'

import type { DuplicateRecord } from 'redesign/modules/Profile/hooks/useProfileDuplicates/useProfileDuplicates.types'

import { useDebounce } from 'hooks/useDebounce'

import type {
  IMarkDuplicatesModalProps,
  IProfileBlockProps,
  IColoredTextProps
} from './MarkDuplicatesModal.types'

import {
  markDuplicatesModalStyles,
  radioButtonStyles,
  radioButtonMasterStyles,
  profileBlockStyles,
  fullNameStyles,
  emailStyles,
  idStyles,
  indicatorStyles,
  closeIconStyles,
  masterColor,
  duplicateColor,
  searchBlockStyles,
  searchBlockTitleStyles,
  searchBlockInfoStyles,
  searchBoxStyles,
  inputFieldStyles,
  addButtonStyles,
  profileColoredStyles,
  buttonRowStyles,
  markDuplicatesButtonStyles
} from './MarkDuplicatesModal.module.css'

const ColoredText = ({ style, text }: IColoredTextProps) => (
  <span className={style}>{text}</span>
)

const ProfileBlock = (props: IProfileBlockProps) => {
  const {
    profile: { id, email, fullName, duplicateOf },
    isMainProfile,
    onClose
  } = props
  const isMaster = duplicateOf === null

  return (
    <Radio
      value={id}
      className={isMaster ? radioButtonMasterStyles : radioButtonStyles}
    >
      <div className={profileBlockStyles}>
        <div className={indicatorStyles}>
          <MasterDuplicateIndicatorFull isMaster={isMaster} />
        </div>
        {!isMainProfile && (
          <div
            className={closeIconStyles}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onClose()
            }}
          >
            <CloseOutlined />
          </div>
        )}
        <div className={fullNameStyles}>{fullName}</div>
        <div className={emailStyles}>
          {email}
          <span className={idStyles}>ID: {id}</span>
        </div>
      </div>
    </Radio>
  )
}

// Sort a profile array to make sure that the MASTER comes first (The rest are ordered by id, as normal)
const masterFirstSorter = (a: DuplicateRecord, b: DuplicateRecord) =>
  a.duplicateOf === null ? -1 : b.duplicateOf === null ? 1 : a.id - b.id

const MarkDuplicatesModal = (props: IMarkDuplicatesModalProps) => {
  const {
    visible,
    profile,
    originalDuplicates,
    closeModal,
    fetchResults,
    searchResults,
    markProfileDuplicates,
    markDuplicatesIsLoading,
    masterProfiles,
    masterProfilesLoading
  } = props

  const [selectedProfile, setSelectedProfile] =
    React.useState<DuplicateRecord>()

  const [profiles, setProfiles] = useState<DuplicateRecord[]>([
    ...[
      {
        id: profile.id,
        email: profile.email,
        fullName: profile.fullName,
        duplicateOf: profile.duplicateOf
      }
    ],
    ...originalDuplicates.sort(masterFirstSorter)
  ])

  const currentMaster: number | null = useMemo(
    () => profiles.filter(item => item.duplicateOf === null).at(0)?.id || null,
    [profiles]
  )

  const searchStringDebounce = useDebounce((searchString: string) => {
    if (searchString.length > 2) {
      fetchResults(searchString)
    }
  }, 1000)

  const setMasterProfile = (masterId: number) =>
    setProfiles(
      profiles.map(item => ({
        ...item,
        duplicateOf: item.id === masterId ? null : masterId
      }))
    )

  const onClickAdd = () => {
    if (!selectedProfile) {
      message.error('Please select a profile from search results.')
      return
    }

    if (profiles.filter(item => item.id === selectedProfile.id).length) {
      message.error('Selected profile is already added.')
      return
    }

    // The "already master/duplicate" check won't apply to the original duplicates so they can be added again if removed in modal
    if (
      originalDuplicates.filter(item => item.id === selectedProfile.id)
        .length === 0
    ) {
      if (selectedProfile.duplicateOf !== null) {
        message.error(
          'Selected profile is already a duplicate in another group.'
        )
        return
      }
      if (masterProfiles.includes(selectedProfile.id)) {
        message.error('Selected profile is already master of another group.')
        return
      }
    }

    setProfiles([
      ...profiles,
      ...[
        {
          ...selectedProfile,
          duplicateOf: currentMaster
        }
      ]
    ])
  }

  const onTextChange = (value: string) => {
    setSelectedProfile(undefined)
    searchStringDebounce(value)
  }

  const onSelectProfile = (value: string) => {
    setSelectedProfile(JSON.parse(value))
  }

  const onSubmit = () => {
    if (!currentMaster) {
      message.error('Please select one profile as the master profile.')
      return
    }
    markProfileDuplicates({
      oldGroup: [profile.id, ...originalDuplicates.map(item => item.id)],
      masterId: currentMaster,
      duplicates: [
        ...profiles.map(item => item.id).filter(id => id !== currentMaster)
      ],
      onSuccess: closeModal
    })
  }

  return (
    <Modal
      centered
      width="580px"
      visible={visible}
      footer={null}
      title="Mark Duplicates"
      className={markDuplicatesModalStyles}
    >
      <div>
        Add profiles and mark one as the{' '}
        <ColoredText style={masterColor} text="Master Profile" />.
        <br />
        A <ColoredText style={masterColor} text="Master Profile" /> is where any
        further recruitment activity with the Candidate should happen and is the
        primary source of information about the Candidate.
        <br />
        A <ColoredText style={duplicateColor} text="Duplicate Profile" /> is a
        duplicate record of information about the Candidate.
        <RadioGroup
          value={
            profiles.filter(item => item.duplicateOf === null).at(0)?.id || 0
          }
          onChange={(e: RadioChangeEvent) => {
            setMasterProfile(e.target.value)
          }}
        >
          {profiles.map(item => (
            <ProfileBlock
              key={item.id}
              profile={item}
              isMainProfile={item.id === profile.id}
              onClose={() =>
                setProfiles(profiles.filter(p => p.id !== item.id))
              }
            />
          ))}
        </RadioGroup>
        {profiles.length < 5 && (
          <div className={searchBlockStyles}>
            <div className={searchBlockTitleStyles}>
              Add a profile that is a duplicate or master of the current
              profile.
            </div>
            <div className={searchBlockInfoStyles}>
              You can have up to 5 profiles in the group.
            </div>
            <br />
            Search for a profile
            <div className={searchBoxStyles}>
              {masterProfilesLoading
                ? (
                  <Spin />
                )
                : (
                  <>
                    <div className={inputFieldStyles}>
                      <Select
                        showSearch
                        dropdownMatchSelectWidth={450}
                        placeholder="Search by Name or Email"
                        style={{ width: '380px' }}
                        onSelect={onSelectProfile}
                        onSearch={onTextChange}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        notFoundContent="No Results"
                      >
                        {searchResults?.length &&
                        searchResults.map(result => (
                          <SelectOption
                            key={result.id}
                            value={JSON.stringify({
                              id: result.id,
                              email: result.email,
                              fullName: result.fullName,
                              duplicateOf: result.duplicateOf
                            })}
                          >
                            <span className={profileColoredStyles}>
                              {result.id}
                            </span>{' '}
                            {result.fullName}
                            <span className={profileColoredStyles}>
                              {' | '}
                            </span>
                            {result.email}
                          </SelectOption>
                        ))}
                      </Select>
                    </div>
                    <div className={addButtonStyles}>
                      <Button type="primary" onClick={onClickAdd}>
                      Add
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
        <div className={buttonRowStyles}>
          <Button onClick={closeModal} disabled={markDuplicatesIsLoading}>
            Cancel
          </Button>
          <Button
            className={markDuplicatesButtonStyles}
            type="primary"
            onClick={onSubmit}
            loading={markDuplicatesIsLoading}
          >
            Mark Duplicates
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MarkDuplicatesModal
