import React, { useState } from 'react'

import { EditOutlined } from '@ant-design/icons'

import { InternalProjectDetailsForm } from '../InternalProjectDetailsForm'
import { SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'

import { formatEmptyText } from 'redesign/utils/stringUtils'

import type { IInternalProjectDetailsProps } from './InternalProjectDetails.types'

import { fieldStyles } from './InternalProjectDetails.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const InternalProjectDetails = ({
  profile,
  onSave
}: IInternalProjectDetailsProps) => {
  const [isEdit, setEdit] = useState(false)

  if (isEdit) {
    return (
      <InternalProjectDetailsForm
        initialValues={profile}
        closeForm={() => setEdit(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <SectionCardContent
      title="Internal Projects Details"
      actions={
        <Button
          data-testid="profileInternalProjectDetails-editButton"
          icon={<EditOutlined />}
          onClick={() => setEdit(true)}
        />
      }
    >
      <Descriptions
        bordered
        column={1}
        labelStyle={{ backgroundColor: dynamicBlack }}
        contentStyle={{ backgroundColor: sootyBlack }}
      >
        <DescriptionsItem label="Status" className={fieldStyles}>
          {formatEmptyText(profile?.benchStatus)}
        </DescriptionsItem>
        <DescriptionsItem label="Summary" className={fieldStyles}>
          {formatEmptyText(profile?.benchSummary)}
        </DescriptionsItem>
        <DescriptionsItem label="Developer's XHQ Rate" className={fieldStyles}>
          {profile?.xhqRate ? `${profile?.xhqRate} USD/h` : '-'}
        </DescriptionsItem>
      </Descriptions>
    </SectionCardContent>
  )
}

export default InternalProjectDetails
