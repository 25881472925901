import type { MouseEvent } from 'react'
import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Link } from 'gatsby'
import moment from 'moment'

import { ScreeningExpandedRow } from '../ScreeningExpandedRow'
import { ScreeningModal } from '../ScreeningModal'
import { App, SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import CavalryAvatar from 'components/CavalryAvatar'
import { RoleFitTag } from 'components/RoleFitTag'
import { Skill } from 'components/Skill'
import type { ColumnsType, ColumnType } from 'components/Table'
import { Table } from 'components/Table'
import { XTeamFitTag } from 'components/XTeamFitTag'

import { TableActions } from 'redesign/components/TableActions'

import { useModal } from 'hooks/useModal'

import { buildRoleFitTooltipText, sortByField } from 'utils/helpers'

import type Screening from 'redesign/types/Screening'
import type SkillType from 'redesign/types/Skill'
import type User from 'redesign/types/User'

import type {
  IScreeningsTableProps,
  ScreeningId
} from './ScreeningsTable.types'

import {
  assessmentValueStyles,
  expandAllButtonStyles
} from './ScreeningsTable.module.css'

const ScreeningsTable = ({
  user,
  profileId,
  screenings,
  createScreening,
  updateScreening,
  autoSaveScreening,
  deleteScreening,
  expandedRows,
  onExpandRow,
  onExpandAllRows,
  roleCandidates,
  isLoadingRoleCandidates,
  features,
  updateFxtStatus,
  isFxt,
  findAndUpdateJobApplication,
  isMutationLoading
}: IScreeningsTableProps) => {
  const { modal: { confirm } } = App.useApp()
  const { isOpen: isModalOpen, closeModal, openModal } = useModal(false)
  const [selectedScreening, setSelectedScreening] = useState(undefined)

  const isAllRowsExpanded = Boolean(expandedRows.length === screenings.length)

  const handleCloseModal = () => {
    closeModal()
    setSelectedScreening(undefined)
  }

  const onDelete = (event: MouseEvent, screeningId: ScreeningId) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this Screening?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        deleteScreening(screeningId)
      }
    })
  }

  const roleNameColumn: ColumnType<Screening> | undefined =
    features?.SCREEN_QUALIFY_ROLE_CANDIDATES
      ? {
        title: 'Role Name',
        dataIndex: 'role_candidate',
        width: 200,
        render: value =>
          value !== null && (
            <Link to={`/role?id=${value.partnerRole.id}`}>
              {value.partnerRole.name}
            </Link>
          )
      }
      : undefined

  const roleFitColumn: ColumnType<Screening> | undefined =
    features?.SCREEN_QUALIFY_ROLE_CANDIDATES
      ? {
        title: 'Role Fit',
        dataIndex: 'roleFit',
        align: 'center',
        key: 'roleFit',
        width: 150,
        render: (value, record) =>
          value !== null && (
            <RoleFitTag
              isFit={value}
              tooltipText={buildRoleFitTooltipText({
                isFit: Boolean(value),
                userName: (record.author as User)?.username,
                date: record.role_candidate?.updated_at,
                context: 'Screening'
              })}
            />
          )
      }
      : undefined

  const columns: ColumnsType<Screening> = [
    {
      title: 'Screened By',
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render: (value, { author }) => (
        <CavalryAvatar email={author?.email} profileURL={author?.profileURL} />
      )
    },
    {
      title: 'Assessment',
      dataIndex: 'body',
      key: 'body',
      render: (value, { published_at: publishedAt }) => {
        const assessmentValue = publishedAt ? value : `DRAFT - ${value}`
        return <span className={assessmentValueStyles}>{assessmentValue}</span>
      },
      width: 200,
      ellipsis: true,
      responsive: ['sm']
    },
    ...(roleNameColumn ? [roleNameColumn] : []),
    {
      title: 'X-Team Fit',
      dataIndex: 'xteamFit',
      align: 'center',
      key: 'xteamFit',
      width: 150,
      render: value => value !== null && <XTeamFitTag fit={value} />
    },
    ...(roleFitColumn ? [roleFitColumn] : []),
    {
      title: 'Screened Skills',
      dataIndex: 'skills',
      key: 'skills',
      width: 150,
      render: (value: SkillType[]) => (
        <>
          {(value || [])
            .sort(sortByField('title'))
            .slice(0, 4)
            .map(({ id, title }) => (
              <Skill key={id} variant="screening" title={title} />
            ))}
        </>
      )
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      render: value => <Tooltip title={moment(value).format('DD MMM YYYY')}>
        {moment(value).fromNow()}
      </Tooltip>,
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 160,
      render: value => <Tooltip title={moment(value).format('DD MMM YYYY')}>
        {moment(value).fromNow()}
      </Tooltip>,
      sorter: (a, b) =>
        a?.updated_at
          ? new Date(a.updated_at).valueOf()
          : 0 - (b?.updated_at ? new Date(b.updated_at).valueOf() : 0),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Actions',
      align: 'center',
      width: 200,
      render: record => (
        <TableActions
          tableName={'screenings'}
          user={user}
          record={record}
          onClickEdit={event => {
            event.stopPropagation()
            setSelectedScreening(record)
            openModal()
          }}
          onDelete={event => onDelete(event, record.id)}
          canUpdate={Boolean(user.role.capabilities.canUpdateAnyScreening)}
        />
      )
    }
  ]

  return (
    <>
      <SectionCardContent
        title="Screenings"
        actions={
          <div>
            <Button onClick={onExpandAllRows} className={expandAllButtonStyles}>
              {isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
            <Button type="primary" onClick={openModal}>
              Create
            </Button>
          </div>
        }
      >
        <Table
          rowKey="id"
          expandRowByClick
          columns={columns}
          dataSource={screenings}
          pagination={false}
          scroll={{ x: true }}
          onExpand={onExpandRow}
          expandedRowKeys={expandedRows}
          expandable={{
            expandedRowRender: data => <ScreeningExpandedRow screening={data} />
          }}
        />
      </SectionCardContent>

      {isModalOpen && (
        <ScreeningModal
          visible={isModalOpen}
          profileId={profileId}
          screening={selectedScreening}
          closeForm={handleCloseModal}
          createScreening={createScreening}
          updateScreening={updateScreening}
          autoSaveScreening={autoSaveScreening}
          deleteScreening={deleteScreening}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          features={features}
          updateFxtStatus={updateFxtStatus}
          isFxt={isFxt}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={isMutationLoading}
        />
      )}
    </>
  )
}

export default ScreeningsTable
