import React from 'react'

import TechEval from '../../../../../../../components/Profile/TechEvals/TechEval'

// Types
import type { ITechEvaluationExpandedRowProps } from './TechEvaluationExpandedRow.types'

const TechEvaluationExpandedRow = ({
  techEvaluation
}: ITechEvaluationExpandedRowProps) =>
  <TechEval values={techEvaluation} />

export default TechEvaluationExpandedRow
