import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listTestAssessmentResults } from 'services/testAssessmentResults'

import type {
  UseTestAssessmentResultsReturn,
  UseTestAssessmentResultsParams
} from './useTestAssessmentsResults.types'

const useTestAssessmentResults = ({
  internalTestAssessmentId,
  internalGeneralAssessmentId,
  profileId,
  enabled = true
}: UseTestAssessmentResultsParams): UseTestAssessmentResultsReturn => {
  const { data: testAssessmentResultsData, isLoading } = useQuery({
    queryKey: ['testAssessmentsResults', profileId, internalTestAssessmentId],
    queryFn: () =>
      listTestAssessmentResults(internalTestAssessmentId, internalGeneralAssessmentId, {
        params: { profileId, _limit: 999 }
      }),
    enabled: enabled && Boolean(profileId),
    refetchOnWindowFocus: false
  })

  const data = useMemo(
    () => testAssessmentResultsData?.data ?? [],
    [testAssessmentResultsData]
  )

  return {
    data,
    isLoading
  }
}

export default useTestAssessmentResults
