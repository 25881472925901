import React, { useState } from 'react'

import { useProfileNotes } from '../../hooks'
import { SectionCard } from 'redesign/components'

import { useJobApplicationsReviewers } from 'redesign/hooks/useJobApplicationsReviewers'
import { useProfileId } from 'redesign/hooks/useProfileId'
import { useUser } from 'redesign/hooks/useUser'

import type Note from 'redesign/types/Note'

import { NotesTable } from './components'

const NotesSection = () => {
  const { data: user } = useUser()
  const { profileId } = useProfileId()
  const {
    data: notes,
    isLoading: notesIsLoading,
    createNote,
    updateNote,
    deleteNote
  } = useProfileNotes()

  const {
    data: jobApplicationReviewers,
    isLoading: jobApplicationReviewersIsLoading
  } = useJobApplicationsReviewers()

  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const onExpandRow = (expanded: boolean, row: Note) => {
    if (expanded) {
      setExpandedRows(state => [...state, row.id])
    } else {
      setExpandedRows(state => state.filter(id => id !== row.id))
    }
  }

  const onExpandAllRows = () => {
    const rowKeys = notes.map(note => note.id)
    if (expandedRows.length === rowKeys.length) {
      setExpandedRows([])
    } else {
      setExpandedRows(rowKeys)
    }
  }

  const isLoading = Boolean(notesIsLoading || jobApplicationReviewersIsLoading)

  return (
    <SectionCard isLoading={isLoading}>
      <NotesTable
        user={user}
        profileId={profileId}
        notes={notes}
        expandedRows={expandedRows}
        onExpandRow={onExpandRow}
        onExpandAllRows={onExpandAllRows}
        jobApplicationReviewers={jobApplicationReviewers}
        createNote={createNote}
        updateNote={updateNote}
        deleteNote={deleteNote}
      />
    </SectionCard>
  )
}

export default NotesSection
