import React, { useEffect, useState } from 'react'

import { useProfileResume } from 'redesign/modules/Profile/hooks/useProfileResume'

import { readProfileResume } from 'services/profile'

import { useResume } from 'redesign/hooks/useResume'

import type ParsedResume from 'redesign/types/ParsedResume'

import { ResumeSection } from './components'
import type { ProfileResumeSectionProps } from './ProfileResumeSection.types'

const ProfileResumeSection = ({
  profileId,
  resumeFileMeta,
  candidateSkills,
  features
}: ProfileResumeSectionProps) => {
  const [selectedTab, setSelectedTab] = useState('file')
  const [parsedResume, setParsedResume] = useState<ParsedResume | null>(null)
  const [parsedResumeWarnings, setParsedResumeWarnings] = useState<string[]>([])
  const [isLoadingStructured, setIsLoadingStructured] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {
    fileList,
    clearFileList,
    uploadResume,
    resumeData,
    uploadProgress,
    isLoading: resumeIsLoading
  } = useResume(resumeFileMeta)

  const { parseProfileResume, deleteProfileResume } = useProfileResume()

  useEffect(() => {
    const loadResume = async () => {
      setIsLoadingStructured(true)
      try {
        const { data } = await readProfileResume(profileId)

        if (data) {
          setParsedResumeAndWarnings(data)
          setSelectedTab('structured')
          setErrorMessage(null)
        }
        setIsLoadingStructured(false)
      } catch (e) {
        setErrorMessage(
          'Oops We couldn\'t scan the information in your file. Make sure to upload a file with the following format .pdf, doc, .docx, .odt.'
        )
        setIsLoadingStructured(false)
      }
    }

    const isDefinedResume = resumeFileMeta && Object.keys(resumeFileMeta).length > 0

    if (isDefinedResume) {
      loadResume()
    } else {
      setIsLoadingStructured(false)
      setParsedResume(null)
    }
  }, [profileId, resumeFileMeta])

  const setParsedResumeAndWarnings = (data: ParsedResume) => {
    setParsedResume(data)
    const {
      data: { DocumentLanguage: documentLanguage }
    } = data
    if (documentLanguage !== 'en') {
      // These are the most common. For the others the initials will be shown
      // in the message
      const foreignLanguages = {
        es: 'Spanish',
        pt: 'Portuguese'
      }
      const language = foreignLanguages[documentLanguage as keyof typeof foreignLanguages] || documentLanguage
      const message = `The uploaded CV is in a foreign language: ${language}`
      setParsedResumeWarnings([message])
    }
  }

  const isLoading = Boolean(isLoadingStructured || resumeIsLoading)

  return (
    <ResumeSection
      resumeFileMeta={resumeFileMeta}
      parseProfileResume={parseProfileResume}
      deleteProfileResume={deleteProfileResume}
      fileList={fileList}
      clearFileList={clearFileList}
      uploadResume={uploadResume}
      resumeData={resumeData}
      candidateSkills={candidateSkills}
      uploadProgress={uploadProgress}
      isLoading={isLoading}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      parsedResumeWarnings={parsedResumeWarnings}
      parsedResume={parsedResume}
      features={features}
    />
  )
}

export default ProfileResumeSection
