import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useForm } from '../../../../../../../hooks/useForm'
import { App } from 'redesign/components'

import { NoteTagsField } from 'redesign/containers/FormFields/NoteTagsField'

import { Button } from 'components/Button'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'

import { formatNegativeValues } from 'redesign/utils/helpers'

import type NoteTag from 'redesign/types/NoteTag'

import type { INotesFormProps } from './NotesForm.types'

import { formStyles, saveButtonStyles } from './NotesForm.module.css'

const NotesForm = ({
  profileId,
  closeForm,
  initialValues,
  createNote,
  updateNote
}: INotesFormProps) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickSave = async () => {
    try {
      // Throw exception if any required field is empty
      await form.validateFields()
    } catch (e) {
      return
    }

    setIsSubmitting(true)

    const values = { profile: profileId, ...form.getFieldsValue() }
    if (initialValues) {
      const tags = formatNegativeValues<NoteTag>(values.tags, initialValues.tags)
      updateNote?.(
        { noteId: initialValues.id, values: { ...values, tags } },
        {
          onSuccess: () => {
            setIsSubmitting(false)
            closeForm()
          }
        }
      )
    } else {
      createNote?.(values, {
        onSuccess: () => {
          setIsSubmitting(false)
          closeForm()
        }
      })
    }
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  const formattedInitialValues = {
    ...initialValues,
    tags: initialValues?.tags?.map(tag => tag.id)
  }

  return (
    <Form
      data-testid="profileNotesForm-form"
      form={form}
      layout="vertical"
      initialValues={formattedInitialValues}
      className={formStyles}
    >
      <FormItem name="body" label="Body" rules={[{ required: true }]}>
        <Input.TextArea data-testid="profileNotesForm-bodyField" autoSize />
      </FormItem>

      <FormItem name="tags" label="Tags">
        <NoteTagsField />
      </FormItem>

      <Button type="primary" onClick={onClickSave} className={saveButtonStyles} loading={isSubmitting} disabled={isSubmitting}>
        Save
      </Button>
      <Button onClick={onClickCancel}>Cancel</Button>
    </Form>
  )
}

export default NotesForm
