import React from 'react'

import { Skill } from 'components/Skill'
import { Tooltip } from 'components/Tooltip'

import { skillTagSorter } from 'redesign/utils/skillUtils/skillUtils'

import type { SkillTag } from 'redesign/types/SkillTag'

import type { TagsListProps } from './TagsList.types'

import {
  containerStyles,
  descriptionStyles,
  skillTagStyles,
  tagListStyles
} from './TagsList.module.css'

const TagsList = ({ description, skills }: TagsListProps) =>
  <div className={containerStyles}>
    <div className={descriptionStyles} data-testid="skill-experience-amount">
      {description}
    </div>
    <div className={tagListStyles}>
      {skills.sort(skillTagSorter).map((skill: SkillTag, index) => (
        <Tooltip
          key={index}
          className={skillTagStyles}
          placement="top"
          title={skill.tooltip}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <span>
            <Skill title={skill.title} variant={skill.variant} />
          </span>
        </Tooltip>
      ))}
    </div>
  </div>

export default TagsList
