import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listXHQLinks = async params => {
  try {
    const response = await api.get(`/xhqlinks`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
