import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const getActivityLogs = async params => {
  try {
    const response = await api.get(`/activity-logs`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const getActivityLogsCount = async params => {
  try {
    const response = await api.get(`/activity-logs/count`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
