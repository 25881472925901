import { useMutation } from '@tanstack/react-query'

import { message } from 'components/Message'

import { createRunwayNotification as createRunwayNotificationService } from 'services/runways'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { CreateSlackNotificationValue, IUseProfileSlackNotificationReturn } from './useProfileSlackNotification.types'

const useProfileSlackNotification = (): IUseProfileSlackNotificationReturn => {
  const { profileId } = useProfileId()

  // Post
  const createSlackNotificationMutation = useMutation<
    Record<string, string>,
    unknown,
    CreateSlackNotificationValue
  >({
    mutationFn: values => createRunwayNotificationService(profileId, values),
    onSuccess: () => {
      message.success('Slack notification sent')
    },
    onError: () => {
      message.error('Slack notification failed')
    }
  })

  const createSlackNotification = (values: CreateSlackNotificationValue) => {
    createSlackNotificationMutation.mutate(values)
  }

  return {
    createSlackNotification
  }
}
export default useProfileSlackNotification
