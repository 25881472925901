import React, { useState } from 'react'

import type { Key } from 'antd/es/table/interface'
import type { ColumnsType } from 'antd/lib/table'
import moment from 'moment'

import { ExpandedRow } from '../ExpandedRow'
import { NotesForm } from '../NotesForm'
import { SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import CavalryAvatar from 'components/CavalryAvatar'
import { Table } from 'components/Table'
import { Tag } from 'components/Tag'

import { sortByField } from 'utils/helpers'

import type Note from 'redesign/types/Note'
import type NoteTag from 'redesign/types/NoteTag'
import type Profile from 'redesign/types/Profile'

import type { INotesTableProps } from './NotesTable.types'

import { expandAllButtonStyles } from './NotesTable.module.css'

// @TODO(TS migration): extract to a separate component.
const AuthorAvatar = ({
  authorId,
  jobApplicationReviewers
}: {
  authorId: number
  jobApplicationReviewers: Profile[]
}) => {
  const author = jobApplicationReviewers.find(
    (reviewer: Profile) => reviewer.id === authorId
  )
  return <CavalryAvatar email={author?.email} profileURL={author?.profileURL} />
}

const NotesTable = ({
  user,
  profileId,
  notes,
  expandedRows,
  onExpandRow,
  onExpandAllRows,
  jobApplicationReviewers,
  createNote,
  updateNote,
  deleteNote
}: INotesTableProps) => {
  const [isCreate, setCreate] = useState(false)

  const avaliableTags = notes
    .reduce((acc: {text: string, value: number}[], note) => {
      note.tags.forEach(tag => {
        const tagFilterItem = { text: tag.slug, value: tag.id }
        if (!acc.includes(tagFilterItem)) {
          acc.push(tagFilterItem)
        }
      })
      return acc
    }, [])

  const isAllRowsExpanded = Boolean(expandedRows.length === notes.length)

  const onClickCreate = () => {
    setCreate(true)
  }

  const closeForm = () => {
    setCreate(false)
  }

  const columns: ColumnsType<Note> = [
    {
      title: 'Author',
      key: 'author',
      dataIndex: 'author',
      width: 160,
      render: (authorId: number) => (
        <AuthorAvatar
          authorId={authorId}
          jobApplicationReviewers={jobApplicationReviewers}
        />
      )
    },
    {
      title: 'Body',
      key: 'body',
      dataIndex: 'body',
      width: 160,
      render: value => value && value.substr(0, 100),
      ellipsis: true
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 160,
      render: (tags: NoteTag[]) => {
        if (!tags) {
          return null
        }

        return tags.sort(sortByField('slug')).map(({ id, slug }) => (
          <Tag key={id} color="blue">
            {slug}
          </Tag>
        ))
      },
      filters: avaliableTags,
      filterMode: 'tree',
      onFilter: (value: boolean | Key, record) =>
        record.tags.some(tag => tag.id === value)
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      render: value => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 160,
      render: value => moment(value).fromNow()
    }
  ]

  return (
    <SectionCardContent
      title="Notes"
      actions={
        <>
          {!isCreate && (
            <div>
              <Button
                onClick={onExpandAllRows}
                className={expandAllButtonStyles}
              >
                {isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
              </Button>
              <Button type="primary" onClick={onClickCreate}>
                Create
              </Button>
            </div>
          )}
        </>
      }
    >
      {isCreate && (
        <NotesForm
          profileId={profileId}
          closeForm={closeForm}
          createNote={createNote}
        />
      )}
      <Table
        rowKey="id"
        expandRowByClick
        columns={columns}
        dataSource={notes}
        pagination={false}
        scroll={{ x: true }}
        expandedRowKeys={expandedRows}
        onExpand={onExpandRow}
        expandable={{
          expandedRowRender: note => (
            <ExpandedRow
              user={user}
              note={note}
              profileId={profileId}
              updateNote={updateNote}
              deleteNote={deleteNote}
            />
          )
        }}
      />
    </SectionCardContent>
  )
}

export default NotesTable
