import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useForm } from '../../../../../../../hooks/useForm'
import { SectionCardContent, App } from 'redesign/components'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'

import type { IMarketingFormProps } from './MarketingForm.types'

import { fieldStyles, saveButtonStyle } from './MarketingForm.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const MarketingForm = ({
  initialValues,
  closeForm,
  onSave
}: IMarketingFormProps) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()

  const onClickSave = () => {
    const values = form.getFieldsValue()
    onSave(values, {
      onSuccess: closeForm
    })
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  return (
    <SectionCardContent
      title="Marketing"
      actions={
        <div>
          <Button
            type="primary"
            onClick={onClickSave}
            className={saveButtonStyle}
          >
            Save
          </Button>
          <Button onClick={onClickCancel}>Cancel</Button>
        </div>
      }
    >
      <Form
        data-testid="profileMarketingForm-form"
        form={form}
        initialValues={initialValues}
      >
        <Descriptions
          bordered
          column={{ xs: 1, sm: 1, md: 2, xxl: 2, xl: 2, lg: 2 }}
          labelStyle={{ backgroundColor: dynamicBlack }}
          contentStyle={{ backgroundColor: sootyBlack }}
        >
          <DescriptionsItem label="Source" className={fieldStyles}>
            <FormItem name="utmSource" noStyle>
              <Input />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem label="Medium" className={fieldStyles}>
            <FormItem name="utmMedium" noStyle>
              <Input />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem label="Campaign" className={fieldStyles}>
            <FormItem name="utmCampaign" noStyle>
              <Input />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem label="Content" className={fieldStyles}>
            <FormItem name="utmContent" noStyle>
              <Input />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem label="Term">
            <FormItem name="utmTerm" noStyle>
              <Input />
            </FormItem>
          </DescriptionsItem>
        </Descriptions>
      </Form>
    </SectionCardContent>
  )
}

export default MarketingForm
