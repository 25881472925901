import React from 'react'

import { SectionCard } from '../../../../components'
import { useProfileJobApplications, useProfileRoles } from '../../hooks'

import { RoleCandidateActionsButtonContainer } from 'redesign/containers/RoleCandidateActionsButtonContainer'
import type { HandleRoleCandidateAssigneeChangeType } from 'redesign/containers/RoleCandidatesTableContainer/RoleCandidatesTableContainer.types'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useOpenXProfile } from 'redesign/hooks/useOpenXProfile'
import { useRoleCandidates } from 'redesign/hooks/useRoleCandidates'
import { useUser } from 'redesign/hooks/useUser'

import { JobApplicationsTable, RolesTable } from './components'

const OpportunitiesSection = () => {
  const { data: featureFlags } = useFeatureFlags()
  const enableXProfileColumn = Boolean(
    featureFlags?.RELEASE_CONTACTED_XPROFILE_BIZDEV
  )
  const shouldDisplayRoleAssignees = Boolean(
    featureFlags?.ROLE_AND_ROLE_CANDIDATE_ASSIGNEES
  )

  const { data: jobApplications, isLoading: jobApplicationsIsLoading } =
    useProfileJobApplications()

  const { data: authData } = useUser()

  const { data: roles, isLoading: rolesIsLoading } = useProfileRoles()

  const { addRoleAssigneesMutation, removeRoleAssigneesMutation } =
    useRoleCandidates()

  const { openXProfile } = useOpenXProfile()

  const isLoading = Boolean(jobApplicationsIsLoading || rolesIsLoading)

  const handleAssigneeChange =
    (roleCandidateId: number) =>
      ({ userId, isAssigned }: HandleRoleCandidateAssigneeChangeType) => {
        const functionToCall = isAssigned
          ? removeRoleAssigneesMutation
          : addRoleAssigneesMutation
        functionToCall({ roleCandidateId, assignees: [userId] })
      }

  return (
    <>
      <SectionCard isLoading={isLoading}>
        <RolesTable
          roles={roles}
          openXProfile={openXProfile}
          showXProfileColumn={enableXProfileColumn}
          authData={authData}
          shouldDisplayRoleAssignees={shouldDisplayRoleAssignees}
          handleAssigneeChange={handleAssigneeChange}
          ActionsComponent={roleCandidate => (
            <RoleCandidateActionsButtonContainer
              roleCandidate={roleCandidate}
            />
          )}
        />
      </SectionCard>
      <SectionCard isLoading={isLoading}>
        <JobApplicationsTable jobApplications={jobApplications} />
      </SectionCard>
    </>
  )
}

export default OpportunitiesSection
