import { handleThrowError, normalizeConfig } from '../utils/services'

import { request as api, getCacheBuster } from './strapi/api'

export const listTestAssessmentResults = async (
  internalTestAssessmentId,
  internalGeneralAssessmentId,
  config = {}
) => {
  try {
    normalizeConfig(config)
    config.params._cb = getCacheBuster('testAssessmentResults')

    const response = await api.get(
      `/tests-details/${internalGeneralAssessmentId}/${internalTestAssessmentId}`,
      config
    )
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
