import React, { useState } from 'react'

import moment from 'moment'

import { ExpandedRow } from '../ExpandedRow'
import { ExperiencesForm } from '../ExperiencesForm'
import { SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'
import { Tag } from 'components/Tag'

import { sortByField } from 'utils/helpers'

import type Experience from 'redesign/types/Experience'

import type { IExperiencesTableProps, ITagsProps } from './ExperiencesTable.types'

const Tags: React.FC<ITagsProps> = ({ skills }) => (
  <>
    {skills?.sort(sortByField('slug')).map(({ id, title }) => (
      <Tag key={id} color="blue">
        {title}
      </Tag>
    ))}
  </>
)

const ExperiencesTable: React.FC<IExperiencesTableProps> = ({
  profileId,
  experiences,
  industries,
  createExperience,
  updateExperience,
  deleteExperience
}) => {
  const [isCreate, setCreate] = useState(false)

  const onClickCreate = () => {
    setCreate(true)
  }

  const columns: ColumnsType<Experience> = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: value => value
    },

    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: value => value
    },

    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: value =>
        industries.find(industry => industry.id === value)?.description
    },

    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      render: skills => <Tags skills={skills} />
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: value => value && moment(value).format('YYYY-MM'),
      sorter: (a, b) => new Date(a.start_date as Date).valueOf() - new Date(b.start_date as Date).valueOf(),
      defaultSortOrder: 'descend'
    },

    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: value => value && moment(value).format('YYYY-MM'),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: value => value && moment(value).fromNow(),
      sorter: (a, b) =>
        new Date(a.updated_at as Date).valueOf() - new Date(b.updated_at as Date).valueOf(),
      defaultSortOrder: 'descend'
    }
  ]

  return (
    <SectionCardContent
      title="Experiences"
      actions={
        <>
          {!isCreate && (
            <Button type="primary" onClick={onClickCreate}>
              Create
            </Button>
          )}
        </>
      }
    >
      {isCreate && (
        <ExperiencesForm
          profileId={profileId}
          closeForm={() => setCreate(false)}
          industries={industries}
          createExperience={createExperience}
        />
      )}
      <Table
        rowKey="id"
        expandRowByClick
        dataSource={experiences}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: experience => (
            <ExpandedRow
              profileId={profileId}
              experience={experience}
              industries={industries}
              deleteExperience={deleteExperience}
              updateExperience={updateExperience}
            />
          )
        }}
      />
    </SectionCardContent>
  )
}

export default ExperiencesTable
