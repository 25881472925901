import React, { useState } from 'react'

import { FilePdfOutlined, LinkOutlined } from '@ant-design/icons'
import moment from 'moment'

import { SectionCardContent } from 'redesign/components'

import { ExpandedRow } from './components/ExpandedRow'

import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useTestAssessmentResults } from 'redesign/hooks/useTestAssessmentResults'

import type TestAssessment from 'redesign/types/TestAssessment'

import type { TestAssessmentsTableProps } from './TestAssessmentsTable.types'

import {
  anchorLinkToResultStyles,
  anchorLinkToPDFStyles,
  expandedRowStyles
} from './TestAssessmentsTable.module.css'

const TestAssessmentsTable = ({
  testAssessments,
  profileId
}: TestAssessmentsTableProps) => {
  const { data: featureFlags } = useFeatureFlags()
  const isTestAssessmentEnabled = Boolean(featureFlags?.RELEASE_TEST_GORILLA)
  const [lastExpandedInternalAssessmentId, setLastExpandedInternalAssessmentId] = useState<
    number | undefined
  >()
  const [
    lastExpandedInternalGeneralAssessmentId,
    setLastExpandedInternalGeneralAssessmentId
  ] = useState<number | undefined>()

  const { data: testResults, isLoading: isTestResultsLoading } =
    useTestAssessmentResults({
      enabled: isTestAssessmentEnabled && Boolean(lastExpandedInternalAssessmentId),
      profileId,
      internalTestAssessmentId: lastExpandedInternalAssessmentId,
      internalGeneralAssessmentId: lastExpandedInternalGeneralAssessmentId
    })

  const columns: ColumnsType<TestAssessment> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score'
    },
    {
      title: 'Assessment PDF',
      dataIndex: 'pdfUrl',
      key: 'pdfUrl',
      render: value => (
        <a
          href={value}
          className={anchorLinkToPDFStyles}
          target="_blank"
          rel="noopener noreferrer"
          title="View PDF"
        >
          <FilePdfOutlined color="red" />
          View
        </a>
      )
    },

    {
      title: 'Link to Result',
      dataIndex: 'assessmentUrl',
      key: 'assessmentUrl',
      render: value => (
        <a
          href={value}
          className={anchorLinkToResultStyles}
          target="_blank"
          rel="noopener noreferrer"
          title="View Link To Result"
        >
          <LinkOutlined />
          TestGorilla
        </a>
      )
    },

    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: value => value && moment(value).format('YYYY-MM-DD'),
      sorter: (a, b) =>
        new Date(a.createdAt as Date).valueOf() -
        new Date(b.createdAt as Date).valueOf(),
      defaultSortOrder: 'descend'
    },

    {
      title: 'Assessment Date',
      dataIndex: 'assessmentDate',
      width: 180,
      key: 'assessmentDate',
      render: value => moment(value).format('YYYY-MM-DD'),
      sorter: (a, b) =>
        new Date(a.assessmentDate).valueOf() -
        new Date(b.assessmentDate).valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 180,
      render: value => value && moment(value).fromNow(),
      sorter: (a, b) =>
        new Date(a.updatedAt as Date).valueOf() -
        new Date(b.updatedAt as Date).valueOf(),
      defaultSortOrder: 'descend'
    }
  ]

  return (
    <SectionCardContent title="Gorilla Tests" actions>
      <Table
        rowKey="id"
        dataSource={testAssessments}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        locale={{
          emptyText: 'No completed TestGorilla assessments found for this user'
        }}
        expandable={{
          expandedRowClassName: () => expandedRowStyles,
          expandedRowRender: (record: TestAssessment) => (
            <ExpandedRow
              isLoading={
                isTestResultsLoading &&
                record.internalTestAssessmentId === lastExpandedInternalAssessmentId
              }
              testResults={testResults}
            />
          ),
          rowExpandable: (record: TestAssessment) =>
            record.containsDecisiveTests,
          onExpand: (expanded: boolean, record: TestAssessment) => {
            if (expanded === true) {
              setLastExpandedInternalAssessmentId(record.internalTestAssessmentId)
              setLastExpandedInternalGeneralAssessmentId(record.internalGeneralAssessmentId)
            }
          }
        }}
      />
    </SectionCardContent>
  )
}

export default TestAssessmentsTable
