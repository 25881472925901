import React, { useEffect, useState } from 'react'

// TODO: Migrate component to TypeScript
import { ProfileNameColumn } from 'components/ProfileNameColumn'
import StatusTag from 'components/ProfileStatusTag/StatusTag'

import { RecommendationTable } from 'redesign/components/RecommendationTable'

import { ORIGINS } from 'utils/role-candidates-helpers'

import type Profile from 'redesign/types/Profile'
import type ProfileStatus from 'redesign/types/ProfileStatus'

import type { ISimilarProfilesTableProps } from './SimilarProfilesTable.types'

const SimilarProfilesTable: React.FC<ISimilarProfilesTableProps> = ({ data, isLoading, error, setSelectedModels, selectedModels, title = 'Similar Profiles' }) => {
  const [models, setModels] = useState<string[]>([])

  const type = ORIGINS.CANDIDATE_SUGGESTION.type

  useEffect(() => {
    if (!models.length) {
      setModels(Object.keys(data[0]?.criteria || {}))
    }
  }, [data, models.length])

  const columns = [
    {
      title: 'Name/Email',
      render: ({ profile }: { profile: Profile }) => <ProfileNameColumn profile={profile} filteredValue={undefined} />
    },
    {
      title: 'Status',
      // @TODO(TS migration)
      // @ts-expect-error I'm not aware of the profile having the 'status' field 🤔
      // Is it missing on the Profile type or is it a type I'm missing?
      render: ({ profile }: ProfileStatus) => <StatusTag status={profile.status} activeRunway={undefined} />
    }
  ]

  return (
    <RecommendationTable
      models={models}
      error={error}
      data={data}
      isLoading={isLoading}
      selectedModels={selectedModels}
      setSelectedModels={setSelectedModels}
      columns={columns}
      title={title}
      type={type}
    />
  )
}

export default SimilarProfilesTable
