import React from 'react'

import { RootComponent } from 'redesign/app/RootComponent'
import { ProfileIdProvider } from 'redesign/providers/ProfileIdProvider'

import SEO from 'components/SEO'

import { ProfilePage as RedesignProfilePage } from 'redesign/modules/Profile'

const ProfilePage = () => (
  <>
    <SEO title="Profiles" />
    <RootComponent>
      <ProfileIdProvider>
        <RedesignProfilePage />
      </ProfileIdProvider>
    </RootComponent>
  </>
)

export default ProfilePage
