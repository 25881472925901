// extracted by mini-css-extract-plugin
export var blackSuede = "#434343";
export var borderWrapperStyles = "MainData-module--borderWrapperStyles--39e2a";
export var breakpointMD = "1099px";
export var breakpointSM = "680px";
export var breakpoints = "'theme/breakpoints.module.css'";
export var colors = "'theme/colors.module.css'";
export var emailStyles = "MainData-module--emailStyles--52db5";
export var gainsBoro = "#DBDBDB";
export var leftBorderStyles = "MainData-module--leftBorderStyles--d5523";
export var profileMetaStyles = "MainData-module--profileMetaStyles--b4ac8";
export var profileNameStyles = "MainData-module--profileNameStyles--f46a9";
export var responsiveSectionStyles = "MainData-module--responsiveSectionStyles--2b3fa";
export var roleCandidateActionsStyles = "MainData-module--roleCandidateActionsStyles--588bc";
export var sectionStyles = "MainData-module--sectionStyles--62714";
export var shortlistedStyles = "MainData-module--shortlistedStyles--75706";
export var votingControlsStyles = "MainData-module--votingControlsStyles--fa890";