import { useMemo } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { message } from 'components/Message'

import {
  create as createScreeningService,
  deleteScreening as deleteScreeningService,
  listDraftScreenings,
  listScreenings,
  update as updateScreeningService
} from 'services/screenings'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Screening from 'redesign/types/Screening'

import type {
  IUseProfileScreeningsReturn,
  UpdateScreeningValueProps
} from './useProfileScreenings.types'

const useProfileScreenings = (): IUseProfileScreeningsReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  const invalidateRelatedQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['screenings'] })
    queryClient.invalidateQueries({ queryKey: ['draftScreenings'] })
    queryClient.invalidateQueries({ queryKey: ['candidateSkills'] })
    queryClient.invalidateQueries({
      queryKey: ['role-candidates', { profile: profileId }]
    })
    queryClient.invalidateQueries({ queryKey: ['activityLog'] })
    queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
  }

  // Get
  const { data: screeningsData, isLoading: screeningsIsLoading } = useQuery({
    queryKey: ['screenings', profileId],
    queryFn: () =>
      listScreenings({
        params: {
          profile: profileId,
          populate: [
            'skills',
            'author',
            'role_candidate',
            'role_candidate.assignee',
            'role_candidate.partnerRole',
            'role_candidate.partnerRole.partner',
            'role_candidate.partnerRole.roleSkills',
            'role_candidate.partnerRole.roleSkills.skill'
          ]
        }
      })
  })
  const screenings = useMemo(() => screeningsData?.data || [], [screeningsData])
  const screeningsCount = useMemo(
    () => screeningsData?.data.length || 0,
    [screeningsData]
  )

  const { data: draftScreeningsData, isLoading: draftScreeningsIsLoading } =
    useQuery({
      queryKey: ['draftScreenings', profileId],
      queryFn: () =>
        listDraftScreenings({
          params: {
            profile: profileId,
            populate: [
              'skills',
              'author',
              'role_candidate',
              'role_candidate.assignee',
              'role_candidate.partnerRole',
              'role_candidate.partnerRole.partner',
              'role_candidate.partnerRole.roleSkills',
              'role_candidate.partnerRole.roleSkills.skill'
            ]
          }
        })
    })
  const draftScreenings = useMemo(
    () => draftScreeningsData?.data || [],
    [draftScreeningsData]
  )
  const draftScreeningsCount = useMemo(
    () => draftScreeningsData?.data.length || 0,
    [draftScreeningsData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createScreeningService,
    onSuccess: () => {
      message.success('Screening created')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Create Note failed')
    }
  })

  const createScreening = (values: Screening, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    Record<string, string>,
    unknown,
    UpdateScreeningValueProps
  >({
    mutationFn: ({ screeningId, values }) =>
      updateScreeningService(screeningId, values),
    onSuccess: () => {
      message.success('Screening updated')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Update Screening failed')
    }
  })

  const updateScreening = (
    values: UpdateScreeningValueProps,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteScreeningService,
    onSuccess: () => {
      message.success('Screening deleted')
      invalidateRelatedQueries()
    },
    onError: () => {
      message.error('Delete Screening failed')
    }
  })

  const deleteScreening = (screeningId: number) => {
    deleteMutation.mutate(screeningId)
  }

  return {
    data: [...screenings, ...draftScreenings],
    dataCount: screeningsCount + draftScreeningsCount,
    isLoading: Boolean(screeningsIsLoading || draftScreeningsIsLoading),
    isMutationLoading: Boolean(
      createMutation.isLoading ||
        updateMutation.isLoading ||
        deleteMutation.isLoading
    ),
    createScreening,
    updateScreening,
    deleteScreening
  }
}
export default useProfileScreenings
