import React from 'react'

import { formatEmptyText } from 'redesign/utils/stringUtils'

import type { ISummaryDataProps } from './SummaryData.type'

import {
  summaryBlockStyles,
  summaryByCandidateContentStyles,
  summaryByCandidateTitleStyles,
  summaryByCavalryContentStyles,
  summaryByCavalryTitleStyles,
  summaryStyles,
  leftBorderStyles,
  borderWrapperStyles
} from './SummaryData.module.css'

const SummaryData = ({
  summaryByCandidate,
  summaryByCavalry
}: ISummaryDataProps) => (
  <div className={borderWrapperStyles}>
    <div className={leftBorderStyles} />
    <div className={summaryStyles}>
      <div className={summaryBlockStyles}>
        <span className={summaryByCandidateTitleStyles}>
          Summary (by Candidate)
        </span>
        <p className={summaryByCandidateContentStyles}>
          {formatEmptyText(summaryByCandidate)}
        </p>
      </div>

      <div className={summaryBlockStyles}>
        <span className={summaryByCavalryTitleStyles}>
          Summary (by Cavalry)
        </span>
        <p className={summaryByCavalryContentStyles}>
          {formatEmptyText(summaryByCavalry)}
        </p>
      </div>
    </div>
  </div>
)

export default SummaryData
