// extracted by mini-css-extract-plugin
export var acceptedStatusStyles = "QuickActionPanel-module--acceptedStatusStyles--78ade";
export var blue = "#6BBCFF";
export var bookedStatusDatesDividerStyles = "QuickActionPanel-module--bookedStatusDatesDividerStyles--9e8a0";
export var bookedStatusTextStyles = "QuickActionPanel-module--bookedStatusTextStyles--7b124";
export var brightNavyBlue = "#177DDC";
export var c64ntscBlue = "#5A7AFF";
export var card = "QuickActionPanel-module--card--ff4c1";
export var clientInterviewedStatusStyles = "QuickActionPanel-module--clientInterviewedStatusStyles--1abd6";
export var colors = "'theme/colors.module.css'";
export var contactedStatusStyles = "QuickActionPanel-module--contactedStatusStyles--9f152";
export var flaxFlowerBlue = "#3C9AE8";
export var iridescentGreen = "#40BF6A";
export var parkWalkGreen = "#8BBB11";
export var presentedToBizDevStatusStyles = "QuickActionPanel-module--presentedToBizDevStatusStyles--eca42";
export var profileRoleStatusBlockStyles = "QuickActionPanel-module--profileRoleStatusBlockStyles--d3804";
export var raisinBlack = "#262626";
export var rajah = "#F3B765";
export var reservedStatusTextStyles = "QuickActionPanel-module--reservedStatusTextStyles--95b44";
export var xProfileRequestedStatusStyles = "QuickActionPanel-module--xProfileRequestedStatusStyles--b344a";