import { handleThrowError } from 'utils/services'

import { request as api } from './strapi/api'

export const getProfileStatus = async profileId => {
  try {
    const params = {
      profile: profileId,
      populate: [
        'bookedCandidate.partnerRole.partner',
        'bookedCandidate.assignee'
      ]
    }
    return await api.get(`/profile-statuses`, { params })
  } catch (e) {
    handleThrowError(e)
  }
}
