import React from 'react'

import { ActionDropdown } from '../ActionDropdown'

import { EditButton } from 'components/generic/EditButton'
import { Row } from 'components/Row'

import type { ActionButtonsRedesignProps } from './ActionButtonsRedesign.types'

import { editButtonStyles } from './ActionButtonsRedesign.module.css'

const ActionButtonsRedesign = (props: ActionButtonsRedesignProps) => {
  const { setIsEditMode, ...dropdownProps } = props

  return (
    <Row justify="end">
      <div className={editButtonStyles}>
        <EditButton onClick={() => setIsEditMode(true)} />
      </div>
      <ActionDropdown {...dropdownProps} />
    </Row>
  )
}

export default ActionButtonsRedesign
