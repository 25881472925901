import type { MouseEvent } from 'react'
import React from 'react'

import { App } from 'redesign/components'

import { ShortlistButton } from './components/ShortlistButton'

import { Button } from 'components/Button'
import { ReserveIcon, ShortlistIcon } from 'components/Svg'

import { ActionDropdown } from 'redesign/components/ActionDropdown'
import type { OverlayListItem } from 'redesign/components/OverlayList/OverlayList.types'

import { useProfileDuplicates } from 'redesign/modules/Profile/hooks/useProfileDuplicates'

import { useReserveRoleCandidate } from './hooks/useReserveRoleCandidate'
import { useShortlistRoleCandidate } from './hooks/useShortlistRoleCandidate'

import { useUser } from 'redesign/hooks/useUser'

import type { RoleCandidateFirstActionsButtonContainerProps } from './RoleCandidateFirstActionsButtonContainer.types'

import { loadingButtonStyles } from './RoleCandidateFirstActionsButtonContainer.module.css'

const RoleCandidateFirstActionsButtonContainer = ({
  profile,
  partnerRole,
  isReserved,
  candidateOrigin,
  isLoading: isLoadingProps
}: RoleCandidateFirstActionsButtonContainerProps) => {
  const {
    modal: { confirm }
  } = App.useApp()
  const { data: user } = useUser()
  const { shortlistRoleCandidate, isLoading: shortlistIsLoading } =
    useShortlistRoleCandidate()
  const { reserveRoleCandidate, isLoading: reserveIsLoading } =
    useReserveRoleCandidate()

  const { duplicates, isDuplicatesLoading } = useProfileDuplicates({
    profileId: profile.id
  })

  const isLoading =
    isLoadingProps ||
    shortlistIsLoading ||
    reserveIsLoading ||
    isDuplicatesLoading

  const isDuplicateProfile = Boolean(
    duplicates.filter(({ duplicateOf }) => duplicateOf !== profile.id).length
  )

  const onShortlist = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    partnerRole &&
      confirm({
        title: 'Shortlist',
        content: `Are you sure you want to shortlist ${
          profile.fullName || 'this profile'
        } for "${partnerRole.name}"?`,
        onOk: () => {
          shortlistRoleCandidate({
            profile: profile.id,
            partnerRole: partnerRole.id,
            shortlistedBy: user.id,
            origin: candidateOrigin
          })
        }
      })
  }

  const onReserve = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    partnerRole &&
      confirm({
        title: 'Reserve',
        content: `Are you sure you want to reserve ${
          profile.fullName || 'this profile'
        } for "${partnerRole.name}"?`,
        onOk: () => {
          reserveRoleCandidate({
            profile: profile.id,
            partnerRole: partnerRole.id,
            reservedBy: user.id,
            origin: candidateOrigin
          })
        }
      })
  }

  const actionItems: OverlayListItem[] = [
    {
      title: 'Shortlist',
      icon: <ShortlistIcon />,
      onClick: onShortlist
    },
    {
      title: 'Reserve',
      icon: <ReserveIcon />,
      onClick: onReserve,
      isVisible: !isReserved
    }
  ]

  if (isReserved) {
    return (
      <ShortlistButton
        onClick={onShortlist}
        loading={isLoading}
        disabled={!partnerRole}
        isDuplicateProfile={isDuplicateProfile}
      />
    )
  }

  if (isLoading) {
    return <Button className={loadingButtonStyles} loading={true} />
  }

  return (
    <ActionDropdown
      isDisabled={!partnerRole}
      actionListItems={actionItems}
      isDuplicateProfile={isDuplicateProfile}
    />
  )
}

export default RoleCandidateFirstActionsButtonContainer
