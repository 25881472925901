import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import { shortlistRoleCandidate as shortlistRoleCandidateService } from 'services/roleCandidates'

import type {
  ShortlistParams,
  UseShortlistRoleCandidateReturn
} from './useShortlistRoleCandidate.types'

const useShortlistRoleCandidate = (): UseShortlistRoleCandidateReturn => {
  const queryClient = useQueryClient()

  const shortlistMutation = useMutation<
    AxiosResponse,
    AxiosError,
    { params: ShortlistParams }
  >({
    mutationFn: ({ params }) => shortlistRoleCandidateService(params),
    onSuccess: () => {
      message.success('Role candidate shortlisted')
      queryClient.invalidateQueries({ queryKey: ['role-candidates'] })
      queryClient.invalidateQueries({ queryKey: ['roleSuggestions'] })
      queryClient.invalidateQueries({ queryKey: ['roleCandidateSuggestions'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: (err: Error) => {
      message.error(err?.message ?? 'Shortlist role candidate failed')
    }
  })

  const shortlistRoleCandidate = (params: ShortlistParams) =>
    shortlistMutation.mutate({ params })

  return {
    shortlistRoleCandidate,
    isLoading: shortlistMutation.isLoading
  }
}

export default useShortlistRoleCandidate
