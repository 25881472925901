import React, { useState } from 'react'

import { useLocation } from '@reach/router'

import { TabVisibilityWrapper } from 'redesign/providers/TabVisibilityWrapper'

import {
  ExperiencesSection,
  InternalSection,
  InterviewsSection,
  MarketingSection,
  NotesSection,
  NotificationPanels,
  OpportunitiesSection,
  Overview as ProfileOverviewSection,
  PreferencesSection,
  RecommendationsSection,
  ActivityLogSection,
  TestAssessmentSection
} from '../containers/'

import { Result } from 'components/Result'
import SEO from 'components/SEO'
import { TabPane, Tabs } from 'components/Tabs'

import {
  useProfile,
  useProfileExperiences,
  useProfileJobApplications,
  useProfileNotes,
  useProfileRoles,
  useProfileRunways,
  useProfileScreenings,
  useProfileTechEvaluations,
  useProfileTestAssessments
} from '../hooks/'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useProfileId } from 'redesign/hooks/useProfileId'

import { getNotFoundPageErrorMessage } from 'redesign/utils/helpers'

import {
  tabLinkSelectedStyles,
  tabLinkStyles,
  tabsStyles
} from './ProfilePage.module.css'

const ProfilePage = () => {
  const activeTabIndex = window.location.hash || '#experiences'
  const [selectedTab, setSelectedTab] = useState(activeTabIndex)

  const { data: featureFlags } = useFeatureFlags()
  const showActivityLogs = Boolean(featureFlags.RELEASE_ACTIVITY_LOGS)
  const isTestAssessmentEnabled = Boolean(featureFlags?.RELEASE_TEST_GORILLA)

  const { dataCount: notesCount } = useProfileNotes()
  const { openDataCount: openRolesCount } = useProfileRoles()
  const { dataCount: runwaysCount } = useProfileRunways()
  const { dataCount: screeningsCount } = useProfileScreenings()
  const { dataCount: experiencesCount } = useProfileExperiences()
  const { dataCount: techEvaluationsCount } = useProfileTechEvaluations()
  const { pendingDataCount: pendingJobApplicationsCount } =
    useProfileJobApplications()
  const {
    dataCount: testAssessmentsCount,
    testProviderUserId,
    isLoading: isTestAssessmentsLoading
  } = useProfileTestAssessments({
    enabled: isTestAssessmentEnabled
  })

  return (
    <>
      <NotificationPanels />
      <ProfileOverviewSection setSelectedTab={setSelectedTab} />
      <Tabs
        className={tabsStyles}
        activeKey={selectedTab}
        onTabClick={(key: string) => setSelectedTab(key)}
      >
        <TabPane
          key="#experiences"
          tab={
            <a
              href="#experiences"
              className={`${tabLinkStyles} ${
                selectedTab === '#experiences' ? tabLinkSelectedStyles : ''
              }`}
            >
              Experiences ({experiencesCount})
            </a>
          }
        >
          <ExperiencesSection />
        </TabPane>
        <TabPane
          key="#interviews"
          tab={
            <a
              href="#interviews"
              className={`${tabLinkStyles} ${
                selectedTab === '#interviews' ? tabLinkSelectedStyles : ''
              }`}
            >
              Interviews ({screeningsCount + techEvaluationsCount})
            </a>
          }
        >
          <InterviewsSection />
        </TabPane>
        {isTestAssessmentEnabled && Boolean(testProviderUserId) && (
          <TabPane
            key="#test-assessments"
            disabled={isTestAssessmentsLoading}
            tab={
              <a
                href="#test-assessments"
                className={`${tabLinkStyles} ${
                  selectedTab === '#test-assessments'
                    ? tabLinkSelectedStyles
                    : ''
                }`}
              >
                TestGorilla ({testAssessmentsCount})
              </a>
            }
          >
            <TestAssessmentSection />
          </TabPane>
        )}
        <TabPane
          key="#notes"
          tab={
            <a
              href="#notes"
              className={`${tabLinkStyles} ${
                selectedTab === '#notes' ? tabLinkSelectedStyles : ''
              }`}
            >
              Notes ({notesCount})
            </a>
          }
        >
          <NotesSection />
        </TabPane>
        <TabPane
          key="#opportunities"
          tab={
            <a
              href="#opportunities"
              className={`${tabLinkStyles} ${
                selectedTab === '#opportunities' ? tabLinkSelectedStyles : ''
              }`}
            >
              Opportunities ({openRolesCount + pendingJobApplicationsCount})
            </a>
          }
        >
          <OpportunitiesSection />
        </TabPane>
        <TabPane
          key="#preferences"
          tab={
            <a
              href="#preferences"
              className={`${tabLinkStyles} ${
                selectedTab === '#preferences' ? tabLinkSelectedStyles : ''
              }`}
            >
              Preferences
            </a>
          }
        >
          <PreferencesSection />
        </TabPane>
        <TabPane
          key="#recommendations"
          tab={
            <a
              href="#recommendations"
              className={`${tabLinkStyles} ${
                selectedTab === '#recommendations' ? tabLinkSelectedStyles : ''
              }`}
            >
              Recommendations
            </a>
          }
        >
          <RecommendationsSection />
        </TabPane>
        <TabPane
          key="#internal"
          tab={
            <a
              href="#internal"
              className={`${tabLinkStyles} ${
                selectedTab === '#internal' ? tabLinkSelectedStyles : ''
              }`}
            >
              Internal ({runwaysCount})
            </a>
          }
        >
          <InternalSection />
        </TabPane>
        <TabPane
          key="#marketing"
          tab={
            <a
              href="#marketing"
              className={`${tabLinkStyles} ${
                selectedTab === '#marketing' ? tabLinkSelectedStyles : ''
              }`}
            >
              Marketing
            </a>
          }
        >
          <MarketingSection />
        </TabPane>
        {showActivityLogs && (
          <TabPane
            key="#activity-log"
            tab={
              <a
                href="#activity-log"
                className={`${tabLinkStyles} ${
                  selectedTab === '#activity-log' ? tabLinkSelectedStyles : ''
                }`}
              >
                Activity Log
              </a>
            }
          >
            <ActivityLogSection />
          </TabPane>
        )}
      </Tabs>
    </>
  )
}

const ProfilePageVisibilitySEOWrapper = () => {
  const location = useLocation()
  const { data: profile, error } = useProfile()
  const { profileId } = useProfileId()

  const pageTitle = profile?.fullName
    ? `${profile.fullName + ' | Profiles'}`
    : `Profiles`

  const errorSubtitleMessage = getNotFoundPageErrorMessage({
    status: error?.response?.status,
    type: 'profile',
    id: profileId,
    params: location.search
  })

  if (error) {
    return (
      <Result
        status={error?.response?.status}
        title={error?.response?.statusText}
        subTitle={errorSubtitleMessage}
      />
    )
  }

  return (
    <>
      <SEO title={pageTitle} />
      <TabVisibilityWrapper>
        <ProfilePage />
      </TabVisibilityWrapper>
    </>
  )
}

export default ProfilePageVisibilitySEOWrapper
