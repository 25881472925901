import React, { useState } from 'react'

import { RoleCandidateFirstActionsButtonContainer } from 'redesign/containers/RoleCandidateFirstActionsButtonContainer'

import { SelectRole } from './components/SelectRole'

import { useProfile, useProfileRoles } from 'redesign/modules/Profile/hooks'

import { usePartnerRoles } from 'redesign/hooks/usePartnerRoles'

import { buildRoleCandidateOrigin } from 'redesign/utils/roleCandidateUtils'

import type { Origin } from 'redesign/types/Origin'
import type PartnerRole from 'redesign/types/PartnerRole'
import type Profile from 'redesign/types/Profile'

import {
  selectStyles,
  quickActionTitleStyles
} from './RoleActionsContainer.module.css'

type RoleActionsContainerProps = {
  profile: Profile
}

const RoleActionsContainer = ({ profile }: RoleActionsContainerProps) => {
  const { origin } = useProfile()
  const { data: roleCandidates, isLoading } = useProfileRoles()
  const [selectedRole, setSelectedRole] = useState<PartnerRole | undefined>()

  const { data: partnerRoles, mappedPartnerRoles } = usePartnerRoles()

  const candidateOrigin: Origin = buildRoleCandidateOrigin({
    origin,
    selectedRole,
    roleCandidates
  })

  const isReserved = Boolean(
    roleCandidates?.find(
      candidate =>
        candidate.reservedAt && !candidate.bookedAt && !candidate.closedAt
    )
  )

  const onChange = (value: number) => {
    const role = partnerRoles.find(role => role.id === value)
    setSelectedRole(role)
  }

  return (
    <>
      <h3 className={quickActionTitleStyles}>Quick Action:</h3>

      <div className={selectStyles}>
        <SelectRole
          partnerRoleOptions={mappedPartnerRoles}
          onChange={onChange}
          roleCandidates={roleCandidates}
        />
      </div>

      <RoleCandidateFirstActionsButtonContainer
        isLoading={isLoading}
        profile={profile}
        isReserved={isReserved}
        partnerRole={selectedRole}
        candidateOrigin={candidateOrigin}
      />
    </>
  )
}

export default RoleActionsContainer
