import React from 'react'

import { MasterDuplicateIndicatorFull } from '../MasterDuplicateIndicator'

import { Modal } from 'components/Modal'

import type {
  IProfileBlockProps,
  IRelatedProfilesModalProps
} from './RelatedProfilesModal.types'

import {
  relatedProfilesModalStyles,
  profileBlockStyles,
  fullNameStyles,
  emailStyles,
  idStyles,
  indicatorStyles
} from './RelatedProfilesModal.module.css'

const getProfileURL = (profileId: number) => `/profile?id=${profileId}`

const ProfileBlock = (props: IProfileBlockProps) => {
  const {
    profile: {
      id,
      email,
      fullName,
      duplicateOf
    }
  } = props
  return (
    <div
      className={profileBlockStyles}
      onClick={() => {
        window.open(getProfileURL(id), '_blank')
      }}
    >
      <div className={indicatorStyles}>
        <MasterDuplicateIndicatorFull isMaster={duplicateOf === null} />
      </div>
      <div className={fullNameStyles}>
        {fullName}
      </div>
      <div className={emailStyles}>
        {email}
        <span className={idStyles}>
          ID: {id}
        </span>
      </div>
    </div>
  )
}

const RelatedProfilesModal = (props: IRelatedProfilesModalProps) => {
  const { profiles, onClose } = props

  return (
    <Modal
      centered
      closable
      maskClosable
      width="520px"
      open={true}
      footer={null}
      onCancel={onClose}
      title="Related Profiles"
      className={relatedProfilesModalStyles}
    >
      <div>
        View all profiles related to this profile below. Click on a profile to access it.
        {
          profiles.map(profile =>
            (<ProfileBlock key={profile.id} profile={profile} />)
          )
        }
      </div>
    </Modal>
  )
}

export default RelatedProfilesModal
