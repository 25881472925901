import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listXHQLinks } from 'services/xhqlinks'

import { useProfileId } from 'redesign/hooks/useProfileId'

import type { IUseProfileXHQLinkReturn } from './useProfileXHQLink.types'

const useProfileXHQLink = (): IUseProfileXHQLinkReturn => {
  const { profileId } = useProfileId()

  const { data: rolesData, isLoading } = useQuery({
    queryKey: ['XHQLink', profileId],
    queryFn: () =>
      listXHQLinks({
        params: {
          profile: profileId
        }
      })
  })

  const data = useMemo(() => rolesData?.data[0] || {}, [rolesData])

  return {
    data,
    isLoading
  }
}
export default useProfileXHQLink
