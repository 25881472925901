// extracted by mini-css-extract-plugin
export var auroraOrange = "#E87040";
export var colors = "'theme/colors.module.css'";
export var errorMessageStyles = "SnoozeModal-module--errorMessageStyles--1a758";
export var errorStyles = "SnoozeModal-module--errorStyles--a02df";
export var fieldDropdownStyles = "SnoozeModal-module--fieldDropdownStyles--43d6d";
export var fieldStyles = "SnoozeModal-module--fieldStyles--043c6";
export var fieldsContainerStyles = "SnoozeModal-module--fieldsContainerStyles--35758";
export var goldenKey = "#D89614";
export var modalContentStyles = "SnoozeModal-module--modalContentStyles--60a85";
export var modalIconStyles = "SnoozeModal-module--modalIconStyles--142cc";
export var modalTitleStyles = "SnoozeModal-module--modalTitleStyles--7308f";