import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getActivityLogsCount } from 'services/activityLogs'

import type Profile from 'redesign/types/Profile'

type useActivityLogsCountParams = {
  profileId: Profile['id']
}

type useActivityLogsCountReturn = {
  data: number
  isLoading: boolean
}

const useActivityLogsCount = ({
  profileId
}: useActivityLogsCountParams): useActivityLogsCountReturn => {
  const { data: activityLogsCountData, isLoading } = useQuery({
    queryKey: ['activityLogCount', profileId],
    queryFn: () =>
      getActivityLogsCount({
        params: {
          profile: profileId
        }
      })
  })

  const activityLogsCount = useMemo(
    () => activityLogsCountData?.data || [],
    [activityLogsCountData]
  )

  return {
    data: activityLogsCount,
    isLoading
  }
}
export default useActivityLogsCount
