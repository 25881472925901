import useProfile from '../useProfile/useProfile'

import { useTestAssessments } from 'redesign/hooks/useTestAssessments'

import type {
  IUseProfileTestAssessmentsReturn,
  IUseProfileTestAssessmentsParams
} from './useProfileTestAssessments.types'

const useProfileTestAssessments = ({
  enabled
}: IUseProfileTestAssessmentsParams): IUseProfileTestAssessmentsReturn => {
  const { data: profile, isLoading: isProfileLoading } = useProfile()
  const { data, isLoading: isTestAssessmentLoading } = useTestAssessments({
    profileId: profile?.id,
    enabled: enabled && !isProfileLoading
  })

  return {
    data,
    dataCount: data.length,
    isLoading: Boolean(isTestAssessmentLoading || isProfileLoading),
    testProviderUserId: profile?.testGorillaUserId
  }
}

export default useProfileTestAssessments
