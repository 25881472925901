import React from 'react'

import { Button } from '../../Button'
import { EditIcon } from '../../Svg'

import type { EditButtonProps } from './EditButton.types'

import {
  buttonStyles,
  editIconBlockStyles,
  editIconStyles
} from './EditButton.module.css'

const EditButton = ({ onClick, isLoading }: EditButtonProps) => (
  <Button
    onClick={onClick}
    icon={
      <div className={editIconBlockStyles}>
        <EditIcon className={editIconStyles} />
      </div>
    }
    className={buttonStyles}
    loading={isLoading}
    data-testid="EditButton"
  />
)

export default EditButton
