import React from 'react'

import { InfoCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'

import { SNOOZE_ENUM } from 'utils/constants'

import type { ISnoozePanelProps } from './SnoozePanel.types'

import { panelStyles, iconStyles } from './SnoozePanel.module.css'

const SnoozePanel = ({ profileSnooze }: ISnoozePanelProps) => {
  if (!profileSnooze?.isSnoozed) {
    return null
  }

  const daysRemaining = dayjs(profileSnooze.expiresAt).fromNow(true)

  return (
    <div className={panelStyles}>
      <InfoCircleFilled className={iconStyles} />
      <span>
        This applicant was put in Snooze mode by{' '}
        {profileSnooze?.reviewer?.email} due to {SNOOZE_ENUM[profileSnooze.reason as unknown as keyof typeof SNOOZE_ENUM]} -{' '}
        {daysRemaining} remaining
      </span>
    </div>
  )
}

export default SnoozePanel
