import React from 'react'

import { useProfile } from '../../hooks'
import { SectionCard } from 'redesign/components'

import { Preferences } from './components'

const PreferencesSection = () => {
  const {
    data: profile,
    isLoading: profileIsLoading,
    updateProfile
  } = useProfile()

  return (
    <SectionCard isLoading={profileIsLoading}>
      <Preferences profile={profile} onSave={updateProfile} />
    </SectionCard>
  )
}
export default PreferencesSection
