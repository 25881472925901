import { intersectionBy, orderBy, differenceBy } from 'lodash'

import { monthsToYears } from 'redesign/utils/helpers'

import type { CandidateSkillRecord } from 'redesign/types/CandidateSkill'
import type RoleCandidate from 'redesign/types/RoleCandidate'
import type RoleSkill from 'redesign/types/RoleSkill'
import type Skill from 'redesign/types/Skill'

import type { TopSkill } from './useProfileXProfiles.types'

type GetTopSkillsProps = {
  roleCandidates: RoleCandidate[]
  candidateSkills: CandidateSkillRecord[]
}

/*
 * Top Skills default order:
 * Skills required for the role that the candidate is currently reserved for (ordered by duration of experience)
 * Skills optional for the role that the candidate is currently reserved for (ordered by duration of experience)
 * Remaining skills (ordered by duration of experience)
 */

export const getTopSkills = ({
  roleCandidates,
  candidateSkills
}: GetTopSkillsProps) => {
  // Get reserved role skills
  const reservedNotBookedCandidate = roleCandidates?.find(
    candidate =>
      candidate.reservedAt && !candidate.bookedAt && !candidate.closedAt
  )

  const requiredRoleSkills =
    reservedNotBookedCandidate?.partnerRole.roleSkills?.reduce(
      (result: Skill[], roleSkill: RoleSkill) => {
        if (roleSkill.required === true) {
          result.push(roleSkill.skill)
        }

        return result
      },
      []
    )

  const optionalRoleSkills =
    reservedNotBookedCandidate?.partnerRole.roleSkills?.reduce(
      (result: Skill[], roleSkill: RoleSkill) => {
        if (roleSkill.required === false) {
          result.push(roleSkill.skill)
        }

        return result
      },
      []
    )

  // Get candidate skills that are also required skills from the reserved role
  const sharedRequiredSkills = intersectionBy(
    candidateSkills,
    requiredRoleSkills as Partial<CandidateSkillRecord>[],
    'id'
  )
  // Get candidate skills that are also optional skills from the reserved role
  const sharedOptionalSkills = intersectionBy(
    candidateSkills,
    optionalRoleSkills as Partial<CandidateSkillRecord>[],
    'id'
  )

  // Remaining skills
  const filteredCandidateSkills = differenceBy(
    candidateSkills,
    [...sharedRequiredSkills, ...sharedOptionalSkills],
    'id'
  ).filter(skill => skill.monthsExperience !== null)

  // Sort skills by monthsExperience
  const orderedSharedRequiredSkills = orderBy(
    sharedRequiredSkills,
    'monthsExperience',
    'desc'
  )
  const orderedSharedOptionalSkills = orderBy(
    sharedOptionalSkills,
    'monthsExperience',
    'desc'
  )
  const orderedCandidateSkills = orderBy(
    filteredCandidateSkills,
    'monthsExperience',
    'desc'
  )

  // Group all the skills in the correct order
  const formatedTopSkills: CandidateSkillRecord[] = [
    ...orderedSharedRequiredSkills,
    ...orderedSharedOptionalSkills,
    ...orderedCandidateSkills
  ]

  // Format data to the format expected in X-Profile API
  const topSkills = formatedTopSkills.reduce(
    (result: TopSkill[], skill: CandidateSkillRecord, index: number) => {
      const experienceInYears = monthsToYears(skill.monthsExperience)

      if (
        index < 15 &&
        experienceInYears !== null &&
        skill.monthsExperience !== null
      ) {
        let seniority

        if (experienceInYears >= 7) {
          seniority = 'TEAM LEAD / ARCHITECT'
        } else if (experienceInYears >= 5) {
          seniority = 'SENIOR'
        } else if (experienceInYears >= 3) {
          seniority = 'MID'
        } else if (experienceInYears < 3) {
          seniority = 'JUNIOR'
        }

        const formattedSkill: TopSkill = {
          skill: skill.title,
          experienceMonthsDuration: skill.monthsExperience,
          seniority
        }

        result.push(formattedSkill)
      }

      return result
    },
    []
  )

  return topSkills
}
