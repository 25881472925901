import React, { useState } from 'react'

import { SimilarProfilesTable } from './components/SimilarProfilesTable'

import { SectionCard } from 'redesign/components/'
import { RoleSuggestionsTable } from 'redesign/components/RoleSuggestionsTable'

import { useSimilarProfiles } from '../../hooks/useSimilarProfiles'
import { useSuggestedProfiles } from '../../hooks/useSuggestedProfiles'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileId } from 'redesign/hooks/useProfileId'
import { useRoleCandidates } from 'redesign/hooks/useRoleCandidates'
import { useRoleSuggestions } from 'redesign/hooks/useRoleSuggestions'
import { useUser } from 'redesign/hooks/useUser'

const RecommendationsSection: React.FC = () => {
  const [selectedRoleModels, setSelectedRoleModels] = useState<string[]>()
  const [selectedSimilarityModels, setSelectedSimilarityModels] =
    useState<string[]>()
  const [selectedSuggestionModels, setSelectedSuggestionModels] =
    useState<string[]>()

  const { profileId } = useProfileId()
  const { data: authData } = useUser()
  const { id: userId } = authData
  const {
    data: roleSuggestions,
    isLoading: roleSuggestionsIsLoading,
    error: roleSuggestionsError,
    unhideSuggestion,
    hideSuggestion,
    hideUnhideIsLoading
  } = useRoleSuggestions({ profile: profileId, models: selectedRoleModels })

  const {
    error: similarProfilesError,
    data: similarProfiles,
    isLoading: similarProfilesIsLoading
  } = useSimilarProfiles(selectedSimilarityModels)

  const {
    error: suggestedProfilesError,
    data: suggestedProfiles,
    isLoading: suggestedProfilesIsLoading
  } = useSuggestedProfiles(selectedSuggestionModels)

  const {
    transferRoleCandidateMutation,
    shortlistRoleCandidateMutation,
    reserveRoleCandidateMutation,
    isLoading: roleCandidateActionsLoading
  } = useRoleCandidates()

  const {
    data: roleCandidates,
    error: roleCandidatesError,
    isLoading: roleCandidatesIsLoading
  } = usePartnerRoleCandidates({ params: { profile: profileId } })

  const { data: featureFlags } = useFeatureFlags()
  const isSimilarProfilesEnabled = Boolean(featureFlags?.OPS_SIMILAR_PROFILES)
  const isSuggestedProfilesEnabled = Boolean(
    featureFlags?.OPS_SUGGESTED_PROFILES
  )

  return (
    <>
      <SectionCard isLoading={false}>
        <RoleSuggestionsTable
          roleCandidates={roleCandidates}
          data={roleSuggestions}
          profileId={profileId}
          handleHide={hideSuggestion}
          handleUnhide={unhideSuggestion}
          handleReserve={values =>
            reserveRoleCandidateMutation({
              ...values,
              reservedBy: userId
            })
          }
          handleShortlist={values =>
            shortlistRoleCandidateMutation({
              ...values,
              shortlistedBy: userId
            })
          }
          handleTransfer={transferRoleCandidateMutation}
          isLoading={
            roleSuggestionsIsLoading ||
            hideUnhideIsLoading ||
            roleCandidatesIsLoading ||
            roleCandidateActionsLoading
          }
          error={roleSuggestionsError || roleCandidatesError}
          setSelectedModels={setSelectedRoleModels}
          selectedModels={selectedRoleModels}
          authData={authData}
        />
      </SectionCard>

      {isSimilarProfilesEnabled && (
        <SectionCard isLoading={false}>
          <SimilarProfilesTable
            data={similarProfiles}
            isLoading={similarProfilesIsLoading}
            error={similarProfilesError}
            setSelectedModels={setSelectedSimilarityModels}
            selectedModels={selectedSimilarityModels}
          />
        </SectionCard>
      )}

      {isSuggestedProfilesEnabled && (
        <SectionCard isLoading={false}>
          <SimilarProfilesTable
            data={suggestedProfiles}
            isLoading={suggestedProfilesIsLoading}
            error={suggestedProfilesError}
            setSelectedModels={setSelectedSuggestionModels}
            selectedModels={selectedSuggestionModels}
            title="Suggested Profiles"
          />
        </SectionCard>
      )}
    </>
  )
}

export default RecommendationsSection
