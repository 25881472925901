import React, { useState } from 'react'

import { MarkDuplicatesModal } from './components/MarkDuplicatesModal'

import { message } from 'components/Message'

import { useMasterProfiles } from 'redesign/modules/Profile/hooks/useMasterProfiles'

import { advancedSearch } from 'services/search'

import type { IMarkProfileDuplicatesModalProps, SearchResults } from './MarkProfileDuplicatesModal.types'

const MarkProfileDuplicatesModal = (props: IMarkProfileDuplicatesModalProps) => {
  const [searchResults, setSearchResults] = useState<SearchResults[]>([])
  const {
    data: masterProfiles,
    isLoading: masterProfilesLoading,
  } = useMasterProfiles()

  async function fetchResults (searchString: string) {
    try {
      const response = await advancedSearch({
        params: {
          nameOrEmail: searchString.trim(),
          _limit: 10
        }
      })
      setSearchResults(response.data.results)
    } catch (error) {
      message.error('Error fetching search results')
    }
  }

  return (
    <MarkDuplicatesModal
      fetchResults={fetchResults}
      searchResults={searchResults}
      masterProfiles={masterProfiles}
      masterProfilesLoading={masterProfilesLoading}
      {...props}
    />
  )
}

export default MarkProfileDuplicatesModal
