import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import { Spin } from 'components/Spin'

import { ProfileVotes as ProfileVotesComponent } from 'redesign/components/ProfileVotes'

import type { ProfileVotesProps } from './ProfileVotes.types'

const ProfileVotes = ({
  votes,
  reviewer,
  votesIsLoading,
  createProfileUpVote,
  createProfileDownVote,
  updateProfileVote,
  deleteProfileVote
}: ProfileVotesProps) => {
  const currentVote = votes?.data?.find(vote => vote.reviewer === reviewer)
  const isUpVote = Boolean(currentVote?.vote)
  const isDownVote = currentVote && !currentVote?.vote

  const handleUpVote = () => {
    if (!currentVote) {
      // Handle new up vote
      createProfileUpVote()
    } else if (currentVote.vote) {
      // Handle existing up vote
      deleteProfileVote(currentVote?.id)
    } else {
      // Handle existing down vote
      updateProfileVote({
        voteId: currentVote?.id,
        values: { vote: true }
      })
    }
  }

  const handleDownVote = () => {
    if (!currentVote) {
      // Handle new down vote
      createProfileDownVote()
    } else if (!currentVote?.vote) {
      // Handle existing down vote
      deleteProfileVote(currentVote?.id)
    } else {
      // Handle existing up vote
      updateProfileVote({
        voteId: currentVote?.id,
        values: { vote: false }
      })
    }
  }

  if (votesIsLoading) {
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    )
  }

  return (
    <ProfileVotesComponent
      votes={votes}
      isUpVote={isUpVote}
      isDownVote={isDownVote}
      handleUpVote={handleUpVote}
      handleDownVote={handleDownVote}
    />
  )
}
export default ProfileVotes
