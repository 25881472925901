import React from 'react'

import { Col } from '../../Col'
import { Row } from '../../Row'

import { formatText } from '../../../utils/helpers'

import {
  content,
  detailsTitle,
  row as rowStyles
} from './DetailsSubItem.module.css'

const DetailsSubItem = ({ title, text, children }) => {
  if (!text && !children) {
    return null
  }

  return (
    <Row className={rowStyles}>
      {title && (
        <Col span="8" className={detailsTitle}>
          {title}
        </Col>
      )}
      <Col span="16">
        <div dangerouslySetInnerHTML={{ __html: formatText(text) }} />
        {children && <div className={content}>{children}</div>}
      </Col>
    </Row>
  )
}
export default DetailsSubItem
