import React from 'react'

import {
  useProfile,
  useProfileRunways,
  useProfileSlackNotification,
  useProfileXHQLink
} from '../../hooks'
import { SectionCard } from 'redesign/components'

import { useProfileDuplicates } from '../../hooks/useProfileDuplicates'

import { useProfileId } from 'redesign/hooks/useProfileId'
import { useUser } from 'redesign/hooks/useUser'

import { InternalProjectDetails, RunwaysTable } from './components'

const InternalSection = () => {
  const { profileId } = useProfileId()

  const {
    data: profile,
    isLoading: profileIsLoading,
    updateProfile
  } = useProfile()

  const {
    data: runways,
    activeRunways,
    isLoading: runwaysIsLoading,
    createRunway,
    updateRunway,
    deleteRunway
  } = useProfileRunways()

  const { duplicates, isDuplicatesLoading } = useProfileDuplicates({
    profileId: Number(profileId)
  })

  const { data: user } = useUser()
  const isUserManager = user.role.type === 'manager'

  const { data: xhqLink } = useProfileXHQLink()
  const { createSlackNotification } = useProfileSlackNotification()

  const isLoading = Boolean(
    profileIsLoading || runwaysIsLoading || isDuplicatesLoading
  )

  return (
    <>
      <SectionCard isLoading={isLoading}>
        <InternalProjectDetails profile={profile} onSave={updateProfile} />
      </SectionCard>
      <SectionCard isLoading={isLoading}>
        <RunwaysTable
          duplicates={duplicates}
          runways={runways}
          activeRunways={activeRunways}
          profileId={profileId}
          xhqLink={xhqLink}
          isUserManager={isUserManager}
          onCreate={createRunway}
          onEdit={updateRunway}
          onDelete={deleteRunway}
          createSlackNotification={createSlackNotification}
        />
      </SectionCard>
    </>
  )
}

export default InternalSection
