import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { RunwayForm } from '../RunwayForm'

import { Modal } from 'components/Modal'

import type { IRunwayModalProps } from './RunwayModal.types'

import {
  modalTitleStyles,
  modalContentStyles,
  modalTitleIconStyles
} from './RunwayModal.module.css'

const RunwayModal = ({
  visible,
  profileId,
  closeForm,
  onCreate,
  onEdit,
  initialValues
}: IRunwayModalProps) => {
  const modalTitle = initialValues ? 'Edit Runway' : 'Create a Runway'

  return (
    <Modal width="500px" open={visible} centered footer={null}>
      <div className={modalContentStyles}>
        <h3 className={modalTitleStyles}>
          <ExclamationCircleOutlined className={modalTitleIconStyles} />
          {modalTitle}
        </h3>
        <RunwayForm
          initialValues={initialValues}
          profileId={profileId}
          closeForm={closeForm}
          onCreate={onCreate}
          onEdit={onEdit}
        />
      </div>
    </Modal>
  )
}

export default RunwayModal
