import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const createProfileSnooze = async profileSnooze => {
  try {
    const response = await api.post(`/profile-snoozes`, profileSnooze)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteProfileSnooze = async profileSnoozeId => {
  try {
    const response = await api.delete(`/profile-snoozes/${profileSnoozeId}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
