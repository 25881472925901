import React from 'react'

import { DownArrowIcon, UpArrowIcon } from 'components/Svg'

import type { ProfileVotesProps } from './ProfileVotes.types'

import { profileVotesStyles, profileVotesCursorStyles, votesCountStyles } from './ProfileVotes.module.css'
import {
  gainsBoro,
  raspberryWine,
  botanicalGarden
} from 'theme/colors.module.css'

export const ProfileVotes = ({ votes, isUpVote, isDownVote, handleUpVote, handleDownVote }: ProfileVotesProps) => {
  const votesSign = votes && votes.count > 0 ? '+' : ''

  return (
    <div className={profileVotesStyles} data-testid="profile-voting">
      <div onClick={handleUpVote} data-testid="profile-upvote" className={profileVotesCursorStyles}>
        <UpArrowIcon
          width={'18'}
          stroke={isUpVote ? botanicalGarden : gainsBoro}
        />
      </div>

      <div className={votesCountStyles}>
        {votesSign}
        {votes?.count}
      </div>

      <div onClick={handleDownVote} data-testid="profile-downvote" className={profileVotesCursorStyles}>
        <DownArrowIcon
          width={'18'}
          stroke={isDownVote ? raspberryWine : gainsBoro}
        />
      </div>
    </div>
  )
}
