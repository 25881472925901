import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useForm } from '../../../../../../../hooks/useForm'
import { SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'
import { InputNumber } from 'components/InputNumber'

import { App } from 'redesign/components/App'

import type { IInternalProjectDetailsFormProps } from './InternalProjectDetailsForm.types'

import {
  fieldStyles,
  saveButtonStyle
} from './InternalProjectDetailsForm.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const InternalProjectsDetailsForm = ({
  initialValues,
  closeForm,
  onSave
}: IInternalProjectDetailsFormProps) => {
  const { modal: { confirm } } = App.useApp()

  const [form] = useForm()

  const onClickSave = () => {
    const values = form.getFieldsValue()
    onSave(values, {
      onSuccess: closeForm
    })
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  return (
    <SectionCardContent
      title="Internal Projects Details"
      actions={
        <div>
          <Button
            type="primary"
            onClick={onClickSave}
            className={saveButtonStyle}
          >
            Save
          </Button>
          <Button onClick={onClickCancel}>Cancel</Button>
        </div>
      }
    >
      <Form
        data-testid="profileInternalProjectDetailsForm-form"
        form={form}
        initialValues={initialValues}
      >
        <Descriptions
          bordered
          column={1}
          labelStyle={{ backgroundColor: dynamicBlack }}
          contentStyle={{ backgroundColor: sootyBlack }}
        >
          <DescriptionsItem label="Status" className={fieldStyles}>
            <FormItem name="benchStatus" noStyle>
              <Input maxLength={255} />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem label="Summary" className={fieldStyles}>
            <FormItem name="benchSummary" noStyle>
              <Input maxLength={255} />
            </FormItem>
          </DescriptionsItem>
          <DescriptionsItem
            label="Developer's XHQ Rate"
            className={fieldStyles}
          >
            <FormItem name="xhqRate" noStyle>
              <InputNumber min={0} />
            </FormItem>
          </DescriptionsItem>
        </Descriptions>
      </Form>
    </SectionCardContent>
  )
}

export default InternalProjectsDetailsForm
