// extracted by mini-css-extract-plugin
export var addButtonStyles = "MarkDuplicatesModal-module--addButtonStyles--79fe4";
export var amazonParrot = "#94E66B";
export var brightNavyBlue = "#177DDC";
export var buttonRowStyles = "MarkDuplicatesModal-module--buttonRowStyles--d9664";
export var closeIconStyles = "MarkDuplicatesModal-module--closeIconStyles--2b824";
export var colors = "'theme/colors.module.css'";
export var darkCharcoal = "#303030";
export var duplicateColor = "MarkDuplicatesModal-module--duplicateColor--12e11";
export var emailStyles = "MarkDuplicatesModal-module--emailStyles--967a8";
export var fullNameStyles = "MarkDuplicatesModal-module--fullNameStyles--3cfa2";
export var gainsBoro = "#DBDBDB";
export var idStyles = "MarkDuplicatesModal-module--idStyles--b9cd3";
export var indicatorStyles = "MarkDuplicatesModal-module--indicatorStyles--af173";
export var inputFieldStyles = "MarkDuplicatesModal-module--inputFieldStyles--f7315";
export var markDuplicatesButtonStyles = "MarkDuplicatesModal-module--markDuplicatesButtonStyles--26723";
export var markDuplicatesModalStyles = "MarkDuplicatesModal-module--markDuplicatesModalStyles--cfc34";
export var masterColor = "MarkDuplicatesModal-module--masterColor--71b42";
export var mayaBlue = "#65b7f3";
export var profileBlockStyles = "MarkDuplicatesModal-module--profileBlockStyles--22d42";
export var profileColoredStyles = "MarkDuplicatesModal-module--profileColoredStyles--e6726";
export var radioButtonMasterStyles = "MarkDuplicatesModal-module--radioButtonMasterStyles--60db8";
export var radioButtonStyles = "MarkDuplicatesModal-module--radioButtonStyles--9e309";
export var searchBlockInfoStyles = "MarkDuplicatesModal-module--searchBlockInfoStyles--5d136";
export var searchBlockStyles = "MarkDuplicatesModal-module--searchBlockStyles--4393a";
export var searchBlockTitleStyles = "MarkDuplicatesModal-module--searchBlockTitleStyles--a32da";
export var searchBoxStyles = "MarkDuplicatesModal-module--searchBoxStyles--48e1a";
export var sootyBlack = "#141414";
export var sunSalutation = "#E6C46B";