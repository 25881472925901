import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listTestAssessments } from 'services/testAssessments'

import type {
  IUseTestAssessmentsReturn,
  IUseTestAssessmentsParams
} from './useTestAssessments.types'

const useTestAssessments = ({
  profileId,
  enabled = true
}: IUseTestAssessmentsParams): IUseTestAssessmentsReturn => {
  const { data: testAssessmentsData, isLoading } = useQuery({
    queryKey: ['testAssessments', profileId],
    queryFn: () => listTestAssessments({ params: { profileId, _limit: 999 } }),
    enabled,
    refetchOnWindowFocus: false
  })

  const data = useMemo(
    () => testAssessmentsData?.data ?? [],
    [testAssessmentsData]
  )

  return {
    data,
    isLoading
  }
}

export default useTestAssessments
