import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { useForm } from '../../../../../../../hooks/useForm'
import { App } from 'redesign/components'

import { Button } from 'components/Button'
import { Col } from 'components/Col'
import { DatePicker } from 'components/DatePicker'
import { Form, FormItem } from 'components/Form'
import { InputNumber } from 'components/InputNumber'
import { Row } from 'components/Row'
import { Select } from 'components/Select'
import { Switch } from 'components/Switch'

import { RUNWAY_REASONS } from 'redesign/utils/constants'

import type { IRunwayForm } from './RunwayForm.types'

import {
  switchStyles,
  activeFieldStyles,
  activeFieldLabelStyles
} from './RunwayForm.module.css'

const RunwayForm = ({
  profileId,
  closeForm,
  initialValues,
  onCreate,
  onEdit
}: IRunwayForm) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()

  const isRunwayActive = initialValues ? initialValues.active : true
  const [activeField, setActiveField] = useState(isRunwayActive)

  const onClickSave = async () => {
    try {
      // Throw exception if any required field is empty
      await form.validateFields()
    } catch (e) {
      return
    }

    const values = { profile: profileId, ...form.getFieldsValue() }

    if (initialValues) {
      const runwayId = initialValues.id
      const hasRateConcerns = Boolean(values.hasRateConcerns === 'true')
      onEdit &&
        onEdit(
          { runwayId, values: { ...values, hasRateConcerns } },
          {
            onSuccess: closeForm
          }
        )
    } else {
      onCreate &&
        onCreate(values, {
          onSuccess: closeForm
        })
    }
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  const formattedInitialValues = {
    ...initialValues,
    startAt: initialValues?.startAt && dayjs(initialValues.startAt),
    endAt: initialValues?.endAt && dayjs(initialValues.endAt),
    hasRateConcerns:
      initialValues?.hasRateConcerns !== null &&
      initialValues?.hasRateConcerns !== undefined
        ? String(initialValues?.hasRateConcerns)
        : null
  }

  return (
    <Form
      data-testid="profileRunwayForm-form"
      layout="vertical"
      form={form}
      initialValues={formattedInitialValues}
    >
      <div className={activeFieldStyles}>
        <span className={activeFieldLabelStyles}>Active</span>
        <FormItem name="active" label="Active" noStyle>
          <Switch
            size="small"
            checked={activeField}
            onChange={checked => setActiveField(checked)}
            className={switchStyles}
          />
        </FormItem>
      </div>

      <Row justify="space-between">
        <FormItem
          name="startAt"
          label="Start Date:"
          rules={[{ required: true }]}
        >
          <DatePicker size="large" />
        </FormItem>

        <FormItem name="endAt" label="End Date:">
          <DatePicker size="large" />
        </FormItem>
      </Row>

      <Col span={24}>
        <FormItem
          name="comingOffProjectReason"
          label="Reason for coming off the project:"
          rules={[{ required: true }]}
        >
          <Select
            size="large"
            options={RUNWAY_REASONS}
            filterOption={false}
            placeholder="Select a Reason"
            data-testid="select-reason"
          />
        </FormItem>
      </Col>

      <Row justify="space-between">
        <FormItem name="hasRateConcerns" label="Rate Concerns:">
          <Select
            size="large"
            filterOption={false}
            data-testid="select-rate-concern"
            style={{ width: '75px' }}
            options={[
              {
                label: 'Yes',
                value: 'true'
              },
              {
                label: 'No',
                value: 'false'
              }
            ]}
          />
        </FormItem>

        <FormItem
          name="rate"
          label="Rate (USD/hr)"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} size="large" formatter={value => `$${value}`} />
        </FormItem>
      </Row>

      <Row justify="space-evenly">
        <Button type="primary" onClick={onClickSave}>
          Save
        </Button>
        <Button onClick={onClickCancel}>Cancel</Button>
      </Row>
    </Form>
  )
}

export default RunwayForm
