import { useMemo } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  getProfileVotes,
  createProfileVote as createProfileVoteService,
  updateProfileVote as updateProfileVoteService,
  deleteProfileVote as deleteProfileVoteService
} from 'services/profileVotes'

import { useProfileId } from 'redesign/hooks/useProfileId'
import { useUser } from 'redesign/hooks/useUser'

import type ProfileVote from 'redesign/types/ProfileVote'

import type { IUseProfileVotesReturn, UpdateProfileVoteParams } from './useProfileVotes.types'

const useProfileVotes = (): IUseProfileVotesReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()
  const { data: user } = useUser()

  // Get
  const {
    data: votesData,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['profileVotes', profileId],
    queryFn: () => getProfileVotes({ params: { profile: profileId } })
  })
  const data: ProfileVote = useMemo(() => votesData?.data || [], [votesData])

  // Post
  const createMutation = useMutation({
    mutationFn: createProfileVoteService,
    onSuccess: () => {
      message.success('Vote submitted')
      queryClient.invalidateQueries({ queryKey: ['profileVotes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Submit vote failed')
    }
  })

  const createProfileUpVote = () => {
    const values = {
      vote: true,
      profile: profileId,
      reviewer: user.id
    }
    createMutation.mutate(values)
  }

  const createProfileDownVote = () => {
    const values = {
      vote: false,
      profile: profileId,
      reviewer: user.id
    }
    createMutation.mutate(values)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateProfileVoteParams
  >({
    mutationFn: ({ voteId, values }) =>
      updateProfileVoteService(voteId, values),
    onSuccess: () => {
      message.success('Vote submitted')
      queryClient.invalidateQueries({ queryKey: ['profileVotes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Submit vote failed')
    }
  })

  const updateProfileVote = (values: UpdateProfileVoteParams) => {
    updateMutation.mutate(values)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteProfileVoteService,
    onSuccess: () => {
      message.success('Vote deleted')
      queryClient.invalidateQueries({ queryKey: ['profileVotes'] })
      queryClient.invalidateQueries({ queryKey: ['activityLog'] })
      queryClient.invalidateQueries({ queryKey: ['activityLogCount'] })
    },
    onError: () => {
      message.error('Delete vote failed')
    }
  })

  const deleteProfileVote = (voteId: number) => {
    deleteMutation.mutate(voteId)
  }

  return {
    data,
    isLoading:
      isLoading ||
      isFetching ||
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading,
    createProfileUpVote,
    createProfileDownVote,
    updateProfileVote,
    deleteProfileVote
  }
}
export default useProfileVotes
