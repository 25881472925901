import React from 'react'

import clsx from 'clsx'

import { Col } from 'components/Col'
import { Row } from 'components/Row'

import type { ExpandedRowProps } from './ExpandedRow.types'

import { rowStyles, loadingRowStyles } from './ExpandedRow.module.css'

const ExpandedRow = ({ isLoading, testResults }: ExpandedRowProps) => {
  if (isLoading) {
    return (
      <Row align="middle" className={clsx(rowStyles, loadingRowStyles)}>
        <span>Loading tests results for the assessment...</span>
      </Row>
    )
  }

  return (
    <>
      {testResults.map(({ id, name, score }) => (
        <Row key={id} className={rowStyles}>
          <Col span={3} offset={1}>
            {name}
          </Col>
          <Col span={3}>Score: {score}%</Col>
        </Row>
      ))}
    </>
  )
}

export default ExpandedRow
