import React, { useState, useCallback } from 'react'

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { Button } from '../../Button'
import { Row } from '../../Row'

import { moreButton } from './DetailsItem.module.css'

const DetailsItem = ({ children, truncateAtItem = 2 }) => {
  const [more, setMore] = useState(false)

  const toggleMore = useCallback(
    () => (more ? setMore(false) : setMore(true)),
    [more]
  )

  if (children?.length <= truncateAtItem) {
    return children
  }

  return (
    <>
      {more ? children : children?.slice(0, truncateAtItem)}
      <Row justify="center">
        <Button
          icon={more ? <CaretUpOutlined /> : <CaretDownOutlined />}
          className={moreButton}
          onClick={toggleMore}
        >
          {more ? 'Less' : 'More'}
        </Button>
      </Row>
    </>
  )
}

export default DetailsItem
