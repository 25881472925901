import type { MouseEvent } from 'react'
import React, { useState } from 'react'

import {
  ExclamationCircleOutlined,
  VideoCameraOutlined
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Link } from 'gatsby'
import moment from 'moment'
import type { ReactPlayerProps } from 'react-player'

import { TechEvaluationExpandedRow } from '../TechEvaluationExpandedRow'
import { App, SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import CavalryAvatar from 'components/CavalryAvatar'
import { RoleFitTag } from 'components/RoleFitTag'
import { Skill } from 'components/Skill'
import type { ColumnsType, ColumnType } from 'components/Table'
import { Table } from 'components/Table'
import { XTeamFitTag } from 'components/XTeamFitTag'

import { EntityIcon } from 'redesign/components/EntityIcon'
import { TableActions } from 'redesign/components/TableActions'
import { TechEvaluationModal } from 'redesign/components/TechEvaluationModal'
import { TechEvaluationPlayerModal } from 'redesign/components/TechEvaluationPlayerModal'

import { useModal } from 'hooks/useModal'

import { buildRoleFitTooltipText, sortByField } from 'utils/helpers'

import type SkillType from 'redesign/types/Skill'
import type TechnicalEvaluation from 'redesign/types/TechnicalEvaluation'
import type User from 'redesign/types/User'

import type { ITechEvaluationsTableProps } from './TechEvaluationsTable.types'

import {
  assessmentValueStyles,
  expandAllButtonStyles,
  recordIconStyles
} from './TechEvaluationsTable.module.css'

const TechEvaluationsTable = ({
  user,
  profileId,
  profileExperts,
  techEvaluations,
  createTechEvaluation,
  updateTechEvaluation,
  autoSaveTechEvaluation,
  deleteTechEvaluation,
  expandedRows,
  onExpandRow,
  onExpandAllRows,
  roleCandidates,
  isLoadingRoleCandidates,
  features,
  updateFxtStatus,
  isFxt,
  findAndUpdateJobApplication,
  isMutationLoading
}: ITechEvaluationsTableProps) => {
  const { modal: { confirm } } = App.useApp()
  const { isOpen: isModalOpen, closeModal, openModal } = useModal(false)
  const {
    isOpen: isPlayerModalOpen,
    closeModal: closePlayerModal,
    openModal: openPlayerModal
  } = useModal(false)

  const [selectedTechEvaluation, setSelectedTechEvaluation] =
    useState(undefined)

  const [videoURL, setVideoURL] = useState<string | undefined | null>(undefined)
  const [player, setPlayer] = useState<ReactPlayerProps | undefined>(undefined)

  const isAllRowsExpanded = Boolean(
    expandedRows.length === techEvaluations.length
  )

  const handleCloseModal = () => {
    closeModal()
    setSelectedTechEvaluation(undefined)
  }

  const onDelete = (event: MouseEvent, techEvaluationId: number) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this Technical Evaluation?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        deleteTechEvaluation(techEvaluationId)
      }
    })
  }

  const roleNameColumn: ColumnType<TechnicalEvaluation> | undefined =
    features?.SCREEN_QUALIFY_ROLE_CANDIDATES
      ? {
        title: 'Role Name',
        dataIndex: 'role_candidate',
        width: 200,
        render: value =>
          value !== null && (
            <Link to={`/role?id=${value.partnerRole.id}`}>
              {value.partnerRole.name}
            </Link>
          )
      }
      : undefined

  const roleFitColumn: ColumnType<TechnicalEvaluation> | undefined =
    features?.SCREEN_QUALIFY_ROLE_CANDIDATES
      ? {
        title: 'Role Fit',
        dataIndex: 'roleFit',
        align: 'center',
        key: 'roleFit',
        width: 150,
        render: (value, record) => value !== null && (
          <RoleFitTag
            isFit={value}
            tooltipText={buildRoleFitTooltipText({
              isFit: Boolean(value),
              userName: (record.author as User)?.username,
              date: record.role_candidate?.updated_at,
              context: 'Technical Evaluation'
            })
            }
          />
        )
      }
      : undefined

  const columns: ColumnsType<TechnicalEvaluation> = [
    {
      title: 'Evaluated By',
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render: (value, { author }) => (
        <CavalryAvatar email={author?.email} profileURL={author.profileURL} />
      )
    },
    {
      title: 'Assessment',
      dataIndex: 'body',
      key: 'body',
      render: (value, { published_at: publishedAt }) => {
        const assessmentValue = publishedAt ? value : `DRAFT - ${value}`
        return <span className={assessmentValueStyles}>{assessmentValue}</span>
      },
      width: 200,
      ellipsis: true,
      responsive: ['sm']
    },
    {
      title: 'Recording',
      dataIndex: 'recording',
      align: 'center',
      key: 'recording',
      width: 120,
      render: value =>
        value && (
          <Button
            shape="circle"
            icon={<VideoCameraOutlined />}
            className={recordIconStyles}
            onClick={event => {
              event.stopPropagation()
              player && player.seekTo(0, 'fraction')
              setVideoURL(value)
              openPlayerModal()
            }}
          />
        )
    },
    ...(roleNameColumn ? [roleNameColumn] : []),
    {
      title: 'X-Team Fit',
      dataIndex: 'xteamFit',
      align: 'center',
      key: 'xteamFit',
      width: 150,
      render: value => value !== null && <XTeamFitTag fit={value} />
    },
    ...(roleFitColumn ? [roleFitColumn] : []),
    {
      title: 'Qualified Skills',
      dataIndex: 'skills',
      key: 'skills',
      width: 150,
      render: (value: SkillType[]) => (
        <>
          {(value || [])
            .sort(sortByField('title'))
            .slice(0, 4)
            .map(({ id, title }) => (
              <Skill key={id} variant="qualified" title={title} />
            ))}
        </>
      )
    },
    {
      title: 'Expert',
      dataIndex: 'expert',
      key: 'expert',
      width: 160,
      render: value => (
        <EntityIcon enabled={Boolean(value)} activeTitle="" inactiveTitle="" />
      ),
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      onFilter: (value, record) => Boolean(record.expert) === value
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      render: value => <Tooltip title={moment(value).format('DD MMM YYYY')}>
        {moment(value).fromNow()}
      </Tooltip>,
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 160,
      render: value => <Tooltip title={moment(value).format('DD MMM YYYY')}>
        {moment(value).fromNow()}
      </Tooltip>,
      sorter: (a, b) =>
        new Date(a.updated_at).valueOf() - new Date(b.updated_at).valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Actions',
      align: 'center',
      width: 200,
      render: record => (
        <TableActions
          user={user}
          record={record}
          onClickEdit={event => {
            event.stopPropagation()
            setSelectedTechEvaluation(record)
            openModal()
          }}
          onDelete={event => onDelete(event, record.id)}
          canUpdate={Boolean(user.role.capabilities.canUpdateAnyTechEval)}
          tableName="techEvaluations"
        />
      ),
      responsive: ['sm']
    }
  ]

  const playerRef = (videoPlayer: ReactPlayerProps) => {
    setPlayer(videoPlayer)
  }

  return (
    <>
      <SectionCardContent
        title="Technical Evaluations"
        actions={
          <div>
            <Button onClick={onExpandAllRows} className={expandAllButtonStyles}>
              {isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
            <Button type="primary" onClick={openModal}>
              Create
            </Button>
          </div>
        }
      >
        <Table
          rowKey="id"
          expandRowByClick
          columns={columns}
          dataSource={techEvaluations}
          pagination={false}
          scroll={{ x: true }}
          onExpand={onExpandRow}
          expandedRowKeys={expandedRows}
          expandable={{
            expandedRowRender: data => (
              <TechEvaluationExpandedRow techEvaluation={data} />
            )
          }}
        />
      </SectionCardContent>

      {isModalOpen && (
        <TechEvaluationModal
          visible={isModalOpen}
          profileId={profileId}
          techEvaluation={selectedTechEvaluation}
          closeForm={handleCloseModal}
          createTechEvaluation={createTechEvaluation}
          updateTechEvaluation={updateTechEvaluation}
          autoSaveTechEvaluation={autoSaveTechEvaluation}
          deleteTechEvaluation={deleteTechEvaluation}
          profileExperts={profileExperts}
          roleCandidates={roleCandidates}
          isLoadingRoleCandidates={isLoadingRoleCandidates}
          features={features}
          updateFxtStatus={updateFxtStatus}
          isFxt={isFxt}
          findAndUpdateJobApplication={findAndUpdateJobApplication}
          isMutationLoading={isMutationLoading}
        />
      )}

      <TechEvaluationPlayerModal
        player={player}
        // @TODO(TS migration)
        // @ts-expect-error Is 'playerRef' defined wrong
        // as we expect a legacy ref object here as forced by ReactPlayer lib?
        playerRef={playerRef}
        videoURL={videoURL}
        visible={isPlayerModalOpen}
        onCancel={() => {
          closePlayerModal()
          setVideoURL(null)
        }}
      />
    </>
  )
}

export default TechEvaluationsTable
