import React from 'react'

import { SectionCard } from 'redesign/components'

import { TestAssessmentsTable } from './components/TestAssessmentsTable'

import { useProfileId } from 'redesign/hooks/useProfileId'
import { useTestAssessments } from 'redesign/hooks/useTestAssessments'

const TestAssessmentSection = () => {
  const { profileId } = useProfileId()
  const { data: testAssessments, isLoading: testAssessmentsIsLoading } =
    useTestAssessments({ profileId })

  const isLoading = Boolean(testAssessmentsIsLoading)

  return (
    <SectionCard isLoading={isLoading}>
      <TestAssessmentsTable
        testAssessments={testAssessments}
        profileId={profileId}
      />
    </SectionCard>
  )
}

export default TestAssessmentSection
