import React, { useMemo } from 'react'

import { HistoryOutlined } from '@ant-design/icons'

import { CandidateSkillTooltip } from '../CandidateSkillTooltip'
import { TagsList } from '../TagsList'

import { getGroupedSkills, mapSkillToTagsList } from 'redesign/utils/skillUtils'

import type { CandidateSkillRecord } from 'redesign/types/CandidateSkill'
import type { SkillTag } from 'redesign/types/SkillTag'

import type { ICandidateSkillsProps } from './CandidateSkills.types'

import {
  historyIconStyles,
  skillsFooterStyles,
  skillsHeaderStyles
} from './CandidateSkills.module.css'

const groupDescriptions: Record<string, string> = {
  7: '7+ Years Experience',
  5: '5+ Years Experience',
  3: '3+ Years Experience',
  1: '1+ Years Experience',
  0: '<1 Year Experience',
  20: 'Unknown'
}

const CandidateSkills = ({ candidateSkills }: ICandidateSkillsProps) => {
  const skills: SkillTag[] = useMemo(() =>
    candidateSkills.map((skill: CandidateSkillRecord) => ({
      ...mapSkillToTagsList(skill),
      tooltip: <CandidateSkillTooltip skill={skill} />
    })), [candidateSkills]
  )

  const skillGroups = getGroupedSkills(skills)

  return (
    <div>
      <div className={skillsHeaderStyles}>
        This feature is related to measuring and displaying information about a candidate&apos;s skills and seniority.
      </div>

      {skillGroups.map(group =>
        Boolean(group?.skills?.length) && <TagsList key={group.years} description={groupDescriptions[group.years]} skills={group.skills} />
      )}

      <div className={skillsFooterStyles}>
        {Number(skillGroups.pop()?.skills?.length) > 0 &&
          (<>
            <HistoryOutlined className={historyIconStyles} />
            Skills with unknown years of experience are displayed in order of how often they appear in the candidate profile.
          </>)
        }
      </div>
    </div>
  )
}

export default CandidateSkills
