import React, { useState, useMemo } from 'react'

import { EditOutlined } from '@ant-design/icons'

import { PreferencesForm } from '../PreferencesForm'
import { SectionCardContent } from 'redesign/components'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'
import { MinimumRate } from 'components/MinimumRate'

import type JobType from 'redesign/types/JobType'
import type Skill from 'redesign/types/Skill'

import type { IPreferencesProps } from './Preferences.types'

import { fieldStyles } from './Preferences.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const arrayToString = (array: JobType[] | Skill[] | undefined, key: string) =>
  array?.map(item => item[key]).join(', ') || '-'

const Preferences = ({ profile, onSave }: IPreferencesProps) => {
  const [isEdit, setIsEdit] = useState(false)

  const rateRange = useMemo(() => {
    if (profile?.rateRange?.includes(',')) {
      const [firstValue, secondValue] = profile?.rateRange.split(',')

      // 'All' ranges
      if (parseInt(firstValue, 10) === 0 && !secondValue) {
        return `All`
      }

      // Intermediate rate ranges
      if (secondValue) {
        return `$${firstValue} to $${secondValue}`
      }

      // `100+` range
      return `$${firstValue}+`
    }
  }, [profile])

  if (isEdit) {
    return (
      <PreferencesForm
        initialValues={profile}
        closeForm={() => setIsEdit(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <SectionCardContent
      title="Preferences"
      actions={
        <Button
          data-testid="profilePreferencesSection-editButton"
          icon={<EditOutlined />}
          onClick={() => setIsEdit(true)}
        />
      }
    >
      <Descriptions
        bordered
        column={{ xs: 1, sm: 1, md: 2, xxl: 2, xl: 2, lg: 2 }}
        labelStyle={{ backgroundColor: dynamicBlack }}
        contentStyle={{ backgroundColor: sootyBlack }}
      >
        <DescriptionsItem label="Job types" className={fieldStyles}>
          {arrayToString(profile?.preferredJobTypes, 'title')}
        </DescriptionsItem>

        <DescriptionsItem label="Skill preferences" className={fieldStyles}>
          {arrayToString(profile?.preferredSkills, 'title')}
        </DescriptionsItem>

        <DescriptionsItem label="Minimum rate" className={fieldStyles}>
          <MinimumRate minRate={Number(profile?.minRate)} />
        </DescriptionsItem>
        <DescriptionsItem label="Expected Rate Range" className={fieldStyles}>
          {rateRange}
        </DescriptionsItem>

        <DescriptionsItem
          label="Subscribed to Job Board emails"
          className={fieldStyles}
        >
          {profile?.subscribed ? 'Yes' : 'No'}
        </DescriptionsItem>

        <DescriptionsItem
          label="Subscribed to Job Alerts emails"
          className={fieldStyles}
        >
          {profile?.jobAlertsNotification ? 'Yes' : 'No'}
        </DescriptionsItem>
      </Descriptions>
    </SectionCardContent>
  )
}

export default Preferences
