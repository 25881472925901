import React, { useMemo } from 'react'

import dayjs from 'dayjs'
import { navigate } from 'gatsby'

import { XProfilesSection } from '../XProfilesSection'
import { LastExperience, ProfileLocation } from 'redesign/components'

import { RoleActionsContainer } from './containers/RoleActionsContainer'

import Clipboard from 'components/Clipboard'
import { Col } from 'components/Col'
import { Row } from 'components/Row'

import { useProfileExperiences, useParsedResumeExtractedData } from 'redesign/modules/Profile/hooks'

import { convertMonthsToYears } from 'redesign/utils/numberUtils'

import type Experience from 'redesign/types/Experience'

import type { MainDataProps } from './MainData.types'

import {
  borderWrapperStyles,
  emailStyles,
  leftBorderStyles,
  profileNameStyles,
  responsiveSectionStyles,
  roleCandidateActionsStyles,
  sectionStyles
} from './MainData.module.css'

const MainData = ({
  profile,
  parsedResume,
  setSelectedTab,
  updatedAt,
  createdAt,
  xProfiles,
  xProfileRequestedAt,
  xProfileRequestedBy,
  handleOpenXProfile,
  handleCreateXProfile,
  handleRequestXProfile,
}: MainDataProps) => {
  const { data: experiences } = useProfileExperiences()
  const { id: parsedResumeId, data: parsedResumeData } = parsedResume || {}
  const { data: parsedResumeExtractedData } = useParsedResumeExtractedData({ parsedResume: parsedResumeId })
  const { monthsOfWorkExperience } = parsedResumeExtractedData

  const resumeLocation = parsedResumeData?.ContactInformation.Location
  const positions = parsedResumeData?.EmploymentHistory.Positions
  const lastLegacyExperience = useMemo(
    () =>
      Array.isArray(experiences) && experiences.length > 0
        ? experiences.reduce(
          (latestStartedExperience, experience) =>
            dayjs(experience.start_date).isAfter(
              latestStartedExperience.start_date
            )
              ? experience
              : latestStartedExperience,
            { start_date: '0000-01-01' } as unknown as Experience
        )
        : undefined,
    [experiences]
  )

  const location = profile.country || resumeLocation?.CountryCode
  const lastExperience =
    positions?.[0] ||
    (Boolean(lastLegacyExperience) && {
      Employer: {
        Name: {
          Normalized: lastLegacyExperience?.company,
          Raw: lastLegacyExperience?.company
        }
      },
      JobTitle: {
        Normalized: lastLegacyExperience?.role,
        Raw: lastLegacyExperience?.role
      },
      StartDate: {
        Date: lastLegacyExperience?.start_date
      }
    })

  const rateRange = useMemo(() => {
    if (profile?.rateRange?.includes(',')) {
      const [firstValue, secondValue] = profile?.rateRange.split(',')

      // 'All' ranges
      if (parseInt(firstValue, 10) === 0 && !secondValue) {
        return `All`
      }

      // Intermediate rate ranges
      if (secondValue) {
        return `$${firstValue} to $${secondValue}`
      }

      // `100+` range
      return `$${firstValue}+`
    }
  }, [profile])

  const navigateToExperiencesSection = () => {
    navigate(`?id=${profile.id}#experiences`)
    setSelectedTab('#experiences')
  }

  return (
    <div className={borderWrapperStyles}>
      <div className={leftBorderStyles} />

      <div className={sectionStyles}>
        <h2 className={profileNameStyles} data-testid="full-name">
          {profile.fullName}
        </h2>

        {lastExperience && (
          <LastExperience
            company={
              lastExperience.Employer?.Name?.Normalized ||
              lastExperience.Employer?.Name?.Raw
            }
            onStartDateClick={navigateToExperiencesSection}
            role={
              lastExperience.JobTitle?.Normalized ||
              lastExperience.JobTitle?.Raw
            }
            startDate={lastExperience.StartDate?.Date}
          />
        )}

        <div className={emailStyles} data-testid="profile-email">
          <a href={`mailto:${profile.email}`}>{profile.email}</a>
          <Clipboard text={profile.email} iconVisible={true} isNewProfilePage />
        </div>

        <Row>
          {location !== undefined && (
            <Col xs={24} md={12}>
              <ProfileLocation
                country={profile.country}
                timezoneOffset={profile.timezoneOffset}
                showLabel
              />
            </Col>
          )}
          {rateRange && (
            <Col xs={24} md={12}>
              <span>
                <strong>Expected Rate:</strong> {rateRange}
              </span>
            </Col>
          )}
          {profile.referral && (
            <Col xs={24} md={12}>
              <div>
                <strong>Referred By:</strong> {profile.referral}
              </div>
            </Col>
          )}
          <Col xs={24} md={12}>
            <div>
              <strong>Experience:</strong> {convertMonthsToYears(monthsOfWorkExperience)}
            </div>
          </Col>
        </Row>

        <div className={responsiveSectionStyles}>
          <XProfilesSection
            updatedAt={updatedAt}
            createdAt={createdAt}
            xProfiles={xProfiles}
            xProfileRequestedAt={xProfileRequestedAt}
            xProfileRequestedBy={xProfileRequestedBy}
            handleOpenXProfile={handleOpenXProfile}
            handleCreateXProfile={handleCreateXProfile}
            handleRequestXProfile={handleRequestXProfile}
            parsedResume={parsedResume}
            resumeMetadata={profile.resume}
          />
        </div>

        <div className={roleCandidateActionsStyles}>
          <RoleActionsContainer profile={profile} />
        </div>
      </div>
    </div>
  )
}

export default MainData
