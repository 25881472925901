import React from 'react'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { getDurationFromMonths } from 'redesign/utils/helpers'

import type { ICandidateSkillTooltipProps, SkillLastUsedFields } from './CandidateSkillTooltip.types'

dayjs.extend(relativeTime)

const CandidateSkillTooltip = (props: ICandidateSkillTooltipProps) => {
  const { skill } = props

  const totalText = `Appears ${skill.total} time${skill.total > 1 ? 's' : ''} in the candidate profile`
  const experienceAmount = skill.monthsExperience
    ? getDurationFromMonths(skill.monthsExperience)
    : 'unknown'

  const lastUsedFields: Array<keyof SkillLastUsedFields> = [
    'lastUsed',
    'lastUsedQualified',
    'lastUsedScreened',
    'lastUsedCV',
    'lastUsedExperience',
    'lastUsedLegacy',
  ]
  const lastUsedValues: SkillLastUsedFields = {}

  for (const field of lastUsedFields) {
    lastUsedValues[field] = skill[field]
      ? dayjs(skill[field]).fromNow()
      : 'unknown'
  }

  if (skill.type === 'qualified') {
    return (
      <div>
        Experience: {experienceAmount} <br />
        Qualified: {lastUsedValues.lastUsedQualified} <br />
        Last used: {lastUsedValues.lastUsed}
      </div>
    )
  }

  if (skill.type === 'screened') {
    return (
      <div>
        Experience: {experienceAmount} <br />
        Screened: {lastUsedValues.lastUsedScreened} <br />
        Last used: {lastUsedValues.lastUsed}
      </div>
    )
  }

  if (skill.monthsExperience) {
    return (
      <div>
        Experience: {experienceAmount} <br />
        Last used: {lastUsedValues.lastUsed}
      </div>
    )
  }

  return (
    <div>
      {totalText}
    </div>
  )
}

export default CandidateSkillTooltip
