// extracted by mini-css-extract-plugin
export var amazonParrot = "#94E66B";
export var burntCoffee = "#2B2112";
export var buttonIconFullStyles = "MasterDuplicateIndicator-module--buttonIconFullStyles--84661";
export var buttonIconStylesDuplicate = "MasterDuplicateIndicator-module--buttonIconStylesDuplicate--ee973";
export var buttonIconStylesMaster = "MasterDuplicateIndicator-module--buttonIconStylesMaster--a75e2";
export var buttonLabelStylesDuplicate = "MasterDuplicateIndicator-module--buttonLabelStylesDuplicate--80d5c";
export var buttonLabelStylesMaster = "MasterDuplicateIndicator-module--buttonLabelStylesMaster--f4e9d";
export var buttonStyles = "MasterDuplicateIndicator-module--buttonStyles--80dde";
export var buttonStylesDuplicate = "MasterDuplicateIndicator-module--buttonStylesDuplicate--0ecf3";
export var buttonStylesMaster = "MasterDuplicateIndicator-module--buttonStylesMaster--dd6dc";
export var chestnutGreen = "#25461A";
export var colors = "'theme/colors.module.css'";
export var fullIndicatorStyles = "MasterDuplicateIndicator-module--fullIndicatorStyles--c4c45";
export var fullIndicatorStylesDuplicate = "MasterDuplicateIndicator-module--fullIndicatorStylesDuplicate--cb4ee";
export var fullIndicatorStylesMaster = "MasterDuplicateIndicator-module--fullIndicatorStylesMaster--86f46";
export var sunSalutation = "#E6C46B";