import React from 'react'

import { SectionCard } from 'redesign/components'

import { useProfile } from '../../hooks/useProfile'
import { useProfileExperiences } from '../../hooks/useProfileExperiences'

import { useCandidateSkills } from 'redesign/hooks/useCandidateSkills'
import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useIndustries } from 'redesign/hooks/useIndustries'
import { useProfileId } from 'redesign/hooks/useProfileId'

import { ExperiencesTable } from './components'
import { ProfileResumeSection } from './containers'

const Experiences = () => {
  const { profileId } = useProfileId()
  const { data: profile, isLoading: profileIsLoading } = useProfile()

  const {
    data: experiences,
    createExperience,
    updateExperience,
    deleteExperience,
    isLoading: experiencesIsLoading
  } = useProfileExperiences()

  const { data: industries, isLoading: industriesIsLoading } = useIndustries()

  const { data: candidateSkills, isLoading: candidateSkillsIsLoading } =
    useCandidateSkills({ profileId: Number(profileId) })

  const { data: features } = useFeatureFlags()

  const isLoading = Boolean(
    experiencesIsLoading ||
      profileIsLoading ||
      industriesIsLoading ||
      candidateSkillsIsLoading
  )

  return (
    <>
      <SectionCard isLoading={isLoading}>
        <ProfileResumeSection
          profileId={profileId}
          resumeFileMeta={profile?.resume}
          candidateSkills={candidateSkills}
          features={features}
        />
      </SectionCard>
      <SectionCard isLoading={isLoading}>
        <ExperiencesTable
          profileId={profileId}
          experiences={experiences}
          industries={industries}
          createExperience={createExperience}
          updateExperience={updateExperience}
          deleteExperience={deleteExperience}
        />
      </SectionCard>
    </>
  )
}

export default Experiences
