import React from 'react'

import dayjs from 'dayjs'

import {
  PROFILE_FIELDS
} from 'utils/constants'

import type { ActivityLog } from 'redesign/types/ActivityLog'

export type MessageProps = {
  log: ActivityLog
}

export const CreateProfileMessage = ({ log }: MessageProps) => (
  <span>Profile created by {log.user.username}.</span>
)

export const CandidateCreateProfileMessage = () => (
  <span>Profile created by candidate.</span>
)

export const MarkDuplicateMessage = ({ log }: MessageProps) => (
  <span>Marked as duplicate by {log.user.username}.</span>
)

export const CreateNoteMessage = ({ log }: MessageProps) => (
  <span>Note added by {log.user.username}.</span>
)

export const UpdateNoteMessage = ({ log }: MessageProps) => (
  <span>Note updated by {log.user.username}.</span>
)

export const CreateScreeningMessage = ({ log }: MessageProps) => {
  if (log.roleCandidate) {
    return (
      <span>
        Screened by {log.user.username} for{' '}
        <a
          target="_blank"
          href={`/role?id=${log.roleCandidate.partnerRole.id}`}
          rel="noreferrer"
        >
          {log.roleCandidate.partnerRole.name}
        </a>
        .
      </span>
    )
  }

  return <span>Screened by {log.user.username}.</span>
}

export const UpdateScreeningMessage = ({ log }: MessageProps) => {
  if (log.roleCandidate) {
    return (
      <span>
        Screening updated by {log.user.username} for{' '}
        <a
          target="_blank"
          href={`/role?id=${log.roleCandidate.partnerRole.id}`}
          rel="noreferrer"
        >
          {log.roleCandidate.partnerRole.name}
        </a>
        .
      </span>
    )
  }

  return <span>Screening updated by {log.user.username}.</span>
}

export const CreateTechEvalMessage = ({ log }: MessageProps) => {
  if (log.roleCandidate) {
    return (
      <span>
        Technically Evaluated by {log.user.username} for{' '}
        <a
          target="_blank"
          href={`/role?id=${log.roleCandidate.partnerRole.id}`}
          rel="noreferrer"
        >
          {log.roleCandidate.partnerRole.name}
        </a>
        .
      </span>
    )
  }

  return <span>Technically Evaluated by {log.user.username}.</span>
}

export const UpdateTechEvalMessage = ({ log }: MessageProps) => {
  if (log.roleCandidate) {
    return (
      <span>
        Technical Evaluation updated by {log.user.username} for{' '}
        <a
          target="_blank"
          href={`/role?id=${log.roleCandidate.partnerRole.id}`}
          rel="noreferrer"
        >
          {log.roleCandidate.partnerRole.name}
        </a>
        .
      </span>
    )
  }

  return <span>Technical Evaluation updated by {log.user.username}.</span>
}

export const ShortlistCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Shortlisted by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const ContactCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Contacted by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const ReserveCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Reserved by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const RequestXProfileCandidateMessage = ({ log }: MessageProps) => (
  <span>
    X-Profile requested by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const PresentToBizDevCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Presented to BizDev by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const PitchCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Pitched by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const ClientInterviewCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Client Interviewed by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const AcceptCandidateMessage = ({ log }: MessageProps) => (
  <span>
    Accepted by {log.user.username} for{' '}
    <a
      target="_blank"
      href={`/role?id=${log.roleCandidate.partnerRole.id}`}
      rel="noreferrer"
    >
      {log.roleCandidate.partnerRole.name}
    </a>
    .
  </span>
)

export const BookCandidateMessage = ({ log }: MessageProps) => {
  const startDateEditedMessagePart = log.payload.bookedStartAt && !log.payload.bookedEndAt ? 'start date' : ''
  const endDateEditedMessagePart = log.payload.bookedEndAt && !log.payload.bookedStartAt ? 'end date' : ''
  const bothDatesEditedMessagePart = log.payload.bookedStartAt && log.payload.bookedEndAt ? 'start date and end date' : ''

  return (
    <span>
    Booking {startDateEditedMessagePart}{bothDatesEditedMessagePart}{endDateEditedMessagePart} added by {log.user.username} for{' '}
      <a
        target="_blank"
        href={`/role?id=${log.roleCandidate.partnerRole.id}`}
        rel="noreferrer"
      >
        {log.roleCandidate.partnerRole.name}
      </a>
    .
    </span>
  )
}

export const UpdateCandidateBookingMessage = ({ log }: MessageProps) => {
  const startDateEditedMessagePart = log.payload.bookedStartAt && !log.payload.bookedEndAt ? 'start date' : ''
  const endDateEditedMessagePart = log.payload.bookedEndAt && !log.payload.bookedStartAt ? 'end date' : ''
  const bothDatesEditedMessagePart = log.payload.bookedStartAt && log.payload.bookedEndAt ? 'start date and end date' : ''

  return (
    <span>
      Booking {startDateEditedMessagePart}{bothDatesEditedMessagePart}{endDateEditedMessagePart} edited by {log.user.username} for{' '}
      <a
        target="_blank"
        href={`/role?id=${log.roleCandidate.partnerRole.id}`}
        rel="noreferrer"
      >
        {log.roleCandidate.partnerRole.name}
      </a>
      .
    </span>
  )
}

export const CloseCandidateMessage = ({ log }: MessageProps) => (
  <>
    <span>
      Candidate closed by {log.user.username} for{' '}
      <a
        target="_blank"
        href={`/role?id=${log.roleCandidate.partnerRole.id}`}
        rel="noreferrer"
      >
        {log.roleCandidate.partnerRole.name}
      </a>
      .
      <span style={{ display: 'block' }}>
        Reason: &quot;
        {log.roleCandidate.roleCandidateCloseReason?.summary}
        &quot;.
      </span>
    </span>
  </>
)

export const TransferCandidateMessage = ({ log }: MessageProps) => {
  if (!log.roleCandidate.transferredTo) {
    return null
  }

  return (
    <span>
      Transferred by {log.user.username} from{' '}
      <a
        target="_blank"
        href={`/role?id=${log.roleCandidate.partnerRole.id}`}
        rel="noreferrer"
      >
        {log.roleCandidate.partnerRole.name}
      </a>{' '}
      to{' '}
      <a
        target="_blank"
        href={`/role?id=${log.roleCandidate.transferredTo.partnerRole.id}`}
        rel="noreferrer"
      >
        {log.roleCandidate.transferredTo.partnerRole.name}.
      </a>
    </span>
  )
}

export const CreateJobApplicationMessage = ({ log }: MessageProps) => (
  <span>
    Candidate applied for{' '}
    <a
      target="_blank"
      href={`/job?id=${log.jobApplication.job.id}`}
      rel="noreferrer"
    >
      {log.jobApplication.job.name}
    </a>
    .
  </span>
)

export const ReApplyJobApplicationMessage = ({ log }: MessageProps) => (
  <span>
    Candidate reapplied for{' '}
    <a
      target="_blank"
      href={`/job?id=${log.jobApplication.job.id}`}
      rel="noreferrer"
    >
      {log.jobApplication.job.name}
    </a>
    .
  </span>
)

export const WithdrewJobApplicationMessage = ({ log }: MessageProps) => (
  <span>
    Candidate withdrew application for{' '}
    <a
      target="_blank"
      href={`/job?id=${log.jobApplication.job.id}`}
      rel="noreferrer"
    >
      {log.jobApplication.job.name}
    </a>
    .
  </span>
)

export const ExpireJobApplicationMessage = ({ log }: MessageProps) => (
  <span>
    Candidate application expired for{' '}
    <a
      target="_blank"
      href={`/job?id=${log.jobApplication.job.id}`}
      rel="noreferrer"
    >
      {log.jobApplication.job.name}
    </a>
    .
  </span>
)

export const CreateRunwayMessage = ({ log }: MessageProps) => {
  if (log.payload.endAt) {
    return (
      <span>
        New runway created by {log.user.username} from
        {dayjs(log.payload.startAt).format('YYYY-MM-DD')} to
        {dayjs(log.payload.endAt).format('YYYY-MM-DD')}.
      </span>
    )
  }

  return (
    <span>
      New runway created by {log.user.username} for
      {dayjs(log.payload.startAt).format('YYYY-MM-DD')}.
    </span>
  )
}

export const UpdateRunwayMessage = ({ log }: MessageProps) => {
  if (log.payload.endAt) {
    return (
      <span>
        Runway extended by {log.user.username} from
        {dayjs(log.payload.startAt).format('YYYY-MM-DD')} to
        {dayjs(log.payload.endAt).format('YYYY-MM-DD')}.
      </span>
    )
  }

  return (
    <span>
      Runway extended by {log.user.username} for
      {dayjs(log.payload.startAt).format('YYYY-MM-DD')}.
    </span>
  )
}

export const DeleteRunwaysMessage = ({ log }: MessageProps) => (
  <span>Runway deleted by {log.user.username}.</span>
)

export const ExpireRunwaysMessage = () => <span>Runway ended.</span>

export const CreateXProfileMessage = ({ log }: MessageProps) => (
  <span>New X-Profile created by {log.user.username}.</span>
)

export const SubmitVoteMessage = ({ log }: MessageProps) => {
  if (log.payload.vote) {
    return <span>Upvoted by {log.user.username}.</span>
  }

  return <span>Downvoted by {log.user.username}.</span>
}

export const DeleteVoteMessage = ({ log }: MessageProps) => (
  <span>Vote deleted by {log.user.username}.</span>
)

export const SnoozeProfileMessage = ({ log }: MessageProps) => (
  <span>
    Snoozed by {log.user.username} ending{' '}
    {dayjs(log.payload.expiresAt).format('YYYY-MM-DD')}.
  </span>
)

export const UnSnoozeProfileMessage = ({ log }: MessageProps) => (
  <span>Unsnoozed by {log.user.username}.</span>
)

export const UploadFileMessage = ({ log }: MessageProps) => {
  if (log.payload.field === 'resume') {
    return (
      <span>
        CV updated by{' '}
        {log.user.role.type === 'authenticated'
          ? 'candidate'
          : log.user.username}
        .
      </span>
    )
  } else if (log.payload.field === 'image') {
    return <span>Profile image updated.</span>
  }

  return null
}

export const UpdateFXTMessage = ({ log }: MessageProps) => {
  if (log.payload.action === 'added') {
    return (
      <span>
        Marked as Future X-Teamer by {log.user.username} for the following
        reason: &quot;{log.payload.context}&quot;.
      </span>
    )
  }

  return (
    <span>
      Removed as Future X-Teamer by {log.user.username} for the following
      reason: &quot;{log.payload.context}&quot;.
    </span>
  )
}

export const UpdateProfileLogMessage = ({ log }: MessageProps) => {
  delete log.previousValues?.location
  delete log.previousValues?.updatedAtByCandidate
  const updatedFields = log.previousValues
    ? (Object.keys(log.previousValues) as Array<
        keyof typeof log.previousValues
      >)
    : []

  const username =
    log.type === 'CANDIDATE_UPDATE_PROFILE' ? 'candidate' : log.user.username

  return (
    <>
      {updatedFields.map(field => {
        if (field === 'author') {
          return null
        }

        if (field === 'preferredSkills' || field === 'preferredJobTypes') {
          return (
            <span key={`${log.id}_${field}`} style={{ display: 'block' }}>
              {PROFILE_FIELDS[field]} updated by {username}.
            </span>
          )
        }

        return (
          <span key={`${log.id}_${field}`} style={{ display: 'block' }}>
            {PROFILE_FIELDS[field]} updated by {username} from &quot;
            {String(log.previousValues[field]) ?? 'empty'}&quot; to &quot;
            {String(log.payload[field])}
            &quot;.
          </span>
        )
      })}
    </>
  )
}

export const DeleteNoteMessage = ({ log }: MessageProps) => (
  <span>
    Note originally posted by {log.previousValues?.author} on{' '}
    {dayjs(log.previousValues?.created_at).format('YYYY-MM-DD')} deleted by{' '}
    {log.user.username}.
  </span>
)

export const DeleteScreeningMessage = ({ log }: MessageProps) => (
  <span>
    Screening originally posted by {log.previousValues?.author} on{' '}
    {dayjs(log.previousValues?.created_at).format('YYYY-MM-DD')} deleted by{' '}
    {log.user.username}.
  </span>
)

export const DeleteTechEvalMessage = ({ log }: MessageProps) => (
  <span>
    Technical Evaluation originally posted by {log.previousValues?.author} on{' '}
    {dayjs(log.previousValues?.created_at).format('YYYY-MM-DD')} deleted by{' '}
    {log.user.username}.
  </span>
)

export const RequestXProfileMessage = ({ log }: MessageProps) => (
  <span>
    X-Profile requested by {log.user.username} at {dayjs(log.payload.xProfileRequestedAt).format('YYYY-MM-DD')}.
  </span>
)
