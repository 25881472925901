import type { MouseEvent } from 'react'
import React from 'react'

import { Button } from 'components/Button'
import { message } from 'components/Message'

import type { ISlackNotificationButtonProps } from './SlackNotificationButton.types'

const SlackNotificationButton = ({
  runway,
  activeRunways,
  xhqLink,
  onClick
}: ISlackNotificationButtonProps) => {
  const isButtonEnabled = Boolean(
    runway.active && runway.endingDate && xhqLink.slackId
  )

  const sendSlackNotification = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (activeRunways && activeRunways.length > 1) {
      return message.error(
        'The notification cannot be sent since multiple runways are active'
      )
    }

    onClick({
      runwayId: runway.id,
      xhqlinkId: xhqLink.id
    })
  }

  return (
    <Button onClick={sendSlackNotification} disabled={!isButtonEnabled}>
      Send Notification
    </Button>
  )
}

export default SlackNotificationButton
