import type { MessageProps } from './messages'
import {
  BookCandidateMessage,
  UpdateCandidateBookingMessage,
  CandidateCreateProfileMessage,
  CloseCandidateMessage,
  ContactCandidateMessage,
  CreateJobApplicationMessage,
  CreateNoteMessage,
  CreateProfileMessage,
  CreateRunwayMessage,
  CreateScreeningMessage,
  CreateTechEvalMessage,
  CreateXProfileMessage,
  DeleteRunwaysMessage,
  DeleteVoteMessage,
  ExpireJobApplicationMessage,
  ExpireRunwaysMessage,
  MarkDuplicateMessage,
  PitchCandidateMessage,
  PresentToBizDevCandidateMessage,
  ReApplyJobApplicationMessage,
  RequestXProfileCandidateMessage,
  ReserveCandidateMessage,
  ShortlistCandidateMessage,
  SnoozeProfileMessage,
  SubmitVoteMessage,
  TransferCandidateMessage,
  UnSnoozeProfileMessage,
  UpdateFXTMessage,
  UpdateNoteMessage,
  UpdateProfileLogMessage,
  UpdateRunwayMessage,
  UpdateScreeningMessage,
  UpdateTechEvalMessage,
  UploadFileMessage,
  WithdrewJobApplicationMessage,
  DeleteNoteMessage,
  DeleteScreeningMessage,
  DeleteTechEvalMessage,
  ClientInterviewCandidateMessage,
  AcceptCandidateMessage,
  RequestXProfileMessage,
} from './messages'

const ACTIVITY_LOGS_MESSAGES: Record<
  string,
  React.ComponentType<MessageProps>
> = {
  CANDIDATE_CREATE_PROFILE: CandidateCreateProfileMessage,
  CREATE_PROFILE: CreateProfileMessage,
  CANDIDATE_UPDATE_PROFILE: UpdateProfileLogMessage,
  UPDATE_PROFILE: UpdateProfileLogMessage,
  MARK_DUPLICATE: MarkDuplicateMessage,
  UPDATE_FXT: UpdateFXTMessage,
  CREATE_NOTE: CreateNoteMessage,
  UPDATE_NOTE: UpdateNoteMessage,
  DELETE_NOTE: DeleteNoteMessage,
  CREATE_SCREENING: CreateScreeningMessage,
  UPDATE_SCREENING: UpdateScreeningMessage,
  DELETE_SCREENING: DeleteScreeningMessage,
  CREATE_TECH_EVAL: CreateTechEvalMessage,
  UPDATE_TECH_EVAL: UpdateTechEvalMessage,
  DELETE_TECH_EVAL: DeleteTechEvalMessage,
  SHORTLIST_CANDIDATE: ShortlistCandidateMessage,
  CONTACT_CANDIDATE: ContactCandidateMessage,
  RESERVE_CANDIDATE: ReserveCandidateMessage,
  REQUEST_X_PROFILE_CANDIDATE: RequestXProfileCandidateMessage,
  PRESENT_TO_BIZDEV_CANDIDATE: PresentToBizDevCandidateMessage,
  PITCH_CANDIDATE: PitchCandidateMessage,
  CLIENT_INTERVIEW_CANDIDATE: ClientInterviewCandidateMessage,
  ACCEPT_CANDIDATE: AcceptCandidateMessage,
  BOOK_CANDIDATE: BookCandidateMessage,
  UPDATE_CANDIDATE_BOOKING: UpdateCandidateBookingMessage,
  CLOSE_CANDIDATE: CloseCandidateMessage,
  TRANSFER_CANDIDATE: TransferCandidateMessage,
  CREATE_JOB_APPLICATION: CreateJobApplicationMessage,
  REAPPLY_JOB_APPLICATION: ReApplyJobApplicationMessage,
  WITHDREW_JOB_APPLICATION: WithdrewJobApplicationMessage,
  EXPIRE_JOB_APPLICATION: ExpireJobApplicationMessage,
  CREATE_RUNWAY: CreateRunwayMessage,
  UPDATE_RUNWAY: UpdateRunwayMessage,
  DELETE_RUNWAY: DeleteRunwaysMessage,
  EXPIRE_RUNWAY: ExpireRunwaysMessage,
  CREATE_X_PROFILE: CreateXProfileMessage,
  SUBMIT_VOTE: SubmitVoteMessage,
  DELETE_VOTE: DeleteVoteMessage,
  SNOOZE_PROFILE: SnoozeProfileMessage,
  UNSNOOZE_PROFILE: UnSnoozeProfileMessage,
  UPLOAD_FILE: UploadFileMessage,
  REQUEST_X_PROFILE_PROFILE: RequestXProfileMessage,
}

export default ACTIVITY_LOGS_MESSAGES
