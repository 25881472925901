import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { useForm } from '../../../../../../../hooks/useForm'
import { App } from 'redesign/components'

import { IndustriesField } from 'redesign/containers/FormFields/IndustriesField'
import { SkillsField } from 'redesign/containers/FormFields/SkillsField'

import { Button } from 'components/Button'
import { DatePicker } from 'components/DatePicker'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'

import { arrayToSelectOptions } from 'utils/helpers'

import { formatNegativeValues } from 'redesign/utils/helpers'

import type Skill from 'redesign/types/Skill'

import type { IExperiencesFormProps } from './ExperiencesForm.types'

const ExperiencesForm = ({
  profileId,
  initialValues,
  closeForm,
  industries,
  createExperience,
  updateExperience
}: IExperiencesFormProps) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()
  const industryOptions: Array<{ value: string; label: string }> =
    arrayToSelectOptions(industries)

  const onClickSave = async () => {
    try {
      // Throw exception if any required field is empty
      await form.validateFields()
    } catch (e) {
      return
    }

    const values = { profile: profileId, ...form.getFieldsValue() }

    if (initialValues) {
      const industry =
        typeof values.industry === 'object'
          ? values.industry.value
          : values.industry
      const skills = formatNegativeValues<Skill>(values.skills, initialValues.skills)
      updateExperience &&
        updateExperience(
          {
            experienceId: initialValues.id,
            values: { ...values, skills, industry }
          },
          { onSuccess: closeForm }
        )
    } else {
      createExperience && createExperience(values, { onSuccess: closeForm })
    }
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  const formattedInitialValues = {
    ...initialValues,
    start_date: dayjs(initialValues?.start_date),
    end_date: initialValues?.end_date ? dayjs(initialValues?.end_date) : null,
    industry: industryOptions.find(
      // @TODO(TS migration)
      // @ts-expect-error check why we're comparing string value and an Industry object?
      industry => industry.value === initialValues?.industry
    ),
    skills: arrayToSelectOptions(initialValues?.skills)
  }

  return (
    <Form form={form} layout="vertical" initialValues={formattedInitialValues}>
      <FormItem name="role" label="Role" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem name="company" label="Company" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem name="industry" label="Industry">
        <IndustriesField />
      </FormItem>

      <FormItem
        name="start_date"
        label="Start Date"
        rules={[{ required: true }]}
      >
        <DatePicker picker="month" />
      </FormItem>

      <FormItem name="end_date" label="End Date">
        <DatePicker picker="month" />
      </FormItem>

      <FormItem
        name="highlights"
        label="Highlights"
        rules={[{ required: true }]}
      >
        <Input.TextArea autoSize />
      </FormItem>

      <FormItem name="skills" label="Skills">
        <SkillsField />
      </FormItem>

      <Button type="primary" onClick={onClickSave}>
        Save
      </Button>
      <Button onClick={onClickCancel}>Cancel</Button>
    </Form>
  )
}

export default ExperiencesForm
