import React from 'react'

import { Col } from '../../Col'
import { Row } from '../../Row'

import DetailsItem from './DetailsItem'
import DetailsSubItem from './DetailsSubItem'
import SkillsList from './SkillsList'

const TopSkills = ({ taxonomies }) => (
  <DetailsItem>
    {taxonomies?.map(taxonomy => (
      <DetailsSubItem
        key={taxonomy.id}
        title={
          <Row>
            <Col span="16">{taxonomy.name}</Col>
            <Col span="8"> {taxonomy.percentOfOverall}% of Overall</Col>
          </Row>
        }
      >
        {taxonomy.skills && (
          <SkillsList skills={taxonomy.skills} listId={taxonomy.id} />
        )}
      </DetailsSubItem>
    ))}
  </DetailsItem>
)

export default TopSkills
