import React, { useState } from 'react'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'

import type { FxtAction, ProfileFxtStatus } from 'redesign/types/ProfileFxtStatus'

import type { FxtModalTexts, IFxtStatusModalProps } from './FxtStatusModal.types'

import {
  fieldsContainerStyles,
  fxtModalStyles,
  modalContentStyles,
  textAreaStyles
} from './FxtStatusModal.module.css'

const markingModalTexts: FxtModalTexts = {
  title: 'Mark as Future X-Teamer',
  message: 'Are you sure you want to mark this candidate as a Future X-Teamer?',
  placeholder: 'Explain why you believe this person is a Future X-Teamer.',
  action: 'added'
}

const removalModalTexts: FxtModalTexts = {
  title: 'Remove Future X-Teamer',
  message: 'Are you sure you want to remove the "Future X-Teamer" status of this candidate?',
  placeholder: 'The candidate had a poor attitude during the interview and communicated in a rude manner with one of our recruiters.',
  action: 'removed'
}

const FxtStatusModal = ({
  visible,
  closeModal,
  updateFxtStatus,
  isFxt,
  profileId
}: IFxtStatusModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [context, setContext] = useState<string>('')

  const modalTexts: FxtModalTexts = isFxt ? removalModalTexts : markingModalTexts

  const handleConfirm = () => {
    setIsLoading(true)
    const values: ProfileFxtStatus = {
      profile: profileId,
      method: 'profile_menu',
      action: modalTexts.action as FxtAction,
      context
    }
    updateFxtStatus(values, {
      onSuccess: () => {
        setIsLoading(false)
        closeModal()
      },
      onError: () => setIsLoading(false)
    })
  }

  return (
    <Modal
      open={visible}
      closable
      onCancel={closeModal}
      width={520}
      title={modalTexts.title}
      className={fxtModalStyles}
      footer={[
        <Button key="cancel-btn" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="confirm-btn"
          loading={isLoading}
          onClick={handleConfirm}
          disabled={context.trim() === ''}
        >
          Confirm
        </Button>
      ]}
    >
      <div className={modalContentStyles}>
        <div className={fieldsContainerStyles}>
          <span>
            {modalTexts.message}
          </span>
          <Input.TextArea
            rows={3}
            value={context}
            className={textAreaStyles}
            placeholder={modalTexts.placeholder}
            onChange={e => setContext(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default FxtStatusModal
