import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { Button } from 'components/Button'
import type { DatePickerProps } from 'components/DatePicker'
import { DatePicker } from 'components/DatePicker'
import { Modal } from 'components/Modal'
import { Select } from 'components/Select'

import type { CreateSnoozeParams } from 'redesign/modules/Profile/hooks/useProfileSnooze/useProfileSnooze.types'

import { SNOOZE_ENUM_OPTIONS } from 'utils/constants'

import type { SnoozeModalProps, SnoozeReason } from './SnoozeModal.types'

import {
  errorMessageStyles,
  fieldDropdownStyles,
  fieldsContainerStyles,
  fieldStyles,
  modalContentStyles,
  modalIconStyles,
  modalTitleStyles,
  errorStyles,
} from './SnoozeModal.module.css'

const SnoozeModal = ({
  visible,
  closeModal,
  createProfileSnooze,
  isLoading,
  error
}: SnoozeModalProps) => {
  const [fieldError, setFieldError] = useState<string | null>(null)

  // @TODO(TS migration): instead of type casting which is a bad practice and unsafe typically
  // we should instead convert 'src/config/constants.js' to TS format
  // and type SNOOZE_ENUM_OPTIONS const appropriately so that a 'value' is of type Reason.
  const [snoozeReason, setSnoozeReason] = useState<SnoozeReason>(SNOOZE_ENUM_OPTIONS[0] as unknown as SnoozeReason)
  const [snoozeExpiresAt, setSnoozeExpiresAt] = useState(dayjs().add(3, 'months'))

  const handleSelectChange = (_: number, option: unknown) => {
    setSnoozeReason(option as SnoozeReason)
  }

  const handleDateChange: DatePickerProps['onChange'] = date => {
    if (!date?.isValid()) {
      setFieldError('Invalid Date')
    } else {
      setFieldError(null)
      setSnoozeExpiresAt(date)
    }
  }

  const handleModalConfirm = () => {
    const values: CreateSnoozeParams = {
      reason: snoozeReason.value,
      expiresAt: snoozeExpiresAt.format('YYYY-MM-DD')
    }

    createProfileSnooze(values, {
      onSuccess: closeModal
    })
  }

  const isButtonDisabled = Boolean(!snoozeExpiresAt || fieldError || error)

  return (
    <Modal
      open={visible}
      footer={[
        <Button key="cancel-btn" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="confirm-btn"
          loading={isLoading}
          onClick={handleModalConfirm}
          disabled={isButtonDisabled}
        >
          Confirm
        </Button>
      ]}
    >
      <div className={modalContentStyles}>
        <ExclamationCircleOutlined className={modalIconStyles} />
        <div className={fieldsContainerStyles}>
          <span className={modalTitleStyles}>Snooze</span>
          <span>Why do you want to snooze this candidate?</span>
          <Select
            data-testid="snooze-reason-select"
            className={fieldStyles}
            dropdownClassName={fieldDropdownStyles}
            value={snoozeReason}
            options={SNOOZE_ENUM_OPTIONS}
            onChange={handleSelectChange}
          />
          <br />
          <span>Snooze until the date:</span>
          <DatePicker
            value={snoozeExpiresAt}
            className={`${fieldStyles} ${fieldError ? errorStyles : ''}`}
            dropdownClassName={fieldDropdownStyles}
            onChange={handleDateChange}
          />
          {fieldError
            ? (
              <span className={errorMessageStyles}>{fieldError}</span>
            )
            : null}
          {error ? <span className={errorMessageStyles}>{error}</span> : null}
        </div>
      </div>
    </Modal>
  )
}

export default SnoozeModal
