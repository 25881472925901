import React, { useState } from 'react'

import { Select, SelectOption } from 'components/Select'

import { isShortlistedRole } from 'redesign/utils/partnerRoleUtils/partnerRoleUtils'

import type { SelectRoleProps } from './SelectRole.types'

import { selectStyles, optionSkillsStyles } from './SelectRole.module.css'

const SelectRole = ({
  partnerRoleOptions,
  onChange,
  roleCandidates
}: SelectRoleProps) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const filteredOptions = partnerRoleOptions.filter(role => {
    const formattedValue = searchValue
      ?.toLowerCase()
      .replaceAll(':', '')
      .replaceAll(' ', '.*')
    const pattern = new RegExp(`(?=.*?${formattedValue})`)
    return role.label.toLowerCase().match(pattern)
  })

  const onSearch = (value: string) => {
    setSearchValue(value)
  }

  return (
    <Select
      className={selectStyles}
      showSearch={true}
      suffixIcon={null}
      filterOption={false}
      placeholder="Search a role"
      onSearch={onSearch}
      onChange={onChange}
      data-testid="select-partner-role"
      optionLabelProp="label"
    >
      {filteredOptions.map(({ value, label, skills }) => (
        <SelectOption
          key={value}
          value={value}
          data-testid="select-option"
          label={label}
        >
          <small className={optionSkillsStyles}>{skills}</small>
          <div
            style={{
              color: isShortlistedRole({
                roleId: value,
                roleCandidates
              })
                ? 'cyan'
                : ''
            }}
          >
            {label}
          </div>
        </SelectOption>
      ))}
    </Select>
  )
}

export default SelectRole
