import { useMutation } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { createXProfileEntry } from 'services/profile'

import { useCandidateSkills } from 'redesign/hooks/useCandidateSkills'
import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileId } from 'redesign/hooks/useProfileId'
import { useXProfiles } from 'redesign/hooks/useXProfiles'

import type {
  CreateXProfilePayload,
  PrepareXProfilePayloadProps,
  XProfile,
  XProfileSocial,
  XProfileWorkExperience
} from 'redesign/types/XProfile'

import type { IUseProfileExpertsReturn } from './useProfileXProfiles.types'
import { getTopSkills } from './utils'

const useProfileXProfiles = (): IUseProfileExpertsReturn => {
  const { data: featureFlags } = useFeatureFlags()
  const enableNewXProfile = Boolean(featureFlags?.RELEASE_NEW_XPROFILE)

  const { profileId } = useProfileId()
  const { data, isLoading } = useXProfiles({
    profileId,
    enabled: Boolean(profileId)
  })
  const { data: candidateSkills } = useCandidateSkills({
    profileId: Number(profileId)
  })

  const { data: roleCandidates } = usePartnerRoleCandidates({
    enabled: Boolean(profileId),
    params: { profile: profileId }
  })

  const createXProfileMutation = useMutation<
    AxiosResponse<XProfile>,
    AxiosError,
    CreateXProfilePayload
  >(createXProfileEntry)

  const createXProfile = (payload: CreateXProfilePayload) =>
    createXProfileMutation.mutateAsync(payload)

  const prepareXProfilePayload = ({
    profile,
    parsedResume
  }: PrepareXProfilePayloadProps): CreateXProfilePayload => {
    const {
      id,
      fullName,
      about,
      githubAccount,
      linkedinAccount,
      stackoverflowAccount
    } = profile

    const socialAccounts: XProfileSocial[] = [
      ...(githubAccount
        ? [
            {
              url: githubAccount,
              type: 'github'
            } as XProfileSocial
        ]
        : []),
      ...(linkedinAccount
        ? [
            {
              url: linkedinAccount,
              type: 'linkedin'
            } as XProfileSocial
        ]
        : []),
      ...(stackoverflowAccount
        ? [
            {
              url: stackoverflowAccount,
              type: 'stackoverflow'
            } as XProfileSocial
        ]
        : [])
    ]

    // Experiences
    const positions = parsedResume?.EmploymentHistory.Positions
    const lastExperience = positions?.[0]

    const workExperiences: XProfileWorkExperience[] = (positions || []).map(
      position => ({
        highlights: position.Description,
        company:
            position.Employer?.Name?.Normalized ?? position.Employer?.Name?.Raw,
        role: position.JobTitle?.Normalized ?? position.JobTitle?.Raw
      })
    )

    const topSkills = getTopSkills({ roleCandidates, candidateSkills })
    const photoUrl = profile.image?.url

    return {
      name: fullName,
      profile_id: id,
      blurb:
        lastExperience?.JobTitle?.Normalized ?? lastExperience?.JobTitle?.Raw,
      about,
      social: socialAccounts,
      workExperiences,
      ...(enableNewXProfile ? { topSkills } : {}),
      ...(enableNewXProfile ? { photoUrl } : {})
    }
  }

  return {
    data,
    createXProfile,
    prepareXProfilePayload,
    isLoading
  }
}
export default useProfileXProfiles
