import React from 'react'

import { Tag } from '../Tag'

import { applicationStatus } from './constants'
import type { JobStatusCellProps } from './JobStatusCell.types'

export default function JobStatusCell ({ record }: JobStatusCellProps) {
  const status = applicationStatus(record).find(({ condition }) => condition)

  return <Tag color={status?.color} data-testid="job-status-cell">{status?.text}</Tag>
}
