import React from 'react'

import { Button } from 'components/Button'
import { ShortlistIcon } from 'components/Svg'
import { Tooltip } from 'components/Tooltip'

import type { ShortlistButtonProps } from './ShortlistButton.types'

import { buttonStyles, iconStyles } from './ShortlistButton.module.css'

const ShortlistButton = ({
  onClick,
  disabled,
  loading,
  isDuplicateProfile
}: ShortlistButtonProps) =>
  isDuplicateProfile
    ? (
      <Tooltip title="Unable to perform action on a duplicate profile. Please use the master profile.">
        <Button
          type="primary"
          disabled
          className={buttonStyles}
          icon={
            <div className={iconStyles}>
              <ShortlistIcon />
            </div>
          }
        >
        Shortlist
        </Button>
      </Tooltip>
    )
    : (
      <Button
        onClick={onClick}
        type="primary"
        loading={loading}
        disabled={disabled}
        className={buttonStyles}
        icon={
          <div className={iconStyles}>
            <ShortlistIcon />
          </div>
        }
      >
      Shortlist
      </Button>
    )

export default ShortlistButton
