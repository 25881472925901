import { useMemo } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { first } from 'lodash'

import { message } from 'components/Message'

import {
  listExperiences,
  createExperience as createExperienceService,
  updateExperience as updateExperienceService,
  deleteExperience as deleteExperienceService
} from 'services/experiences'

import { useProfileId } from 'redesign/hooks/useProfileId'

import { capitalizeFirstLetter } from 'redesign/utils/stringUtils'

import type Experience from 'redesign/types/Experience'
import type { MutationOptions } from 'redesign/types/Mutation'

import type {
  ExperienceId,
  IUseProfileExperiencesReturn,
  UpdateExperienceValueProps
} from './useProfileExperiences.types'

const useProfileExperiences = (): IUseProfileExperiencesReturn => {
  const queryClient = useQueryClient()
  const { profileId } = useProfileId()

  // Get
  const { data: experiencesData, isLoading } = useQuery({
    queryKey: ['experiences', profileId],
    queryFn: () =>
      listExperiences({
        params: {
          profile: profileId,
          populate: ['skills']
        }
      })
  })

  const data = useMemo(() => experiencesData?.data || [], [experiencesData])
  const dataCount = useMemo(
    () => experiencesData?.data.length || 0,
    [experiencesData]
  )

  // Post
  const createMutation = useMutation({
    mutationFn: createExperienceService,
    onSuccess: () => {
      message.success('Experience created')
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
      queryClient.invalidateQueries({ queryKey: ['candidateSkills'] })
    },
    onError: (err: any) => {
      const errData = err.response?.data?.data
      const genericErrorMessage = 'Create Experience failed'
      const errorMessage: string =
        first(errData?.role) ?? first(errData?.company) ?? genericErrorMessage

      // Some API messages start with lowercase so we have to normalize it
      message.error(capitalizeFirstLetter(errorMessage))
    }
  })

  const createExperience = (
    values: Experience,
    callbacks?: MutationOptions
  ) => {
    createMutation.mutate(values, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    Record<string, string>,
    unknown,
    UpdateExperienceValueProps
  >({
    mutationFn: ({ experienceId, values }) =>
      updateExperienceService(experienceId, values),
    onSuccess: () => {
      message.success('Experience updated')
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
      queryClient.invalidateQueries({ queryKey: ['candidateSkills'] })
    },
    onError: () => {
      message.error('Update Experience failed')
    }
  })

  const updateExperience = (
    values: UpdateExperienceValueProps,
    callbacks?: MutationOptions
  ) => {
    updateMutation.mutate(values, callbacks)
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteExperienceService,
    onSuccess: () => {
      message.success('Experience deleted')
      queryClient.invalidateQueries({ queryKey: ['experiences'] })
      queryClient.invalidateQueries({ queryKey: ['candidateSkills'] })
    },
    onError: () => {
      message.error('Delete Experience failed')
    }
  })

  const deleteExperience = (experienceId: ExperienceId) => {
    deleteMutation.mutate(experienceId)
  }

  return {
    data,
    dataCount,
    isLoading,
    createExperience,
    updateExperience,
    deleteExperience
  }
}
export default useProfileExperiences
