import React, { useState } from 'react'

import { EditOutlined } from '@ant-design/icons'

import { MarketingForm } from '../MarketingForm'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'

import { SectionCardContent } from 'redesign/components/'

import { formatEmptyText } from 'redesign/utils/stringUtils'

import type { IMarketingProps } from './Marketing.types'

import { fieldStyles } from './Marketing.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const Marketing = ({ profile, onSave }: IMarketingProps) => {
  const [isEdit, setEdit] = useState(false)

  if (isEdit) {
    return (
      <MarketingForm
        initialValues={profile}
        closeForm={() => setEdit(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <SectionCardContent
      title="Marketing"
      actions={
        <Button
          data-testid="profileMarketingSection-editButton"
          icon={<EditOutlined />}
          onClick={() => setEdit(true)}
        />
      }
    >
      <Descriptions
        bordered
        column={{ xs: 1, sm: 1, md: 2, xxl: 2, xl: 2, lg: 2 }}
        labelStyle={{ backgroundColor: dynamicBlack }}
        contentStyle={{ backgroundColor: sootyBlack }}
      >
        <DescriptionsItem label="Source" className={fieldStyles}>
          {formatEmptyText(profile?.utmSource)}
        </DescriptionsItem>
        <DescriptionsItem label="Medium" className={fieldStyles}>
          {formatEmptyText(profile?.utmMedium)}
        </DescriptionsItem>
        <DescriptionsItem label="Campaign" className={fieldStyles}>
          {formatEmptyText(profile?.utmCampaign)}
        </DescriptionsItem>
        <DescriptionsItem label="Content" className={fieldStyles}>
          {formatEmptyText(profile?.utmContent)}
        </DescriptionsItem>
        <DescriptionsItem label="Term">
          {formatEmptyText(profile?.utmTerm)}
        </DescriptionsItem>
      </Descriptions>
    </SectionCardContent>
  )
}

export default Marketing
