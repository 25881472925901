import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useForm } from '../../../../../../../hooks/useForm'
import { SectionCardContent, App } from 'redesign/components'

import {
  SkillsField,
  JobTypesField,
  RateRangeField
} from 'redesign/containers/FormFields'

import { Button } from 'components/Button'
import { Descriptions, DescriptionsItem } from 'components/Descriptions'
import { Form, FormItem } from 'components/Form'
import { InputNumber } from 'components/InputNumber'
import { Switch } from 'components/Switch'

import { LockedFormItem } from 'redesign/components/LockedFormItem'

import { arrayToSelectOptions } from 'utils/helpers'

import { formatNegativeValues } from 'redesign/utils/helpers'

import type JobType from 'redesign/types/JobType'
import type Skill from 'redesign/types/Skill'

import type { IPreferencesForm } from './PreferencesForm.types'

import {
  fieldStyles,
  formStyles,
  saveButtonStyle,
  minRateFieldStyles,
  descriptionsStyles,
  noStyles
} from './PreferencesForm.module.css'
import { sootyBlack, dynamicBlack } from 'theme/colors.module.css'

const PreferencesForm = ({
  initialValues,
  closeForm,
  onSave
}: IPreferencesForm) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()

  const onClickSave = () => {
    const values = form.getFieldsValue()
    const preferredJobTypes = formatNegativeValues<JobType>(
      values.preferredJobTypes,
      initialValues.preferredJobTypes
    )
    const preferredSkills = formatNegativeValues<Skill>(
      values.preferredSkills,
      initialValues.preferredSkills
    )

    const minRate = Number(values?.minRate)

    onSave(
      {
        ...values,
        preferredSkills,
        preferredJobTypes,
        minRate
      },
      {
        onSuccess: closeForm
      }
    )
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeForm()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  const formattedInitialValues = {
    ...initialValues,
    preferredSkills: arrayToSelectOptions(initialValues?.preferredSkills),
    preferredJobTypes: arrayToSelectOptions(initialValues?.preferredJobTypes)
  }

  return (
    <SectionCardContent
      title="Preferences"
      actions={
        <div>
          <Button
            type="primary"
            onClick={onClickSave}
            className={saveButtonStyle}
          >
            Save
          </Button>
          <Button onClick={onClickCancel}>Cancel</Button>
        </div>
      }
    >
      <Form
        data-testid="profilePreferencesForm-form"
        form={form}
        initialValues={formattedInitialValues}
        className={formStyles}
      >
        <LockedFormItem label="Job types" type="descriptionItem">
          <FormItem name="preferredJobTypes" className={noStyles}>
            <JobTypesField />
          </FormItem>
        </LockedFormItem>

        <LockedFormItem label="Skill preferences" type="descriptionItem">
          <FormItem name="preferredSkills" className={noStyles}>
            <SkillsField />
          </FormItem>
        </LockedFormItem>

        <Descriptions
          bordered
          column={{ xs: 1, sm: 1, md: 2, xxl: 2, xl: 2, lg: 2 }}
          className={descriptionsStyles}
          labelStyle={{ backgroundColor: dynamicBlack }}
          contentStyle={{ backgroundColor: sootyBlack }}
        >
          <DescriptionsItem label="Minimum rate" className={fieldStyles}>
            <FormItem name="minRate" className={noStyles}>
              <InputNumber className={minRateFieldStyles} />
            </FormItem>
          </DescriptionsItem>
        </Descriptions>

        <LockedFormItem label="Expected Rate Range" type="descriptionItem">
          <FormItem name="rateRange" className={noStyles}>
            <RateRangeField />
          </FormItem>
        </LockedFormItem>

        <LockedFormItem
          label="Subscribed to Job Board emails"
          type="descriptionItem"
        >
          <FormItem
            name="subscribed"
            valuePropName="checked"
            className={noStyles}
          >
            <Switch />
          </FormItem>
        </LockedFormItem>

        <LockedFormItem
          label="Subscribed to Job Alerts emails"
          type="descriptionItem"
        >
          <FormItem
            name="jobAlertsNotification"
            valuePropName="checked"
            className={noStyles}
          >
            <Switch />
          </FormItem>
        </LockedFormItem>
      </Form>
    </SectionCardContent>
  )
}

export default PreferencesForm
