// extracted by mini-css-extract-plugin
export var auroraOrange = "#E87040";
export var brilliantAzure = "#319bfc";
export var cannotSnoozeItemStyles = "ActionDropdown-module--cannotSnoozeItemStyles--71e45";
export var colors = "'theme/colors.module.css'";
export var conkerBrown = "#571E07";
export var friarGray = "#868686";
export var gainsBoro = "#DBDBDB";
export var gray1 = "#141414";
export var lightSilver = "#D9D9D9";
export var notSnoozedItemStyles = "ActionDropdown-module--notSnoozedItemStyles--f48c6";
export var overlayListItemStyles = "ActionDropdown-module--overlayListItemStyles--2fbf5";
export var overlayListStyles = "ActionDropdown-module--overlayListStyles--1829e";
export var snoozedItemStyles = "ActionDropdown-module--snoozedItemStyles--fc04f";
export var threeDotButtonStyles = "ActionDropdown-module--threeDotButtonStyles--cfd1b";