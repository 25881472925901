import React from 'react'

import clsx from 'clsx'

import { RoleCandidateActionsButtonContainer } from '../RoleCandidateActionsButtonContainer'

import { Divider } from 'components/Divider'

import { useProfile } from 'redesign/modules/Profile/hooks'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'

import { buildTooltipElement } from 'utils/helpers'

import { getRoleCandidateState } from 'redesign/utils/partnerRoleUtils'

import { BookedStatusDates } from './components'

import {
  profileRoleStatusBlockStyles,
  card,
  bookedStatusTextStyles,
  bookedStatusDatesDividerStyles,
  reservedStatusTextStyles,
  contactedStatusStyles,
  xProfileRequestedStatusStyles,
  presentedToBizDevStatusStyles,
  clientInterviewedStatusStyles,
  acceptedStatusStyles,
} from './QuickActionPanel.module.css'

const STATUS_CLASS_NAME = {
  CONTACTED: contactedStatusStyles,
  X_PROFILE_REQUESTED: xProfileRequestedStatusStyles,
  PRESENTED_TO_BIZDEV: presentedToBizDevStatusStyles,
  CLIENT_INTERVIEWED: clientInterviewedStatusStyles,
  ACCEPTED: acceptedStatusStyles,
}

const QuickActionPanel = () => {
  const { data: featureFlags } = useFeatureFlags()
  const { data: profile } = useProfile()
  const isBookedStatusDatesEnabled = Boolean(
    featureFlags?.RELEASE_BOOKED_STATUS_DATES
  )

  const { data: roleCandidates } = usePartnerRoleCandidates({
    enabled: Boolean(profile.id),
    params: { profile: profile.id }
  })

  const reservedNotBookedCandidate = roleCandidates?.find(
    candidate =>
      candidate.reservedAt && !candidate.bookedAt && !candidate.closedAt
  )

  const reservedNotBookedCandidateStatus = getRoleCandidateState(
    reservedNotBookedCandidate
  )

  let reservedNotBookedCandidateClassName

  if (reservedNotBookedCandidateStatus) {
    reservedNotBookedCandidateClassName =
      STATUS_CLASS_NAME[
        reservedNotBookedCandidateStatus.value as keyof typeof STATUS_CLASS_NAME
      ]
  }

  const bookedNotClosedCandidate = roleCandidates?.find(
    candidate => Boolean(candidate.bookedAt) && !candidate.closedAt
  )

  const shouldShowPanel = Boolean(
    reservedNotBookedCandidate ?? bookedNotClosedCandidate
  )

  if (!shouldShowPanel) {
    return null
  }

  const shouldShowBookedStatusDates = Boolean(
    isBookedStatusDatesEnabled &&
      (bookedNotClosedCandidate?.bookedStartAt ??
        bookedNotClosedCandidate?.bookedEndAt)
  )

  return (
    <div
      className={profileRoleStatusBlockStyles}
      data-testid={'ProfileRoleQuickAction-status-block'}
    >
      {bookedNotClosedCandidate && (
        <>
          <div className={card}>
            <span>
              <span className={bookedStatusTextStyles}>
                {getRoleCandidateState(bookedNotClosedCandidate)?.name}
              </span>
              <span>
                {buildTooltipElement('Booked', bookedNotClosedCandidate, true)}
              </span>
            </span>
            <RoleCandidateActionsButtonContainer
              roleCandidate={bookedNotClosedCandidate}
            />
          </div>
          {shouldShowBookedStatusDates && (
            <>
              <Divider
                type="horizontal"
                className={bookedStatusDatesDividerStyles}
              />
              <BookedStatusDates
                startDate={bookedNotClosedCandidate?.bookedStartAt}
                endDate={bookedNotClosedCandidate?.bookedEndAt}
              />
            </>
          )}
        </>
      )}
      {reservedNotBookedCandidate && bookedNotClosedCandidate && (
        <Divider type="horizontal" plain />
      )}
      {reservedNotBookedCandidate && (
        <div className={card}>
          <span>
            <span
              className={clsx(
                reservedStatusTextStyles,
                reservedNotBookedCandidateClassName
              )}
            >
              {reservedNotBookedCandidateStatus?.name}
            </span>
            <span>
              {buildTooltipElement(
                reservedNotBookedCandidateStatus?.name,
                reservedNotBookedCandidate,
                true
              )}
            </span>
          </span>
          <RoleCandidateActionsButtonContainer
            roleCandidate={reservedNotBookedCandidate}
          />
        </div>
      )}
    </div>
  )
}

export default QuickActionPanel
