import React, { useState, useMemo } from 'react'

import { Link } from 'gatsby'

import { SectionCardContent } from 'redesign/components'

import { JobStatusCell } from 'components/JobStatusCell'
import type { RadioChangeEvent } from 'components/RadioGroup'
import { RadioGroup } from 'components/RadioGroup'
import { RelativeDate } from 'components/RelativeDate'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'

import { compareStrings } from 'utils/helpers'

import type JobApplication from 'redesign/types/JobApplication'

import type { IJobApplicationsTableProps } from './JobApplicationsTable.types'

const JobApplicationsTable = ({
  jobApplications
}: IJobApplicationsTableProps) => {
  const [filter, setFilter] = useState('Pending')

  const filteredJobApplications = useMemo(() => {
    switch (filter) {
      case 'Pending':
        return jobApplications.filter(
          item =>
            item.active === true &&
            item.applied === true &&
            item.hiddenAt === null
        )
      case 'Hidden':
        return jobApplications.filter(
          item => item.active === true && item.hiddenAt !== null
        )
      case 'Closed':
        return jobApplications.filter(item => item.active === false)
      case 'Withdrawn':
        return jobApplications.filter(
          item => item.active === true && item.applied === false
        )
      case 'All':
        return jobApplications
      default:
        return []
    }
  }, [jobApplications, filter])

  const columns: ColumnsType<JobApplication> = [
    {
      title: 'Job',
      dataIndex: 'job',
      key: 'job',
      render: value =>
        value ? <Link to={`/job?id=${value?.id}`}>{value?.name}</Link> : 'n/a',
      sorter: (a, b) => compareStrings(a.job.name, b.job.name)
    },

    {
      title: 'Application Date',
      dataIndex: 'appliedAt',
      key: 'appliedAt',
      render: value => <RelativeDate value={value} />,
      sorter: (a, b) =>
        new Date(a.appliedAt).valueOf() - new Date(b.appliedAt).valueOf()
    },
    {
      title: 'Application Status',
      key: 'appStatus',
      render: record => <JobStatusCell record={record} />
    }
  ]

  return (
    <SectionCardContent title="Job Applications" actions={<RadioGroup
      options={['Pending', 'Hidden', 'Closed', 'Withdrawn', 'All']}
      optionType="button"
      buttonStyle="solid"
      value={filter}
      onChange={({ target: { value } }: RadioChangeEvent) => setFilter(value)}
    />}>
      <Table
        rowKey="id"
        expandRowByClick
        columns={columns}
        dataSource={filteredJobApplications}
        pagination={false}
        scroll={{ x: true }}
      />
    </SectionCardContent>
  )
}

export default JobApplicationsTable
