export const notNumberRegex = /[^0-9.]/g

const plurals = {
  years: { singular: 'year', plural: 'years' },
  months: { singular: 'month', plural: 'months' },
}

const getPlural = (number: number, word: { singular: string; plural: string }) => number === 1 ? word.singular : word.plural

export const convertMonthsToYears = (months?: number) => {
  if (!months) {
    return '-'
  }
  const years = Math.floor(months / 12)
  const remainingMonths = months % 12

  const yearsString = years > 0 ? `${years} ${getPlural(years, plurals.years)}` : ''
  const monthsString = remainingMonths > 0 ? `${remainingMonths} ${getPlural(remainingMonths, plurals.months)}` : ''
  const joinString = yearsString && monthsString ? ' and ' : ''

  return `${yearsString}${joinString}${monthsString}`
}
