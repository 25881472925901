import React, { useMemo, useState } from 'react'

import { Link } from 'gatsby'
import moment from 'moment'

import { SectionCardContent } from 'redesign/components'

import { XProfileColumn } from 'redesign/containers/RoleCandidatesTableContainer/components/XProfileColumn'

import type { RadioChangeEvent } from 'components/RadioGroup'
import { RadioGroup } from 'components/RadioGroup'
import { useSearchFilter } from 'components/SearchFilter'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'

import { RecentActivities } from 'redesign/components/RecentActivities/RecentActivities'
import { RoleCandidateAssignees } from 'redesign/components/RoleCandidateAssignees'

import { compareStrings } from 'utils/helpers'

import type { RoleCandidateCloseReason } from 'redesign/types/RoleCandidate'
import type RoleCandidate from 'redesign/types/RoleCandidate'
import type User from 'redesign/types/User'

import type { IRolesTableProps } from './RolesTable.types'

const RolesTable = ({
  roles,
  openXProfile,
  showXProfileColumn,
  authData,
  shouldDisplayRoleAssignees,
  handleAssigneeChange,
  ActionsComponent
}: IRolesTableProps) => {
  const [filter, setFilter] = useState('Open')
  const showActionColumn = filter === 'Open'
  const { createSearchableColumn } = useSearchFilter()

  const filteredRoles = useMemo(() => {
    switch (filter) {
      case 'Open':
        return roles.filter(
          item => item.closedAt === null && item.bookedAt === null
        )
      case 'Closed':
        return roles.filter(
          item => item.closedAt !== null || item.bookedAt !== null
        )
      default:
        return []
    }
  }, [roles, filter])

  const columns: ColumnsType<RoleCandidate> = [
    {
      title: 'Role',
      dataIndex: 'partnerRole',
      key: 'partnerRole',
      render: value => <Link to={`/role?id=${value?.id}`}>{value?.name}</Link>
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 150,
      render: value => moment(value).format('YYYY-MM-DD') || '-',
      sorter: (a, b) =>
        new Date(a.updated_at ?? '').valueOf() -
        new Date(b.updated_at ?? '').valueOf(),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Recent activity',
      render: record => <RecentActivities roleCandidate={record} />
    },
    ...(shouldDisplayRoleAssignees
      ? [{
        title: 'Role Assignees',
        dataIndex: 'inactiveAssignees',
        render: (_: User[], roleCandidate: RoleCandidate) => (
          <RoleCandidateAssignees
            roleCandidate={roleCandidate}
            authData={authData}
            handleAssigneeChange={handleAssigneeChange}
          />
        ),
      }]
      : []),
    createSearchableColumn({
      title: 'Partner',
      dataIndex: ['partnerRole', 'partner'],
      key: 'partnerRole.partner.name',
      placeholder: 'Search Partner Name',
      onFilter: (value: string, record: RoleCandidate) =>
        record?.partnerRole?.partner?.name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()),
      render: (partner: RoleCandidate['partnerRole']['partner'], Text: any) =>
        partner?.name
          ? (
            <Link to={`/partners?id=${partner?.id}`}>
              <Text text={partner.name} />
            </Link>
          )
          : (
            ''
          ),
      sorter: (a: RoleCandidate, b: RoleCandidate) =>
        compareStrings(
          a?.partnerRole?.partner?.name,
          b?.partnerRole?.partner?.name
        )
    }),
    ...(!showActionColumn
      ? [
        {
          title: 'Closing origin and reason',
          dataIndex: 'roleCandidateCloseReason',
          key: 'roleCandidateCloseReason',
          render: (reason: RoleCandidateCloseReason, record: RoleCandidate) =>
            reason?.summary || reason?.legacyKey || record?.closeReason || ''
        }
      ]
      : []),
    ...(showXProfileColumn
      ? [
        {
          title: 'X-Profile',
          render: (roleCandidate: RoleCandidate) => (
            <XProfileColumn
              roleCandidate={roleCandidate}
              openXProfile={openXProfile}
            />
          )
        }
      ]
      : []),
    {
      title: 'Actions',
      key: 'actions',
      render: showActionColumn ? ActionsComponent : undefined
    }
  ]

  return (
    <SectionCardContent
      title="Roles"
      actions={
        <RadioGroup
          options={['Open', 'Closed']}
          optionType="button"
          buttonStyle="solid"
          value={filter}
          onChange={({ target: { value } }: RadioChangeEvent) =>
            setFilter(value)
          }
        />
      }
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filteredRoles}
        pagination={false}
        scroll={{ x: true }}
      />
    </SectionCardContent>
  )
}

export default RolesTable
