// Hooks
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { useProfileId } from 'redesign/hooks/useProfileId'

// Types
import type { IUseProfileRolesReturn } from './useProfileRoles.types'

const useProfileRoles = (): IUseProfileRolesReturn => {
  const { profileId } = useProfileId()

  return usePartnerRoleCandidates({ params: { profile: profileId } })
}
export default useProfileRoles
