import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getMasterProfiles } from 'services/profile'

import type { IUseMasterProfilesReturn, ProfileIdRecord } from './useMasterProfiles.types'

const useMasterProfiles = (): IUseMasterProfilesReturn => {
  const { data: masters, isLoading } = useQuery({
    queryKey: ['masterProfiles'],
    queryFn: () => getMasterProfiles()
  })

  // Returned data is an array of numbers => ids of master profiles
  const data = useMemo(() =>
    masters?.data
      ? masters.data.map((item: ProfileIdRecord) => item.id)
      : [],
  [masters])

  return {
    data,
    isLoading
  }
}

export default useMasterProfiles
